export const startegicDisruptionColumn = [
  {
    Header: 'PROVIDER_ORG_ID',
    accessor: 'PROVIDER_ORG_ID',
  },
  {
    Header: 'ORG_NAME',
    accessor: 'ORG_NAME',
    hiddenDefaultFilters: ['greater', 'greater-equal', 'less', 'less-equal'],
  },
  {
    Header: 'HEADER_ID',
    accessor: 'HEADER_ID',
  },
  {
    Header: 'DETAIL_ID',
    accessor: 'DETAIL_ID',
  },
  {
    Header: 'PC_CONTRACTCLAIMTYPE',
    accessor: 'PC_CONTRACTCLAIMTYPE',
  },
  {
    Header: 'SOURCE',
    accessor: 'SOURCE',
  },
  {
    Header: 'TIN',
    accessor: 'TIN',
  },
  {
    Header: 'PROVIDER_ID',
    accessor: 'PROVIDER_ID',
  },
  {
    Header: 'PROVIDER_ASSIGNMENT_TYPE',
    accessor: 'PROVIDER_ASSIGNMENT_TYPE',
  },
  {
    Header: 'PROVIDER_NAME',
    accessor: 'PROVIDER_NAME',
  },
  {
    Header: 'PROV_ASSIGNMENT_EFF_DATE',
    accessor: 'PROV_ASSIGNMENT_EFF_DATE',
  },
  {
    Header: 'PROV_ASSIGNMENT_EXP_DATE',
    accessor: 'PROV_ASSIGNMENT_EXP_DATE',
  },
  {
    Header: 'MPIN',
    accessor: 'MPIN',
  },
  {
    Header: 'NPI',
    accessor: 'NPI',
  },
];
