/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { styled } from '@abyss/web/tools/styled';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { Navbar } from '../Components/Navbar';
import { Dashboard } from '../Components/Dashboard';
import { Footer } from '../Components/Footer';

import { msalConfig } from '../authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

if (
  msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for Sign In and set Active Account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

const HomeContainer = styled('div', {
  textAlign: 'center',
  // mineight: '100%';
});

const MainContent = () => {
  const getAccoutDetails = useMsal();
  const dispatch = useDispatch();
  const currentAccountDetails = getAccoutDetails.instance.getAllAccounts()[0];
  const isFooter = useSelector((state: RootStateOrAny) => {
    return state?.isFileUploaded;
  });

  const [hours] = useState(new Date().getHours());
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    if (hours < 12) {
      setGreeting('Good morning, ');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon, ');
    } else if (hours >= 16) {
      setGreeting('Good evening, ');
    }
  }, [greeting, hours]);

  useEffect(() => {
    if (getAccoutDetails) {
      dispatch({
        type: 'SET_ACTIVE_ACCOUNT_EMAIL',
        value: currentAccountDetails?.username,
      });
    }
  }, [getAccoutDetails, currentAccountDetails]);

  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          <title> Tiered Benefit Point In Time Report</title>
          <HomeContainer>
            <PageHeader
              logo={
                <React.Fragment>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between !important',
                    }}
                  >
                    <Brandmark
                      brand="uhc"
                      affiliate="uhc"
                      variant="lockup"
                      color="blue"
                      className="brandmark-css"
                    />
                    {/* <img
                      className="navbar-secondary-image"
                      src="./MicrosoftTeams-image.png"
                      alt="navbar-secondaryImage"
                    /> */}
                    <p className="logoTittle">
                      Tiered Benefit Point In Time Report
                    </p>
                  </div>
                </React.Fragment>
              }
              model="pageheader-model"
              css={{
                'abyss-page-header-root': {
                  zIndex: 209,
                  borderColor: 'green',
                  borderStyle: 'dotted',
                  border: '1px solid #002677',
                  background: '#fcd299',
                },
              }}
            >
              <PageHeader.Toolbar>
                <div>
                  <h5 className="greeting">
                    {greeting}{' '}
                    {currentAccountDetails
                      ? `${currentAccountDetails.name}`
                      : 'Test'}
                  </h5>
                  <h5 className="greeting-secondary">Welcome to Dashboard</h5>
                </div>
              </PageHeader.Toolbar>
            </PageHeader>

            {/* <Navbar /> */}
            {/* <Banner /> */}
            <Dashboard />
            {/* {!isFooter && <Footer />} */}
          </HomeContainer>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Navbar />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </React.Fragment>
  );
};

export const Home = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
    </MsalProvider>
  );
};

Home.propTypes = {
  instance: PublicClientApplication.isRequired,
};
