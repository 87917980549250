import { useState, Dispatch, SetStateAction } from 'react';

export class Filters {
  filters: [string[], Dispatch<SetStateAction<string[]>>];

  constructor() {
    this.filters = useState([]);
  }

  public push(newValue: string) {
    const temp: string[] = this.filters[0];
    temp.push(newValue);
    this.filters[1](temp);
  }

  public pop(filterName: string = '') {
    try {
      if (filterName.length === 0) {
        throw new Error('Not a valid filter Name!!');
      }

      if (this.filters[0].length === 0) {
        throw new Error('Stack already empty');
      }

      const removedItems: string[] = [];

      const tempItem: string[] = this.filters[0];

      let filter = tempItem.pop();

      while (filterName !== filter && tempItem.length > 0) {
        if (filter) removedItems.push(filter);
        filter = tempItem.pop();
      }

      if (filterName !== filter && tempItem.length > 0) {
        throw new Error('Filter not selected yet');
      }

      tempItem.push(filterName);
      this.filters[1](tempItem);

      return removedItems;
    } catch (error) {
      console.error('Error while remving filter name from stack');
      console.error(this.filters[0]);
      console.error(filterName);

      return [];
    }
  }

  public peek() {
    if (this.filters[0].length === 0) {
      throw new Error('No Filters selected yet!!');
    }

    return this.filters[0][this.filters[0].length - 1];
  }

  public clear() {
    this.filters[1]([]);
  }

  public hasFilter(filter: string) {
    return this.filters[0].includes(filter);
  }

  public concatenate(concatenateItem: string[]) {
    this.filters[1](this.filters[0].concat(concatenateItem));
  }
}
