import { LogLevel } from '@azure/msal-browser';

const environment = 'AZURE';

let redirectUri =
  environment === 'AZURE'
    ? 'https://uspopsselfservice.uhc.com/'
    : 'http://localhost:4000/';

// https://uspopsselfservice.uhc.com/  PROD URL
// https://uspopstechpowerbireports-dev.uhc.com/  DEV URL

let hostUrl = '';
if (typeof window !== 'undefined') {
  hostUrl = window.location.href;
}

if (hostUrl.includes('tiered_benefit_point_in_time')) {
  redirectUri += 'tiered_benefit_point_in_time';
} else if (hostUrl.includes('ndb_pln_ddp_status_tin_mpin_level')) {
  redirectUri += 'ndb_pln_ddp_status_tin_mpin_level';
} else if (hostUrl.includes('StrategicDisruptions')) {
  redirectUri += 'StrategicDisruptions';
}

export const msalConfig = {
  auth: {
    clientId: '816b2f48-3ddc-4986-ba08-0a53cdb41918',
    authority:
      'https://login.microsoftonline.com/db05faca-c82a-4b9d-b9c5-0f64b6755421',
    redirectUri,
    postLogoutRedirectUri: '/',
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookies: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            console.warn(message);
            break;
          default:
        }
      },
    },
  },
};

export const loginRequest = {
  scopes: ['user.read'],
};

export const silentRequest = {
  scopes: ['user.read'],
  loginHint: 'example@domain.net',
};
