import { configureStore } from "@reduxjs/toolkit";
import { reducer } from "./reducers/reducer";
import logger from "redux-logger";
const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(logger),
});

export default store;
