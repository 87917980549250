import {
  HIDE_DIALOG,
  SHOW_DIALOG,
  START_LOADER,
  STOP_LOADER,
} from './actionTypes';
export const startLoader = () => (dispatch) => {
  dispatch({ type: 'START_LOADER', data: true });
};
export const stopLoader = () => (dispatch) => {
  dispatch({ type: 'STOP_LOADER', data: false });
};
export const showDialog = () => (dispatch) => {
  dispatch({ type: 'SHOW_DIALOG', data: true });
};
export const hideDialog = () => (dispatch) => {
  dispatch({ type: 'HIDE_DIALOG', data: false });
};
