import { START_LOADER, STOP_LOADER } from '../actionTypes';

const initialState = {
  loading: false,
  dialog: false,

  isFileUploaded: false,
  acoTableData: [],
  nonAcoTableData: [],
  isAcoFlag: true,
  totalRecordsToDisplay: 0,
  totalNonAcoRecords: 0,
  checkLoading: false,
  activeAccountId: '',
  activeUserGroups: {},
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ACTIVE_ACCOUNT_EMAIL':
      return {
        ...state,
        activeAccountEmail: action.value,
      };
    case 'SET_ACTIVE_ACCOUNT_ID':
      return {
        ...state,
        activeAccountId: action.value,
      };
    case 'SET_ACTIVE_USER_GROUP':
      return {
        ...state,
        activeUserGroups: action.value,
      };
    case START_LOADER:
      return {
        ...state,
        loading: action.data,
      };

    case STOP_LOADER:
      return {
        ...state,
        loading: action.data,
      };

    case 'SET_LOADING_FLAG':
      return {
        ...state,
        checkLoading: action.value,
      };

    //   // if (action.value.tobeUpdate !== 'None') {
    //   array.push(action.value);
    //   // }
    //   array = uniqByKeepLast(array, (item) => {
    //     return item.orignal;
    //   });

    //   return {
    //     ...state,
    //     objectArray: array,
    //   };

    case 'SET_CLEAR':
      return {
        ...state,
        objectArray: [],
      };

    case 'SET_ACO_TABLE_DATA':
      return { ...state, acoTableData: action.value };
    case 'SET_ACO_FLAG':
      return {
        ...state,
        isAcoFlag: action.value,
      };

    case 'SET_ACO_TOTALCOUNT_DATA':
      return { ...state, totalRecordsToDisplay: action.value };

    case 'SET_NON_ACO_TABLE_DATA_COUNT':
      return { ...state, totalNonAcoRecords: action.value };

    case 'SET_NON_ACO_TABLE_DATA':
      return { ...state, nonAcoTableData: action.value };
    default:
      return state;
  }
};
