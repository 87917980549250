import React from 'react';
import { Router } from '@abyss/web/ui/Router';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { ReportBody } from '@src/components/ReportBody/ReportBody';
import { Home } from './Home';
import { HelloAbyss } from './HelloAbyss';

import { msalConfig } from './authConfig';
import { ReportHome } from './ReportHome/ReportHome';
import { SDRHome } from './Strategic_Disruptions_Report/SDRHome';

const msalInstance = new PublicClientApplication(msalConfig);

if (
  msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for Sign In and set Active Account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

export const Routes = () => {
  return (
    <Router.Routes>
      <Router.Route path="/hello-abyss" element={<HelloAbyss />} />
      <Router.Route path="/" element={<Home />} />
      <Router.Route
        path="/ndb_pln_ddp_status_tin_mpin_level"
        element={<ReportHome />}
      />
      <Router.Route path="/StrategicDisruptions" element={<SDRHome />} />
    </Router.Routes>
  );
};
