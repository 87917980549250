/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { Button } from '@abyss/web/ui/Button';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { startLoader, showDialog, stopLoader } from '../store/actions';
import Loader from '../components/Loader';
const MessagePopupComponent = (props) => {
  const { open, errorName, successCase } = props;
  const [radioValue, setRadioValue] = useState('excel');
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const loadingCheck = useSelector((state) => {
    return state?.checkLoading;
  });

  // useEffect(() => {
  //   console.log('in useeffect');
  //   setIsLoading(true);
  // }, []);

  const onSubmitValue = async (value) => {
    console.log('radioValue=====', radioValue);
    if (value) {
      setIsLoading(true);
    }

    setTimeout(() => {
      if (radioValue == 'excel') {
        props.createExcel();
      } else if (radioValue == 'csv') {
        props.createCsvFile();
      }
    }, 2000);

    // dispatch({ type: 'SET_LOADING_FLAG', value: true });
  };
  return (
    <React.Fragment>
      <Backdrop data-testid="loading-1" open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Loader open={isLoading} />
      <Modal
        title="Export Data"
        isOpen={open}
        onClose={(e) => {
          return props.handleClose(e);
        }}
      >
        <Modal.Section>
          <Grid>
            <Grid.Col span={{ xs: 12 }}>
              <span offset={3}>
                <RadioGroup
                  label="Disclaimer : You can only export upto 450K Records."
                  onChange={(e) => {
                    // selectedRadioValue(e.target.value);
                    setRadioValue(e.target.value);
                  }}
                  value={radioValue}
                >
                  <RadioGroup.Radio label="Excel " value="excel" />
                  <RadioGroup.Radio label="CSV" value="csv" />
                </RadioGroup>
              </span>
              {console.log(isLoading, loadingCheck)}
            </Grid.Col>
          </Grid>
        </Modal.Section>
        <Modal.Section>
          <Layout.Group alignLayout="right">
            <Button
              onClick={(e) => {
                return onSubmitValue(e); //props.createExcel(e)
              }}
            >
              Submit
            </Button>
          </Layout.Group>
        </Modal.Section>
      </Modal>
    </React.Fragment>
  );
};
export default MessagePopupComponent;
