/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { Table } from '@abyss/web/ui/Table';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { styled } from '@abyss/web/tools/styled';
import Loader from '../../components/Loader';
import MapComponent from './MapComponents';
import { hostServer, options } from '../../utils/constants';

const serverUrl = hostServer.dev;
export const Dashboard = () => {
  // const [acoTableData, setAcoTableData] = useState([]);
  const [acoFilterData, setAcoFilterData] = useState([]);
  const WrapperDiv = styled('div', {
    overflow: 'auto',
    height: '395px',
    marginLeft: '4px',
    marginRight: '4px',
  });
  const acoTableData = useSelector((state) => {
    return state?.acoTableData;
  });
  const nonAcoTableData = useSelector((state) => {
    return state?.nonAcoTableData;
  });
  const totalRecordsToDisplay = useSelector((state) => {
    return state?.totalRecordsToDisplay;
  });

  const totalNonAcoRecords = useSelector((state) => {
    return state?.totalNonAcoRecords;
  });

  const isAcoFlag = useSelector((state) => {
    return state?.isAcoFlag;
  });
  const classes = {
    root: {
      flexGrow: 1,
    },
  };
  const fetchAcoData = () => {
    axios
      .get(
        `${serverUrl}/fetchACOTableData?ACO_NAME='Privia'&BASENETWORKIDS='NEXUSACO R'&PROVTYPE='P'&TIERVALUE='1'&STATE='VA'`
      )
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          // setAcoTableData(response.data.results);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const fetchACOFilter = () => {
    axios
      .get(`${serverUrl}/fetchACOFilter`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.ACO_NAME,
              label: item.ACO_NAME,
            });
          });
          setAcoFilterData(formatedList);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  useEffect(() => {
    // fetchAcoData();
    fetchACOFilter();
  }, []);

  const columns = [
    { name: 'ACO_NAME', key: 'ACO_NAME' },
    { name: 'ORGANIZATIONNAME', key: 'ORGANIZATIONNAME' },
    { name: 'PROVTYPE', key: 'PROVTYPE' },
    { name: 'PROVIDERID', key: 'PROVIDERID' },
    { name: 'STATE', key: 'STATE' },
    { name: 'TIN', key: 'TIN' },
    { name: 'PROVID', key: 'PROVID' },
    { name: 'NAMEFIRST', key: 'NAMEFIRST' },
    { name: 'NAMELAST', key: 'NAMELAST' },
    { name: 'ZIPCODE', key: 'ZIPCODE' },
    { name: 'PROVIDERORGID', key: 'PROVIDERORGID' },
    { name: 'PROVIDERORGTYPE', key: 'PROVIDERORGTYPE' },
    { name: 'PLANTYPES', key: 'PLANTYPES' },
    { name: 'CONTRACTID', key: 'CONTRACTID' },
    { name: 'CONTRACTEFFECTIVEDATE', key: 'CONTRACTEFFECTIVEDATE' },
    { name: 'CONTRACTEXPIRATIONDATE', key: 'CONTRACTEXPIRATIONDATE' },
    { name: 'PROVASSIGNMENTTYPE', key: 'PROVASSIGNMENTTYPE' },
    { name: 'TAXONOMYCLASS', key: 'TAXONOMYCLASS' },
    { name: 'TRANSLATIONSPECIALITY', key: 'TRANSLATIONSPECIALITY' },
    { name: 'ADDRESS1', key: 'ADDRESS1' },
    { name: 'ADDRESS2', key: 'ADDRESS2' },
    { name: 'COUNTY', key: 'COUNTY' },
    { name: 'NETWORKID', key: 'NETWORKID' },
    { name: 'NETWORKNAME', key: 'NETWORKNAME' },
    { name: 'SYSDATE', key: 'SYSDATE' },
    { name: 'TIERDISPLAY', key: 'TIERDISPLAY' },
    { name: 'TIERREASON', key: 'TIERREASON' },
    { name: 'QTRULEPACKAGEKEY', key: 'QTRULEPACKAGEKEY' },
    { name: 'QUALITYOUTCOME', key: 'QUALITYOUTCOME' },
    { name: 'EFFICIENCYOUTCOME', key: 'EFFICIENCYOUTCOME' },
  ];

  return (
    <>
      <MapComponent acoFilterData={acoFilterData} />

      {isAcoFlag && acoTableData && acoTableData?.length > 0 && (
        <>
          <div
            style={{
              textAlign: 'right',
              marginRight: '12px',
              color: '#002677',
              fontWeight: 'bold',
            }}
          >
            Showing Records:{acoTableData?.length} Out of Records :
            {totalRecordsToDisplay ? totalRecordsToDisplay : <>Loading...</>}
          </div>
        </>
      )}
      {!isAcoFlag && nonAcoTableData && nonAcoTableData?.length > 0 && (
        <>
          <div
            style={{
              textAlign: 'right',
              marginRight: '12px',
              color: '#002677',
              fontWeight: 'bold',
            }}
          >
            Showing Records:{nonAcoTableData?.length} Out of Records :
            {totalNonAcoRecords ? totalNonAcoRecords : <>Loading...</>}
          </div>
        </>
      )}
      {isAcoFlag && acoTableData && acoTableData.length > 0 ? (
        <WrapperDiv>
          <Table title="Table Scroll" columns={columns} rows={acoTableData} />
        </WrapperDiv>
      ) : !isAcoFlag && nonAcoTableData && nonAcoTableData.length > 0 ? (
        <WrapperDiv>
          <Table
            title="Table Scroll"
            columns={columns}
            rows={nonAcoTableData}
          />
        </WrapperDiv>
      ) : (
        <>
          <p>No Records Found</p>
        </>
      )}
    </>
  );
};
