/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { Divider } from '@abyss/web/ui/Divider';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import axios from 'axios';
import { hostServer, options } from '../../utils/constants';
import { read, utils, write, writeFile } from 'xlsx';
import { startLoader, showDialog, stopLoader } from '../../store/actions';
import Backdrop from '@mui/material/Backdrop';
import Loader from '../../components/Loader';
import CircularProgress from '@mui/material/CircularProgress';
import ExportComponent from '../../components/ExportComponents';
const serverUrl = hostServer.dev;

const NonAcoComponent = () => {
  const dispatch = useDispatch();
  const [orgNamevalue, setOrgNameValue] = React.useState([]);
  const [networkNamevalue, setNetworkNameValue] = React.useState([]);
  const [prvTypeValue, setPrvType] = React.useState([]);
  const [tierValue, setTierValue] = React.useState([]);
  const [stateValue, setStateValue] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [orgNameFilter, setOrgNameFilterData] = React.useState([]);
  const [networkNameFilter, setNtwrkNameFilterData] = React.useState([]);
  const [stateNameFilter, setStateNameFilterData] = React.useState([]);
  const [nonAcoRecords, setNonAcoRecords] = React.useState([]);
  const [CountnonAcoRecords, setCountNonAcoRecords] = React.useState([]);
  const [provTypeList, setProvTypeList] = React.useState([]);
  const [tierValueList, setTierValueList] = React.useState([]);
  const [nonAcoTotalRecords, setNonAcoTotalRecords] = React.useState([]);
  const [dwnloadFlag, setDwnloadFlag] = useState(false);
  const [totalBufferData, setTotalBufferData] = React.useState([]);

  const loading = useSelector((state) => {
    return state.loading;
  });
  const isAcoFlag = useSelector((state) => {
    return state?.isAcoFlag;
  });

  let txt1 = useRef(null);

  let isDisabledFlag = false;
  if (
    orgNamevalue?.length > 0 &&
    networkNamevalue?.length > 0 &&
    prvTypeValue?.length > 0 &&
    tierValue?.length > 0 &&
    stateValue?.length > 0
  ) {
    isDisabledFlag = true;
  }
  const fetchNonAcoFilters = (selValue) => {
    let typedVal = selValue;
    axios
      .get(`${serverUrl}/fetchNonAcoFilters?selectedVal=${typedVal}`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let orgNameList = [];
          response.data.results.map((item) => {
            orgNameList.push({
              value: item.ORGANIZATIONNAME,
              label: item.ORGANIZATIONNAME,
            });
          });
          setOrgNameFilterData(orgNameList);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const fetchNonAcoNetworkNameFilters = (selValue) => {
    axios
      .post(`${serverUrl}/fetchNonAcoNetworkNameFilters`, { data: selValue })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let ntwrkNameList = [];
          response.data.results.map((item) => {
            ntwrkNameList.push({
              value: item.NETWORKNAME,
              label: item.NETWORKNAME,
            });
          });
          setNtwrkNameFilterData(ntwrkNameList);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const fetchNonAcoStateFilters = (selValue) => {
    // let ORGANIZATIONNAME = `'${orgNamevalue}'`;
    // let NETWORKNAME = `'${networkNamevalue}'`;
    // let PROVTYPE = `'${prvTypeValue}'`;
    // let TIERVALUE = `'${selValue}'`;
    let payload = {
      orgNameLists: orgNamevalue,
      networkNameList: networkNamevalue,
      provTypeList: prvTypeValue,
      tierValueList: selValue,
    };

    axios
      .post(`${serverUrl}/fetchNonAcoStateFilters`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let orgNameList = [];
          response.data.results.map((item) => {
            orgNameList.push({
              value: item.STATE,
              label: item.STATE,
            });
          });
          setStateNameFilterData(orgNameList);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // useEffect(() => {
  // fetchNonAcoFilters();
  // fetchNonAcoNetworkNameFilters();
  // fetchNonAcoStateFilters();
  // }, []);

  const makeCsv = () => {
    setDwnloadFlag(true);
  };
  const createExcel = async () => {
    setLoading(true);
    dispatch(startLoader());
    if (totalBufferData) {
      const filename = 'reports.xlsx';
      var ws = utils.json_to_sheet(totalBufferData);
      var wb = utils.book_new();
      utils.book_append_sheet(wb, ws, 'People');
      writeFile(wb, filename);
      setDwnloadFlag(false);
      setTimeout(() => {
        setLoading(false);
        dispatch(stopLoader());
      }, 1000);
    }
  };
  const createCsvFile = async () => {
    let csvData = jsonToCsv(totalBufferData); // Add .items.data
    // Create a CSV file and allow the user to download it
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'Report.csv';
    document.body.appendChild(a);
    a.click();
    setDwnloadFlag(false);
  };

  function jsonToCsv(jsonData) {
    let csv = '';
    // Get the headers
    let headers = Object.keys(jsonData[0]);
    csv += headers.join(',') + '\n';
    // Add the data
    jsonData.forEach(function (row) {
      let data = headers.map((header) => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
      csv += data + '\n';
    });
    return csv;
  }

  useEffect(() => {
    resetFields();
  }, [isAcoFlag]);
  const resetFields = () => {
    setTotalBufferData('');
    setOrgNameValue([]);
    setNetworkNameValue([]);
    setPrvType([]);
    setTierValue([]);
    setStateValue([]);
    setNonAcoTotalRecords('');
    setOrgNameFilterData([]);
    setNtwrkNameFilterData([]);
    setStateNameFilterData([]);
    setTierValueList([]);
    setProvTypeList([]);
    dispatch({
      type: 'SET_NON_ACO_TABLE_DATA',
      value: [],
    });
    dispatch({
      type: 'SET_NON_ACO_TABLE_DATA_COUNT',
      value: 0,
    });
  };
  const provTypeVal = (prvType) => {
    let provTypeList = [];
    prvType.forEach((item) => {
      if (item === 'Professional Provider') {
        provTypeList.push('P');
      } else if (item === 'Facility Provider') {
        provTypeList.push('F');
      } else if (item === 'Group Provider') {
        provTypeList.push('G');
      } else if (item === 'Non-Healthcare ') {
        provTypeList.push('S');
      }
    });

    return provTypeList;
  };
  const fetchNonACOTableData = async () => {
    // let ORG_NAME = `'${orgNamevalue}'`;
    // let NETWORKNAME = `'${networkNamevalue}'`;
    // let PROVTYPE = `'${provTypeVal(prvTypeValue)}'`; //`'${prvTypeValue}'`;
    // let TIERVALUE = `'${tierValue}'`;
    // let STATE = `'${stateValue}'`;

    let payload = {
      orgNameLists: orgNamevalue,
      networkNameList: networkNamevalue,
      provTypeList: provTypeVal(prvTypeValue),
      tierValueList: tierValue,
      stateValueList: stateValue,
    };

    axios
      .post(`${serverUrl}/fetchNonACOTableData`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          setNonAcoRecords(response.data.results);
          dispatch({
            type: 'SET_NON_ACO_TABLE_DATA',
            value: response.data.results,
          });

          setLoading(false);
          dispatch(stopLoader());
        } else {
          // dispatch({
          //   type: 'SET_ACO_TABLE_DATA',
          //   value: [],
          // });
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const getNonAcoProvTypeList = (selValue) => {
    // let ORG_NAME = `'${orgNamevalue}'`;
    // let NETWORKNAME = `'${selValue}'`;
    let payload = {
      orgNameLists: orgNamevalue,
      networkNameList: selValue,
    };
    axios
      .post(`${serverUrl}/getNonAcoProvTypeList`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.PROVTYPE,
              label: item.PROVTYPE,
            });
          });

          setProvTypeList(formatedList);
          setLoading(false);
          dispatch(stopLoader());
        } else {
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // (error);
      });
  };
  const getNonTierValueList = (selValue) => {
    let payload = {
      orgNameLists: orgNamevalue,
      networkNameList: networkNamevalue,
      provTypeList: selValue,
    };

    axios
      .post(`${serverUrl}/getNonTierValueList`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.TIERVALUE,
              label: item.TIERVALUE,
            });
          });

          setTierValueList(formatedList);
          setLoading(false);
          dispatch(stopLoader());
        } else {
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // (error);
      });
  };

  const fetchNonACOTableDataCount = async () => {
    // let ORG_NAME = `'${orgNamevalue}'`;
    // let NETWORKNAME = `'${networkNamevalue}'`;
    // let PROVTYPE = `'${provTypeVal(prvTypeValue)}'`;
    // let TIERVALUE = `'${tierValue}'`;
    // let STATE = `'${stateValue}'`;
    let payload = {
      orgNameLists: orgNamevalue,
      networkNameList: networkNamevalue,
      provTypeList: provTypeVal(prvTypeValue),
      tierValueList: tierValue,
      stateValueList: stateValue,
    };

    axios
      .post(`${serverUrl}/fetchNonACOTableDataCount`, { data: payload })
      .then((response) => {
        dispatch({
          type: 'SET_NON_ACO_TABLE_DATA_COUNT',
          value: response.data.results,
        });
        const responseData = response.data.results;

        if (responseData && responseData != 'undefined' && responseData !== 0) {
          setNonAcoTotalRecords(responseData);

          // setLoading(false);
          // dispatch(stopLoader());
        } else {
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const applyFilter = async () => {
    await fetchNonACOTableDataCount();
    setLoading(true);
    dispatch(startLoader());
    fetchNonACOTableData();
    fetchTotalRecordsForDownload();
  };

  const fetchTotalRecordsForDownload = async () => {
    let totalBatch = 15;
    let currentBatch = 0;

    let totalData = [];
    while (currentBatch < totalBatch) {
      const START = currentBatch * 30000 + 1;
      const END = (currentBatch + 1) * 30000;

      let payload = {
        orgNameLists: orgNamevalue,
        networkNameList: networkNamevalue,
        provTypeList: provTypeVal(prvTypeValue),
        tierValueList: tierValue,
        stateValueList: stateValue,
        START: START,
        END: END,
      };
      axios
        .post(`${serverUrl}/download-nonAco-excel`, { data: payload })
        .then((response) => {
          const responseData = response.data.results;
          if (responseData && responseData?.length && responseData.length > 0) {
            totalData = totalData.concat(response.data.results);

            if (currentBatch == totalBatch) {
              setTotalBufferData(totalData);
            }

            setLoading(false);
            dispatch(stopLoader());
          } else {
            // setLoading(false);
            // dispatch(stopLoader());
          }
        })
        .catch((error) => {
          // console.log(error);
        });
      currentBatch = currentBatch + 1;
    }
  };

  const onChangeOrgName = (selValue) => {
    if (selValue && selValue.length >= 3) {
      fetchNonAcoFilters(selValue);
    }
  };
  const onSelectedOrgName = (selVal) => {
    fetchNonAcoNetworkNameFilters(selVal);
  };

  const onChangeNetworkName = (selVal) => {
    getNonAcoProvTypeList(selVal);
  };
  const onChangeProvType = (selVal) => {
    getNonTierValueList(selVal);
  };
  const onChangeTierValue = (selected) => {
    fetchNonAcoStateFilters(selected);
  };
  const handleCloseMSG = () => {
    /* istanbul ignore next */
    setDwnloadFlag(false);
    // window.location.reload();
  };
  let disableFlag = true;

  if (
    totalBufferData?.length + 1 == nonAcoTotalRecords ||
    totalBufferData?.length == 450000
  ) {
    disableFlag = false;
  }
  return (
    <React.Fragment>
      <>
        <React.Fragment>
          <Backdrop data-testid="loading-1" open={isLoading}>
            <CircularProgress color="primary" />
          </Backdrop>
        </React.Fragment>
        <Loader open={loading} />
        {dwnloadFlag && (
          <ExportComponent
            open={dwnloadFlag}
            handleClose={handleCloseMSG}
            createExcel={createExcel}
            createCsvFile={createCsvFile}
            message="Submission failed.  Please submit screenshot of error to R&T."
            // errorName={errorNameMessage}
            errorCase="err"
          />
        )}
        <Grid container spacing={-30}>
          <Grid item xs={12} sm={2}>
            <SelectInputMulti
              ref={txt1}
              label="Org_Name *"
              placeholder="Please Select"
              // width={225}
              value={orgNamevalue}
              onInputChange={(e) => {
                onChangeOrgName(e);
              }}
              onChange={(e) => {
                onSelectedOrgName(e);
                setOrgNameValue(e);
              }}
              onClear={() => setOrgNameValue([])}
              isClearable
              isSearchable
              options={orgNameFilter}
            />
            {!orgNamevalue?.length && (
              <p
                style={{
                  marginRight: '45px',
                  color: 'red',
                  fontSize: 'smaller',
                }}
              >
                Please type min 3 char*
              </p>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <SelectInputMulti
              selectAll
              ref={txt1}
              label="Ntwrk_Name *"
              placeholder="Please Select"
              // width={225}
              value={networkNamevalue}
              onChange={(e) => {
                onChangeNetworkName(e);
                setNetworkNameValue(e);
              }}
              onClear={() => setNetworkNameValue([])}
              isClearable
              isSearchable
              options={networkNameFilter}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SelectInputMulti
              selectAll
              ref={txt1}
              label="Prv_Type *"
              placeholder="Please Select"
              // width={225}
              value={prvTypeValue}
              onChange={(e) => {
                onChangeProvType(e);
                setPrvType(e);
              }}
              onClear={() => setPrvType([])}
              isClearable
              isSearchable
              options={provTypeList}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SelectInputMulti
              selectAll
              ref={txt1}
              label="Tier_Val *"
              placeholder="Please Select"
              // width={225}
              value={tierValue}
              onChange={(e) => {
                onChangeTierValue(e);
                setTierValue(e);
              }}
              onClear={() => setTierValue([])}
              isClearable
              isSearchable
              options={tierValueList}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <SelectInputMulti
              selectAll
              ref={txt1}
              label="State *"
              placeholder="Please Select"
              // width={225}
              value={stateValue}
              onChange={setStateValue}
              onClear={() => setStateValue('')}
              isClearable
              isSearchable
              options={stateNameFilter}
            />
          </Grid>
        </Grid>
        <Divider />
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}></Grid>
          <Grid item xs={6} md={4}>
            <Button
              onClick={() => applyFilter()}
              style={{
                padding: '0px 26px 0px 26px',
                fontSize: '12px',
              }}
              data-testid="saveButton"
              isDisabled={!isDisabledFlag}
            >
              Apply Filter
            </Button>
            <Button
              variant="outline"
              onClick={() => resetFields()}
              style={{
                padding: '0px 26px 0px 26px',
                fontSize: '12px',
                marginLeft: '10px',
              }}
              data-testid="resetButton"
            >
              RESET
            </Button>

            <Button
              variant="outline"
              onClick={() => makeCsv()}
              style={{
                padding: '0px 26px 0px 26px',
                fontSize: '12px',
                marginLeft: '10px',
              }}
              data-testid="exportButton"
              isDisabled={disableFlag}
            >
              EXPORT
            </Button>
          </Grid>
        </Grid>
      </>
    </React.Fragment>
  );
};

export default NonAcoComponent;
