/* eslint-disable no-param-reassign */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { hostServer } from './constants';

export const fetchTinFilterOptions = (
  payload,
  setTinFiltersLoading,
  setTinFilterOptions,
  distinctTinFilterOptions,
  setDistinctTinFilterOptions
) => {
  payload.column = 'TIN';
  setTinFiltersLoading(true);
  axios
    .post(`${hostServer.dev}/efffff2232323/filters`, {
      data: payload,
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        const formatedList = [];
        response.data.results.forEach((item) => {
          formatedList.push({
            value: `${item.TIN}`,
            label: `${item.TIN}`,
            id: item.TIN,
          });
        });

        setTinFilterOptions(formatedList);

        if (distinctTinFilterOptions.length === 0) {
          setDistinctTinFilterOptions(formatedList);
        }
        setTinFiltersLoading(false);
      }
    })
    .catch((error) => {
      console.error(error);
      setTinFiltersLoading(false);
    });
};

export const fetchMpinFilterOptions = (
  payload,
  setMpinFiltersLoading,
  setMpinFilterOptions,
  setDistinctMpinFilterOptions,
  distinctMpinFilterOptions
) => {
  payload.column = 'MPIN';
  setMpinFiltersLoading(true);
  axios
    .post(`${hostServer.dev}/efffff2232323/filters`, {
      data: payload,
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        const formatedList = [];
        response.data.results.forEach((item) => {
          formatedList.push({
            value: `${item.MPIN}`,
            label: `${item.MPIN}`,
            id: item.MPIN,
          });
        });

        setMpinFilterOptions(formatedList);

        if (distinctMpinFilterOptions.length === 0) {
          setDistinctMpinFilterOptions(formatedList);
        }
        setMpinFiltersLoading(false);
      }
    })
    .catch((error) => {
      console.error(error);
      setMpinFiltersLoading(false);
    });
};

export const fetchProviderNameFilterOptions = (
  payload,
  setProviderNameFiltersLoading,
  setProviderNameFilterOptions,
  setDistinctProviderNameFilterOptions,
  distinctProviderNameFilterOptions
) => {
  payload.column = 'PROVIDER_NAME';
  setProviderNameFiltersLoading(true);
  axios
    .post(`${hostServer.dev}/efffff2232323/filters`, {
      data: payload,
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        const formatedList = [];
        response.data.results.forEach((item) => {
          formatedList.push({
            value: item.PROVIDER_NAME,
            label: item.PROVIDER_NAME?.trim(),
            id: item.PROVIDER_NAME?.trim(),
          });
        });

        setProviderNameFilterOptions(formatedList);

        if (distinctProviderNameFilterOptions.length === 0) {
          setDistinctProviderNameFilterOptions(formatedList);
        }

        setProviderNameFiltersLoading(false);
      }
    })
    .catch((error) => {
      console.error(error);
      setProviderNameFiltersLoading(false);
    });
};

export const fetchOrgTypeFilterOptions = (
  payload,
  setOrgTypeFiltersLoading,
  setOrgTypeFilterOptions,
  setDistinctOrgTypeFilterOptions,
  distinctOrgTypeFilterOptions
) => {
  payload.column = 'ORG_TYPE';
  setOrgTypeFiltersLoading(true);
  axios
    .post(`${hostServer.dev}/efffff2232323/filters`, {
      data: payload,
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        const formatedList = [];
        response.data.results.forEach((item) => {
          formatedList.push({
            value: `${item.ORG_TYPE}`,
            label: `${item.ORG_TYPE}`,
            id: item.ORG_TYPE,
          });
        });

        setOrgTypeFilterOptions(formatedList);

        if (distinctOrgTypeFilterOptions.length === 0) {
          setDistinctOrgTypeFilterOptions(formatedList);
        }
        setOrgTypeFiltersLoading(false);
      }
    })
    .catch((error) => {
      console.error(error);
      setOrgTypeFiltersLoading(false);
    });
};

export const fetchDdpStatusFilterOptions = (
  payload,
  setDdpStatusFiltersLoading,
  setDdpStatusFilterOptions,
  setDistinctDdpStatusFilterOptions,
  distinctDdpStatusFilterOptions
) => {
  payload.column = 'DDP_STATUS';
  setDdpStatusFiltersLoading(true);
  axios
    .post(`${hostServer.dev}/efffff2232323/filters`, {
      data: payload,
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        const formatedList = [];
        response.data.results.forEach((item) => {
          formatedList.push({
            value: item.DDP_STATUS,
            label: item.DDP_STATUS,
            id: item.DDP_STATUS,
          });
        });
        setDdpStatusFilterOptions(formatedList);

        if (distinctDdpStatusFilterOptions.length === 0) {
          setDistinctDdpStatusFilterOptions(formatedList);
        }
        setDdpStatusFiltersLoading(false);
      }
    })
    .catch((error) => {
      console.error(error);
      setDdpStatusFiltersLoading(false);
    });
};

export const fetchStartDateFilterOptions = (
  payload,
  setStartDateFiltersLoading,
  setStartDateFilterOptions,
  setDistinctStartDateFilterOptions,
  distinctStartDateFilterOptions
) => {
  payload.column = 'START_DATE';
  setStartDateFiltersLoading(true);

  axios
    .post(`${hostServer.dev}/efffff2232323/filters`, {
      data: payload,
    })
    .then((response) => {
      const responseData = response.data.results;
      if (responseData && responseData?.length && responseData.length > 0) {
        const formatedList = [];
        response.data.results.forEach((item) => {
          formatedList.push({
            value: item.START_DATE,
            label: item.START_DATE,
            id: item.START_DATE,
          });
        });

        setStartDateFilterOptions(formatedList);

        if (distinctStartDateFilterOptions.length === 0) {
          setDistinctStartDateFilterOptions(formatedList);
        }
        setStartDateFiltersLoading(false);
      }
    })
    .catch((error) => {
      console.error(error);
      setStartDateFiltersLoading(false);
    });
};
