/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { SelectInputMulti } from '@abyss/web/ui/SelectInputMulti';
import Grid from '@mui/material/Grid';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import { Label } from '@abyss/web/ui/Label';
import { RootStateOrAny, useSelector } from 'react-redux';
import Dropdown from '../../components/Dropdown';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { startLoader, showDialog, stopLoader } from '../../store/actions';
import Loader from '../../components/Loader';
import { read, utils, write, writeFile, writeFileXLSX } from 'xlsx';
import { hostServer, options } from '../../utils/constants';
import NonAcoComponent from './BodySubsection';
import { current } from '@reduxjs/toolkit';
import { saveAs } from 'file-saver';
import ExportComponent from '../../components/ExportComponents';
const serverUrl = hostServer.dev;

// import { Grid } from '@abyss/web/ui/Grid';
/**
 * Used for processing ACO Data
 */

const MapComponent = (props) => {
  let txt1 = useRef(null);

  useEffect(() => {
    // txt1.current.focus();
  }, []);
  const [acoNamevalue, setAcoNameValue] = React.useState([]);
  const [isChecked, setChecked] = useState(true);
  const [networkIds, setNtwrkIDS] = React.useState([]);
  const [prvTypeValue, setPrvType] = React.useState([]);
  const [tierValue, setTierValue] = React.useState([]);
  const [stateValue, setStateValue] = React.useState([]);
  const [isLoading, setLoading] = React.useState(false);
  const [isDisableFlag, setIsDisableFlag] = React.useState(false);
  const [acotableData, setAcoTableData] = React.useState([]);
  const [countTotalRecords, setCountTotalRecords] = React.useState([]);
  const [linkedNetworkIDs, setNetworkIDs] = React.useState([]);
  const [provTypeList, setProvTypeList] = React.useState([]);
  const [tierValList, setTierValList] = React.useState([]);
  const [stateValList, setStateValList] = React.useState([]);

  const [totalBufferData, setTotalBufferData] = React.useState([]);

  const [isAbyssLoading, setIsAbyssLoading] = useState(false);
  const [dwnloadFlag, setDwnloadFlag] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);

  const loading = useSelector((state) => {
    return state.loading;
  });
  const dispatch = useDispatch();
  const classes = {
    root: {
      flexGrow: 1,
      overflowX: 'hidden',
    },
  };

  const acoNameList = props.acoFilterData;
  let isDisabledFlag = false;

  if (
    acoNamevalue?.length > 0 &&
    networkIds?.length > 0 &&
    prvTypeValue?.length > 0 &&
    tierValue?.length > 0 &&
    stateValue?.length > 0
  ) {
    isDisabledFlag = true;
  }

  const provTypeVal = (prvType) => {
    let provTypeList = [];
    prvType.forEach((item) => {
      if (item === 'Professional Provider') {
        provTypeList.push('P');
      } else if (item === 'Facility Provider') {
        provTypeList.push('F');
      } else if (item === 'Group Provider') {
        provTypeList.push('G');
      } else if (item === 'Non-Healthcare ') {
        provTypeList.push('S');
      }
    });

    return provTypeList;
  };
  const fetchAcoData = async () => {
    let payload = {
      acoList: acoNamevalue,
      networkId: networkIds,
      provTypeLists: provTypeVal(prvTypeValue),
      tierValueList: tierValue,
      stateValueList: stateValue,
    };

    await axios
      .post(`${serverUrl}/fetchACOTableData`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          // totalData = totalData.concat(response.data.results);
          setAcoTableData(response.data.results);
          dispatch({
            type: 'SET_ACO_TABLE_DATA',
            value: response.data.results,
          });
          // setAcoTableData(totalData);
          setLoading(false);
          dispatch(stopLoader());
        } else {
          dispatch({
            type: 'SET_ACO_TABLE_DATA',
            value: [],
          });
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const handleCheck = (e) => {
    dispatch({
      type: 'SET_ACO_FLAG',
      value: e.target.checked,
    });
    resetFields();
  };

  const applyFilter = async () => {
    await fetchTotalCountRecords();
    setLoading(true);
    dispatch(startLoader());
    fetchAcoData();
    // setTimeout(() => {
    fetchTotalRecordsForDownload();
    // }, 5000);
  };

  const fetchTotalRecordsForDownload = async () => {
    let totalBatch = 15;
    let currentBatch = 0;

    let totalData = [];
    while (currentBatch < totalBatch) {
      const START = currentBatch * 30000 + 1;
      const END = (currentBatch + 1) * 30000;

      let payload = {
        acoList: acoNamevalue,
        networkId: networkIds,
        provTypeLists: provTypeVal(prvTypeValue),
        tierValueList: tierValue,
        stateValueList: stateValue,
        START: START,
        END: END,
      };
      axios
        .post(`${serverUrl}/download-excel`, { data: payload })
        .then((response) => {
          const responseData = response.data.results;
          if (responseData && responseData?.length && responseData.length > 0) {
            totalData = totalData.concat(response.data.results);

            if (currentBatch == totalBatch) {
              setTotalBufferData(totalData);
            }

            setLoading(false);
            dispatch(stopLoader());
          } else {
            // setLoading(false);
            // dispatch(stopLoader());
          }
        })
        .catch((error) => {
          // console.log(error);
        });
      currentBatch = currentBatch + 1;
    }
  };

  const fetchTotalCountRecords = async () => {
    let payload = {
      acoList: acoNamevalue,
      networkId: networkIds,
      provTypeLists: provTypeVal(prvTypeValue),
      tierValueList: tierValue,
      stateValueList: stateValue,
    };
    axios
      .post(`${serverUrl}/fetchTotalCountRecords`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;

        if (responseData != 'undefined' && responseData != null) {
          setCountTotalRecords(response.data.results);
          // setTotalBufferData(response.data);
          dispatch({
            type: 'SET_ACO_TOTALCOUNT_DATA',
            value: response.data.results,
          });
          // setLoading(false);
          // dispatch(stopLoader());
        } else {
          // dispatch({
          //   type: 'SET_ACO_TABLE_DATA',
          //   value: [],
          // });
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const makeCsv = async () => {
    setDwnloadFlag(true);
    // return;
  };
  const createCsvFile = async () => {
    let csvData = jsonToCsv(totalBufferData); // Add .items.data
    // Create a CSV file and allow the user to download it
    let blob = new Blob([csvData], { type: 'text/csv' });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = 'Report.csv';
    document.body.appendChild(a);
    a.click();
    setDwnloadFlag(false);
    // setLoading(true);
  };

  function jsonToCsv(jsonData) {
    let csv = '';
    // Get the headers
    let headers = Object.keys(jsonData[0]);
    csv += headers.join(',') + '\n';
    // Add the data
    jsonData.forEach(function (row) {
      let data = headers.map((header) => JSON.stringify(row[header])).join(','); // Add JSON.stringify statement
      csv += data + '\n';
    });
    return csv;
  }
  // useEffect(() => {

  // }, [checkdata]);

  const createExcel = async () => {
    dispatch({ type: 'SET_LOADING_FLAG', value: true });
    // setLoading(true);
    // dispatch(startLoader());
    setCheckLoading(true);

    if (totalBufferData?.length > 0) {
      // setIsAbyssLoading(true);
      const wb = utils.book_new();
      const outputData = totalBufferData.map(Object.values);
      outputData.unshift(Object.keys(totalBufferData[0]));
      const ws = utils.aoa_to_sheet(outputData, { dense: true, raw: true });
      utils.book_append_sheet(wb, ws);

      writeFile(wb, 'Download.xlsx', {
        type: 'binary',
        bookSST: true,
        compression: true,
      });
      // setIsAbyssLoading(false);
      // setLoading(false);
      // dispatch(stopLoader());
      dispatch({ type: 'SET_LOADING_FLAG', value: false });
      // setCheckLoading(false);
      // console.log('end function');

      setDwnloadFlag(false);
    }
  };

  // const loadingCheck = useSelector((state) => {
  //   return state?.checkLoading;
  // });

  const resetFields = () => {
    setAcoNameValue([]);
    setNtwrkIDS([]);
    setPrvType([]);
    setTierValue([]);
    setStateValue([]);
    setCountTotalRecords('');
    setTotalBufferData('');
    setNetworkIDs([]);
    setProvTypeList([]);
    setTierValList([]);
    setStateValList([]);
    dispatch({
      type: 'SET_ACO_TABLE_DATA',
      value: [],
    });
    dispatch({
      type: 'SET_ACO_TOTALCOUNT_DATA',
      value: 0,
    });
  };
  const getBaseNetwotkID = (selValue) => {
    axios
      .post(`${serverUrl}/getBaseNetwotkID`, { data: selValue })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let networkList = [];
          response.data.results.map((item) => {
            networkList.push({
              value: item.BASENETWORKIDS,
              label: item.BASENETWORKIDS,
            });
          });
          setNetworkIDs(networkList);
          setLoading(false);
          dispatch(stopLoader());
        } else {
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getProvTypeList = (selValue) => {
    let payload = { acoList: acoNamevalue, networkId: selValue };
    axios
      .post(`${serverUrl}/getProvTypeList`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.PROVTYPE,
              label: item.PROVTYPE,
            });
          });

          setProvTypeList(formatedList);
          setLoading(false);
          dispatch(stopLoader());
        } else {
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // (error);
      });
  };

  const getTierValList = (selValue) => {
    let payload = {
      acoList: acoNamevalue,
      networkId: networkIds,
      provTypeLists: selValue,
    };

    axios
      .post(`${serverUrl}/getTierValList`, { data: payload })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.TIERVALUE,
              label: item.TIERVALUE,
            });
          });

          setTierValList(formatedList);
          setLoading(false);
          dispatch(stopLoader());
        } else {
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const getStateValueList = (selValue) => {
    let payload = {
      acoList: acoNamevalue,
      networkId: networkIds,
      provTypeLists: prvTypeValue,
      tierValueList: selValue,
    };

    axios
      .post(`${serverUrl}/getStateValList`, { data: payload })

      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          let formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.STATE,
              label: item.STATE,
            });
          });

          setStateValList(formatedList);
          setLoading(false);
          dispatch(stopLoader());
        } else {
          setLoading(false);
          dispatch(stopLoader());
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  const onChangeAcoName = (selectedVal) => {
    getBaseNetwotkID(selectedVal);
  };

  const onChangeNetworkId = (selectedVal) => {
    getProvTypeList(selectedVal);
  };

  const onChangeProvType = (selectedVal) => {
    getTierValList(selectedVal);
  };
  const onChangeTierVal = (selectedVal) => {
    getStateValueList(selectedVal);
  };
  let disableFlag = true;
  if (
    totalBufferData?.length + 1 == countTotalRecords ||
    totalBufferData?.length == 450000
  ) {
    disableFlag = false;
  }
  const handleCloseMSG = () => {
    /* istanbul ignore next */
    setDwnloadFlag(false);
    // window.location.reload();
  };
  return (
    <React.Fragment>
      <Backdrop data-testid="loading-1" open={isLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
      <React.Fragment>
        <div style={classes.root}>
          <Loader open={loading} />
          {dwnloadFlag && (
            <ExportComponent
              open={dwnloadFlag}
              handleClose={handleCloseMSG}
              createExcel={createExcel}
              createCsvFile={createCsvFile}
              setLoading={setLoading}
            />
          )}

          {/* <LoadingSpinner
            size="$lg"
            isLoading={isAbyssLoading}
            ariaLoadingLabel="Loading page"
            color="$interactive1"
          >
            <IconMaterial
              color="$interactive1"
              icon="upload_file"
              size="35px"
            />
          </LoadingSpinner> */}
          <Grid container style={{ margin: '3px' }}>
            <Grid item xs={12} sm={2}>
              <Grid style={{ margin: '28px 0px 0px 60px' }}>
                <ToggleSwitch
                  label="ACO"
                  isChecked={isChecked}
                  onChange={(e) => {
                    handleCheck(e);
                    setChecked(e.target.checked);
                  }}
                />
              </Grid>
            </Grid>
            {isChecked ? (
              <>
                <Grid container spacing={-30}>
                  <Grid item xs={12} sm={2}>
                    <SelectInputMulti
                      ref={txt1}
                      label="ACO_Name *"
                      placeholder="Please Select"
                      // width={225}
                      value={acoNamevalue}
                      onChange={(e) => {
                        setAcoNameValue(e);
                        onChangeAcoName(e);
                      }}
                      onClear={() => setAcoNameValue([])}
                      isClearable
                      isSearchable
                      // onfocus={(e) => {
                      //   onfocusFun(e);
                      // }}
                      // ref="selectInput"
                      // onMouseOut={(e) => {
                      //   onBlurFun(e);
                      // }}
                      // hideChips={isChecked}
                      options={acoNameList}
                      css={{
                        '.abyss-chip-root': {
                          width: '50% !important',
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SelectInputMulti
                      selectAll
                      ref={txt1}
                      label="Base_Ntwrk_ID *"
                      placeholder="Please Select"
                      // width={225}
                      value={networkIds}
                      onChange={(e) => {
                        onChangeNetworkId(e);
                        setNtwrkIDS(e);
                      }}
                      onClear={() => setNtwrkIDS('')}
                      isClearable
                      isSearchable
                      options={linkedNetworkIDs} // {baseNetworkIds}
                    />
                    {/* </Grid> */}
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SelectInputMulti
                      selectAll
                      ref={txt1}
                      label="Prv_Type *"
                      placeholder="Please Select"
                      // width={225}
                      value={prvTypeValue}
                      onChange={(e) => {
                        onChangeProvType(e);
                        setPrvType(e);
                      }}
                      onClear={() => setPrvType('')}
                      isClearable
                      isSearchable
                      options={provTypeList} //{provTypes}
                    />
                  </Grid>{' '}
                  <Grid item xs={12} sm={2}>
                    <SelectInputMulti
                      selectAll
                      ref={txt1}
                      label="Tier_Val *"
                      placeholder="Please Select"
                      // width={225}
                      value={tierValue}
                      onChange={(e) => {
                        onChangeTierVal(e);
                        setTierValue(e);
                      }}
                      onClear={() => setTierValue('')}
                      isClearable
                      isSearchable
                      options={tierValList}
                      //[
                      //{ label: 'one', value: '1' },
                      // { label: 'Zero', value: '0' },
                      // ]} //{tierValueList} tierValList
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <SelectInputMulti
                      selectAll
                      ref={txt1}
                      label="State *"
                      placeholder="Please Select"
                      // width={225}
                      value={stateValue}
                      onChange={setStateValue}
                      onClear={() => setStateValue('')}
                      isClearable
                      isSearchable
                      // onKeyDown
                      options={stateValList} //{stateList}
                    />
                  </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2}>
                  <Grid item xs={6} md={8}></Grid>
                  <Grid item xs={6} md={4}>
                    <Button
                      onClick={() => applyFilter()}
                      style={{
                        padding: '0px 26px 0px 26px',
                        fontSize: '12px',
                      }}
                      data-testid="saveButton"
                      isDisabled={!isDisabledFlag}
                    >
                      Apply Filter
                    </Button>
                    <Button
                      variant="outline"
                      onClick={() => resetFields()}
                      style={{
                        padding: '0px 26px 0px 26px',
                        fontSize: '12px',
                        marginLeft: '10px',
                      }}
                      data-testid="resetButton"
                    >
                      RESET
                    </Button>

                    {/* <Grid item xs={12} sm={2}> */}
                    {/* <Grid container item row> */}
                    <Button
                      variant="outline"
                      isLoading={isAbyssLoading}
                      onClick={() => makeCsv()}
                      style={{
                        padding: '0px 26px 0px 26px',
                        fontSize: '12px',
                        marginLeft: '10px',
                      }}
                      data-testid="exportButton"
                      isDisabled={disableFlag}
                    >
                      EXPORT
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : (
              <NonAcoComponent />
            )}
          </Grid>
        </div>
      </React.Fragment>
    </React.Fragment>
  );
};
export default MapComponent;
