import React, { useState, useEffect } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { DateInput } from '@abyss/web/ui/DateInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import axios from 'axios';
import { Button } from '@abyss/web/ui/Button';
import { useSelector } from 'react-redux';
import { hostServer } from '../../../utils/constants';

export const NewSubscriptionModal = ({
  subscription,
  setEditSubscription,
  fetchSubscriptions,
}) => {
  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [afterSaveMessage, setAfterSaveMessage] = useState('');

  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState('Daily');
  const [subscriptionWeekDay, setSubscriptionWeekDay] = useState('');
  const [subscriptionMonthDay, setSubscriptionMonthDay] = useState('');

  const [additionalRecepients, setAdditionRecepients] = useState('');

  const [isInputsValid, setIsValidInput] = useState(true);

  const [repeatMessage, setRepeatMessage] = useState('daily');

  const [weekDays, setWeekDays] = useState([]);

  function checkEmailDomain(email) {
    const allowedDomains = ['uhc', 'uhg', 'optum'];
    const domain = email.split('@')[1].split('.')[0];

    return allowedDomains.includes(domain);
  }

  function checkValidEmailAddress(email) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email.match(emailRegex);
  }

  function validateEnteredAdditionaRecipients(inputValue) {
    if (inputValue.length > 0) {
      const additionalRecepientsArray = inputValue.split(',');

      const emailDiv = document.getElementById('email-error');

      for (let i = 0; i < additionalRecepientsArray.length; i++) {
        // validateEmailInAzureAD(additionalRecepientsArray[i]);
        if (!checkValidEmailAddress(additionalRecepientsArray[i])) {
          emailDiv.style.display = 'block';
          emailDiv.innerText = 'Please enter a valid email address!!';
          setIsValidInput(false);
          return;
        }
        if (!checkEmailDomain(additionalRecepientsArray[i])) {
          emailDiv.style.display = 'block';
          emailDiv.innerText = 'External email addresses are not allowed!!';
          setIsValidInput(false);
          return;
        }
      }
      if (
        additionalRecepients !== subscription?.ADDITIONAL_RECIPIENT_EMAILADDRESS
      ) {
        setIsValidInput(true);
      }
    }
  }

  const editSubscription = () => {
    const payload = {
      subscriptionId: subscription?.SUBSCRIPTION_ID || '',
      userEmail: activeAccountEmail,
      additionalRecipients:
        subscription?.RECIPIENT_EMAILADDRESS !== additionalRecepients
          ? { RECIPIENT_EMAILADDRESS: additionalRecepients, changed: 1 }
          : { RECIPIENT_EMAILADDRESS: additionalRecepients, changed: 0 },
      TIN: subscription?.SUBSCRIPTION_TIN_FILTERS || [],
      MPIN: subscription?.SUBSCRIPTION_MPIN_FILTERS || [],
      PROVIDER_NAME: subscription?.SUBSCRIPTION_PROVIDER_NAME_FILTERS || [],
      ORG_TYPE: subscription?.SUBSCRIPTION_ORG_TYPE_FILTERS || [],
      DDP_STATUS: subscription?.SUBSCRIPTION_DDP_STATUS_FILTERS || [],
      START_DATE: subscription?.SUBSCRIPTION_START_DATE_FILTERS || [],
      subscriptionFrequency:
        subscription?.SUBSCRIPTION_FREQUENCY !== subscriptionFrequency
          ? { SUBSCRIPTION_FREQUENCY: subscriptionFrequency, changed: 1 }
          : { SUBSCRIPTION_FREQUENCY: subscriptionFrequency, changed: 0 },
      subscriptionStartDate:
        subscription?.SUBSCRIPTION_START_DATE !== subscriptionStartDate
          ? { SUBSCRIPTION_START_DATE: subscriptionStartDate, changed: 1 }
          : { SUBSCRIPTION_START_DATE: subscriptionStartDate, changed: 0 },
      subscriptionEndDate:
        subscription?.SUBSCRIPTION_END_DATE !== subscriptionEndDate
          ? { SUBSCRIPTION_END_DATE: subscriptionEndDate, changed: 1 }
          : { SUBSCRIPTION_END_DATE: subscriptionEndDate, changed: 0 },
      subscriptionWeekDay:
        subscription?.SUBSCRIPTION_WEEK_DAY !== subscriptionWeekDay
          ? { SUBSCRIPTION_WEEK_DAY: subscriptionWeekDay, changed: 1 }
          : { SUBSCRIPTION_WEEK_DAY: subscriptionWeekDay, changed: 0 },
      subscriptionMonthDay:
        subscription?.SUBSCRIPTION_MONTH_DAY !== subscriptionMonthDay
          ? { SUBSCRIPTION_MONTH_DAY: subscriptionMonthDay, changed: 1 }
          : { SUBSCRIPTION_MONTH_DAY: subscriptionMonthDay, changed: 0 },
    };

    axios
      .post(`${hostServer.dev}/subscription/updateSubscription`, {
        data: payload,
      })
      .then((response) => {
        setAfterSaveMessage('Data Saved Successfully.');
        setShowSaveMessage(true);
        fetchSubscriptions();
        setTimeout(() => {
          setEditSubscription(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setShowSaveMessage(true);
        setAfterSaveMessage(
          'Unable to save the details. Please try after sometime.'
        );
        setTimeout(() => {
          setEditSubscription(false);
        }, 2000);
      });
  };

  useEffect(() => {
    setAdditionRecepients(subscription?.RECIPIENT_EMAILADDRESS);
    setSubscriptionFrequency(subscription?.SUBSCRIPTION_FREQUENCY);
    setSubscriptionWeekDay(subscription?.SUBSCRIPTION_WEEK_DAY);
    setSubscriptionMonthDay(subscription?.SUBSCRIPTION_MONTH_DAY);
    setSubscriptionStartDate(subscription?.SUBSCRIPTION_START_DATE);
    setSubscriptionEndDate(subscription?.SUBSCRIPTION_END_DATE);
  }, []);

  return (
    <React.Fragment>
      {!showSaveMessage ? (
        <React.Fragment>
          <Modal.Section
            css={{
              'abyss-modal-section-root': {
                paddingBottom: '4px',
              },
            }}
          >
            <h6>User email: {activeAccountEmail}</h6>
            <TextInput
              css={{
                'abyss-text-input-root': {
                  marginTop: '5px',
                  width: '60%',
                },
              }}
              label="Additional Recepient(s):"
              value={additionalRecepients}
              onChange={(e) => {
                setAdditionRecepients(e.target.value);
                setTimeout(() => {
                  document.getElementById('email-error').innerText = '';
                  validateEnteredAdditionaRecipients(e.target.value);
                }, 1000);
              }}
            />

            <div
              style={{
                display: 'none',
                fontSize: '16px',
                color: '#C40000',
                margin: '5px',
              }}
              id="email-error"
            />

            <div
              style={{
                color: '#424242',
                margin: '4px',
                padding: '2px',
                fontSize: '14px',
              }}
            >
              Note: Only UHG email addresses may be allowed within the
              subscription. Emails may not be sent to outside email addresses
              (i.e., gmail.com, yahoo.com, etc.).
            </div>
            {(subscription?.SUBSCRIPTION_TIN_FILTERS.length > 0 ||
              subscription?.SUBSCRIPTION_MPIN_FILTERS.length > 0 ||
              subscription?.SUBSCRIPTION_PROVIDER_NAME_FILTERS.length > 0 ||
              subscription?.SUBSCRIPTION_ORG_TYPE_FILTERS.length > 0 ||
              subscription?.SUBSCRIPTION_DDP_STATUS_FILTERS.length > 0 ||
              subscription?.SUBSCRIPTION_START_DATE_FILTERS.length > 0) && (
              <div
                style={{
                  marginTop: '10px',
                }}
              >
                <span
                  style={{
                    fontSize: '18px',
                    marginBottom: '4px',
                  }}
                >
                  Filters Selected
                </span>
                <hr />
                {subscription?.SUBSCRIPTION_TIN_FILTERS &&
                  subscription?.SUBSCRIPTION_TIN_FILTERS.length > 0 && (
                    <span
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <span
                        style={{
                          color: '#002677',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        TIN:
                      </span>{' '}
                      <span
                        style={{
                          fontSize: '17px',
                        }}
                      >
                        {subscription?.SUBSCRIPTION_TIN_FILTERS}
                      </span>
                    </span>
                  )}
                <br />
                {subscription?.SUBSCRIPTION_MPIN_FILTERS &&
                  subscription?.SUBSCRIPTION_MPIN_FILTERS.length > 0 && (
                    <span
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <span
                        style={{
                          color: '#002677',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        MPIN:
                      </span>{' '}
                      <span
                        style={{
                          fontSize: '17px',
                        }}
                      >
                        {subscription?.SUBSCRIPTION_MPIN_FILTERS}
                      </span>
                    </span>
                  )}
                <br />
                {subscription?.SUBSCRIPTION_PROVIDER_NAME_FILTERS &&
                  subscription?.SUBSCRIPTION_PROVIDER_NAME_FILTERS.length >
                    0 && (
                    <span
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <span
                        style={{
                          color: '#002677',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        PROVIDER_NAME:{' '}
                      </span>
                      <span
                        style={{
                          fontSize: '17px',
                        }}
                      >
                        {subscription?.SUBSCRIPTION_PROVIDER_NAME_FILTERS}
                      </span>
                    </span>
                  )}
                <br />
                {subscription?.SUBSCRIPTION_ORG_TYPE_FILTERS &&
                  subscription?.SUBSCRIPTION_ORG_TYPE_FILTERS.length > 0 && (
                    <span
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <span
                        style={{
                          color: '#002677',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        ORG_TYPE:
                      </span>{' '}
                      <span
                        style={{
                          fontSize: '17px',
                        }}
                      >
                        {subscription?.SUBSCRIPTION_ORG_TYPE_FILTERS}
                      </span>
                    </span>
                  )}
                <br />
                {subscription?.SUBSCRIPTION_DDP_STATUS_FILTERS &&
                  subscription?.SUBSCRIPTION_DDP_STATUS_FILTERS.length > 0 && (
                    <span
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <span
                        style={{
                          color: '#002677',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        DDP_STATUS:{' '}
                      </span>
                      <span
                        style={{
                          fontSize: '17px',
                        }}
                      >
                        {subscription?.SUBSCRIPTION_DDP_STATUS_FILTERS}
                      </span>
                    </span>
                  )}
                <br />
                {subscription?.SUBSCRIPTION_START_DATE_FILTERS &&
                  subscription?.SUBSCRIPTION_START_DATE_FILTERS.length > 0 && (
                    <span
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <span
                        style={{
                          color: '#002677',
                          fontSize: '18px',
                          fontWeight: 'bold',
                        }}
                      >
                        START_DATE:{' '}
                      </span>
                      <span
                        style={{
                          fontSize: '17px',
                        }}
                      >
                        {subscription?.SUBSCRIPTION_START_DATE_FILTERS}
                      </span>
                    </span>
                  )}
              </div>
            )}

            <div
              style={{
                marginTop: '10px',
              }}
            >
              <h5>Schedule Date</h5>
              <div
                style={{
                  display: 'flex',
                }}
                className=""
              >
                <DateInput
                  label="Start Date"
                  value={subscriptionStartDate}
                  onChange={(e) => {
                    setSubscriptionStartDate(e);

                    if (typeof e === 'object') {
                      setIsValidInput(false);
                      const dateErrorDiv =
                        document.getElementById('date-error');
                      dateErrorDiv.innerHTML = 'Please select date!!';
                      dateErrorDiv.style.display = 'block';
                    } else if (additionalRecepients.length === 0) {
                      setIsValidInput(true);
                      const dateErrorDiv =
                        document.getElementById('date-error');
                      dateErrorDiv.style.display = 'none';
                    } else if (additionalRecepients.length !== 0) {
                      const emailErrorDiv =
                        document.getElementById('email-error');
                      if (emailErrorDiv.style.display === 'none') {
                        setIsValidInput(true);
                      }
                      const dateErrorDiv =
                        document.getElementById('date-error');
                      dateErrorDiv.style.display = 'none';
                    }
                  }}
                  css={{
                    'abyss-date-picker-root': {
                      width: '50% !important',
                      margin: '5px',
                    },
                  }}
                  minimumDate={Date.now()}
                />
                <DateInput
                  label="End Date"
                  value={subscriptionEndDate}
                  onChange={setSubscriptionEndDate}
                  css={{
                    'abyss-date-picker-root': {
                      width: '50% !important',
                      margin: '5px',
                    },
                  }}
                  minimumDate={Date.now()}
                />
              </div>
              <div
                style={{
                  display: 'none',
                  fontSize: '16px',
                  color: '#C40000',
                  margin: '5px',
                }}
                id="date-error"
              />
              <SelectInput
                label="Repeat"
                placeholder="Pick one"
                value={subscriptionFrequency}
                onChange={setSubscriptionFrequency}
                onClear={() => {
                  setSubscriptionFrequency('');
                }}
                options={[
                  { value: 'Daily', label: 'Daily' },
                  { value: 'Weekly', label: 'Weekly' },
                  { value: 'Monthly', label: 'Monthly' },
                ]}
                isClearable
                isSearchable
                css={{
                  'abyss-select-input-root': {
                    width: '50%',
                  },
                }}
              />
              {subscriptionFrequency === 'Weekly' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '10px 2px',
                  }}
                >
                  <div>
                    <h6>On</h6>
                  </div>

                  <div
                    className={
                      subscription?.SUBSCRIPTION_WEEK_DAY?.includes('0')
                        ? 'week-day selected-week-day'
                        : 'week-day'
                    }
                    onClick={(e) => {
                      if (subscriptionWeekDay.length > 0) {
                        if (subscriptionWeekDay.includes('0')) {
                          e.target.classList.remove('selected-week-day');
                          if (subscriptionWeekDay.includes('0,')) {
                            subscriptionWeekDay.replace('0,', '');
                          } else {
                            subscriptionWeekDay.replace('0', '');
                          }
                          // setWeekDays(weekDays.pop('Sunday'));
                        } else {
                          setSubscriptionWeekDay(`${subscriptionWeekDay},0`);
                          // setWeekDays.push(weekDays.push('Sunday'));
                          e.target.classList.add('selected-week-day');
                        }
                      } else {
                        setSubscriptionWeekDay(`0`);
                        // setWeekDays.push(weekDays.push('Sunday'));
                        e.target.classList.add('selected-week-day');
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    S
                  </div>

                  <div
                    className={
                      subscription?.SUBSCRIPTION_WEEK_DAY?.includes('1')
                        ? 'week-day selected-week-day'
                        : 'week-day'
                    }
                    onClick={(e) => {
                      if (subscriptionWeekDay.length > 0) {
                        if (subscriptionWeekDay.includes('1')) {
                          e.target.classList.remove('selected-week-day');
                          if (subscriptionWeekDay.includes('1,')) {
                            subscriptionWeekDay.replace('1,', '');
                          } else {
                            subscriptionWeekDay.replace('1', '');
                          }
                          // setWeekDays(weekDays.pop('Monday'));
                        } else {
                          setSubscriptionWeekDay(`${subscriptionWeekDay},1`);
                          // setWeekDays.push(weekDays.push('Monday'));
                          e.target.classList.add('selected-week-day');
                        }
                      } else {
                        setSubscriptionWeekDay(`1`);
                        // setWeekDays.push(weekDays.push('Monday'));
                        e.target.classList.add('selected-week-day');
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    M
                  </div>

                  <div
                    className={
                      subscription?.SUBSCRIPTION_WEEK_DAY?.includes('2')
                        ? 'week-day selected-week-day'
                        : 'week-day'
                    }
                    onClick={(e) => {
                      if (subscriptionWeekDay.length > 0) {
                        if (subscriptionWeekDay.includes('2')) {
                          e.target.classList.remove('selected-week-day');
                          if (subscriptionWeekDay.includes('2,')) {
                            subscriptionWeekDay.replace('2,', '');
                          } else {
                            subscriptionWeekDay.replace('2', '');
                          }
                          // setWeekDays(weekDays.pop('Tuesday'));
                        } else {
                          setSubscriptionWeekDay(`${subscriptionWeekDay},2`);
                          // setWeekDays.push(weekDays.push('Tuesday'));
                          e.target.classList.add('selected-week-day');
                        }
                      } else {
                        setSubscriptionWeekDay(`2`);
                        // setWeekDays.push(weekDays.push('Tuesday'));
                        e.target.classList.add('selected-week-day');
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    T
                  </div>

                  <div
                    className={
                      subscription?.SUBSCRIPTION_WEEK_DAY?.includes('3')
                        ? 'week-day selected-week-day'
                        : 'week-day'
                    }
                    onClick={(e) => {
                      if (subscriptionWeekDay.length > 0) {
                        if (subscriptionWeekDay.includes('3')) {
                          e.target.classList.remove('selected-week-day');
                          if (subscriptionWeekDay.includes('3,')) {
                            subscriptionWeekDay.replace('3,', '');
                          } else {
                            subscriptionWeekDay.replace('3', '');
                          }
                          // setWeekDays(weekDays.pop('Wednesday'));
                        } else {
                          setSubscriptionWeekDay(`${subscriptionWeekDay},3`);
                          // setWeekDays.push(weekDays.push('Wednesday'));
                          e.target.classList.add('selected-week-day');
                        }
                      } else {
                        setSubscriptionWeekDay(`3`);
                        // setWeekDays.push(weekDays.push('Wednesday'));
                        e.target.classList.add('selected-week-day');
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    W
                  </div>

                  <div
                    className={
                      subscription?.SUBSCRIPTION_WEEK_DAY?.includes('4')
                        ? 'week-day selected-week-day'
                        : 'week-day'
                    }
                    onClick={(e) => {
                      if (subscriptionWeekDay.length > 0) {
                        if (subscriptionWeekDay.includes('4')) {
                          e.target.classList.remove('selected-week-day');
                          if (subscriptionWeekDay.includes('4,')) {
                            subscriptionWeekDay.replace('4,', '');
                          } else {
                            subscriptionWeekDay.replace('4', '');
                          }
                          // setWeekDays(weekDays.pop('Thursday'));
                        } else {
                          setSubscriptionWeekDay(`${subscriptionWeekDay},4`);
                          // setWeekDays.push(weekDays.push('Thursday'));
                          e.target.classList.add('selected-week-day');
                        }
                      } else {
                        setSubscriptionWeekDay(`4`);
                        // setWeekDays.push(weekDays.push('Thursday'));
                        e.target.classList.add('selected-week-day');
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    T
                  </div>

                  <div
                    className={
                      subscription?.SUBSCRIPTION_WEEK_DAY?.includes('5')
                        ? 'week-day selected-week-day'
                        : 'week-day'
                    }
                    onClick={(e) => {
                      if (subscriptionWeekDay.length > 0) {
                        if (subscriptionWeekDay.includes('5')) {
                          e.target.classList.remove('selected-week-day');
                          if (subscriptionWeekDay.includes('5,')) {
                            subscriptionWeekDay.replace('5,', '');
                          } else {
                            subscriptionWeekDay.replace('5', '');
                          }
                          // setWeekDays(weekDays.pop('Friday'));
                        } else {
                          setSubscriptionWeekDay(`${subscriptionWeekDay},5`);
                          // setWeekDays.push(weekDays.push('Friday'));
                          e.target.classList.add('selected-week-day');
                        }
                      } else {
                        setSubscriptionWeekDay(`5`);
                        // setWeekDays.push(weekDays.push('Friday'));
                        e.target.classList.add('selected-week-day');
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    F
                  </div>

                  <div
                    className={
                      subscription?.SUBSCRIPTION_WEEK_DAY?.includes('6')
                        ? 'week-day selected-week-day'
                        : 'week-day'
                    }
                    onClick={(e) => {
                      if (subscriptionWeekDay.length > 0) {
                        if (subscriptionWeekDay.includes('6')) {
                          e.target.classList.remove('selected-week-day');
                          if (subscriptionWeekDay.includes('6,')) {
                            subscriptionWeekDay.replace('6,', '');
                          } else {
                            subscriptionWeekDay.replace('6', '');
                          }
                          // setWeekDays(weekDays.pop('Saturday'));
                        } else {
                          setSubscriptionWeekDay(`${subscriptionWeekDay},6`);
                          // setWeekDays.push(weekDays.push('Saturday'));
                          e.target.classList.add('selected-week-day');
                        }
                      } else {
                        setSubscriptionWeekDay(`6`);
                        // setWeekDays.push(weekDays.push('Saturday'));
                        e.target.classList.add('selected-week-day');
                      }
                    }}
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    S
                  </div>
                </div>
              )}

              {subscriptionFrequency === 'Monthly' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    margin: '10px 2px',
                  }}
                >
                  <TextInput
                    css={{
                      'abyss-text-input-root': {
                        marginTop: '5px',
                        width: '60%',
                      },
                    }}
                    label="Every month on day(s):"
                    placeholder="Ex: 1,27-30"
                    value={subscriptionMonthDay}
                    onChange={(e) => {
                      setSubscriptionMonthDay(e.target.value);
                    }}
                  />
                </div>
              )}
            </div>
            <div
              style={{
                color: '#424242',
                margin: '8px 4px 2px 4px',
                padding: '2px',
                fontSize: '16px',
              }}
            >
              Note: You will receive this report approximately 8AM CT based on
              the repeating schedule you've selected.
            </div>
          </Modal.Section>
          <Modal.Section>
            <div className="modal-primary-buttons">
              <Button
                size="sm"
                className="abyss-button"
                onClick={() => {
                  editSubscription();
                }}
                isDisabled={!isInputsValid}
              >
                Save
              </Button>
              <Button
                type="button"
                size="sm"
                variant="outline"
                className="abyss-button"
                onClick={() => {
                  setEditSubscription(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Section>
        </React.Fragment>
      ) : (
        <Modal.Section>
          <div
            style={{
              width: '60%',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            {afterSaveMessage.includes('Success') ? (
              <IconMaterial
                icon="done"
                variant="filled"
                color="$success1"
                size={64}
              />
            ) : (
              <IconMaterial
                icon="clear"
                variant="filled"
                color="$error1"
                size={64}
              />
            )}
            <h5>{afterSaveMessage}</h5>
          </div>
        </Modal.Section>
      )}
    </React.Fragment>
  );
};
