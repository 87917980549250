/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { FileUpload } from '@abyss/web/ui/FileUpload';
import { Modal } from '@abyss/web/ui/Modal';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { TextInput } from '@abyss/web/ui/TextInput';
import { DateInput } from '@abyss/web/ui/DateInput';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { Snackbar, Alert } from '@mui/material';
import { MultiSelect } from 'react-multi-select-component';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { read, utils, write } from 'xlsx';
import { startegicDisruptionColumn } from '@src/definitions/strategicDisruptionColumns';
import { Filters } from '@src/definitions/Filters';
import { hostServer } from '../../utils/constants';
import { SubscriptionModal } from '../SubscriptionModalStrategic/SubscriptionModal';

export const ReportBody = ({ table }) => {
  const [sheetColumns, setSheetColumns] = useState([]);
  const [sheetJsonData, setSheetJsonData] = useState({});
  const [fileName, setfileName] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tableDataAll, setTableDataAll] = useState([]);
  const [SubscribeButton, setSubscribeButton] = useState(true);

  const [showError, setShowError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [SubmitisLoading, setSubmitisLoading] = useState(false);
  const [FilterisLoading, setFilterisLoading] = useState(false);
  const [TinDropDown, setTinDropDown] = React.useState([]);
  const [MpinDropDown, setMpinDropDown] = React.useState([]);
  const [PidDropDown, setPidDropDown] = React.useState([]);
  const [NpiDropDown, setNpiDropDown] = React.useState([]);
  const [ONameDropDown, setONameDropDown] = React.useState([]);
  const [showSubscribeMessage, setShowSubscribeMessage] = useState(false);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);

  // Subscription Feature
  const [additionalRecepients, setAdditionRecepients] = useState('');
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState('Daily');
  const [subscriptionWeekDay, setSubscriptionWeekDay] = useState('Monday');
  const [subscriptionMonthDay, setSubscriptionMonthDay] = useState('');
  let uniqueSubtin = [];
  let uniqueSubmpin = [];

  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });

  const downloadExcel = () => {
    // Create an array of data to be exported to Excel
    const data = [
      ['MPIN', 'TIN'],
      [1010101, 2501010],
      [2001010, 3001010],
      [3001010, 3500000],
    ];

    // Create a new workbook
    const workbook = utils.book_new();

    // Convert the data array to a worksheet
    const worksheet = utils.aoa_to_sheet(data);

    // Add the worksheet to the workbook
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');

    // Convert the workbook to an Excel file
    const excelBuffer = write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    // Create a download URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;
    link.download = 'UploadFile_Template.xlsx';
    link.click();

    // Clean up the URL and link
    // URL.revokeObjectURL(url);
  };

  const dataTablePropsPagination = useDataTable({
    initialData: tableData,
    initialColumns: React.useMemo(() => {
      return startegicDisruptionColumn;
    }, []),
    showPagination: true,
    showTopPagination: true,
    showBottomPagination: true,
    showDownloadButton: true,
    paginationBottomCompact: true,
    pageSizeDefault: 20,
    downloadButtonConfig: {
      removeFiltered: true,
    },
    pageSizeOptions: [20, 40, 60, 80],
  });

  const [fileTin, setFileTin] = useState([]);
  const [fileMpin, setFileMpin] = useState([]);

  const strategicDisruptionFilters = new Filters();

  const [tinFilterOptions, setTinFilterOptions] = useState([]);
  const [tinFilters, setTinFilters] = useState([]);
  const [distinctTinFilterOptions, setDistinctTinFilterOptions] = useState([]);
  const [tinFiltersLoading, setTinFiltersLoading] = useState(false);

  const [mpinFilterOptions, setMpinFilterOptions] = useState([]);
  const [mpinFilters, setMpinFilters] = useState([]);
  const [distinctMpinFilterOptions, setDistinctMpinFilterOptions] = useState(
    []
  );
  const [mpinFiltersLoading, setMpinFiltersLoading] = useState(false);

  const [providerIdFilterOptions, setProviderIdFilterOptions] = useState([]);
  const [providerIdFilters, setProviderIdFilters] = useState([]);
  const [distinctProviderIdFilterOptions, setDistinctProviderIdFilterOptions] =
    useState([]);
  const [providerIdFiltersLoading, setProviderIdFiltersLoading] =
    useState(false);

  const [npiFilterOptions, setNpiFilterOptions] = useState([]);
  const [npiFilters, setNpiFilters] = useState([]);
  const [distinctNpiFilterOptions, setDistinctNpiFilterOptions] = useState([]);
  const [npiFiltersLoading, setNpiFiltersLoading] = useState(false);

  const [orgNameFilterOptions, setOrgNameFilterOptions] = useState([]);
  const [orgNameFilters, setOrgNameFilters] = useState([]);
  const [distinctOrgNameFilterOptions, setDistinctOrgNameFilterOptions] =
    useState([]);
  const [orgNameFiltersLoading, setOrgNameFiltersLoading] = useState(false);

  const fetchTinFilterOptions = (payload) => {
    payload.column = 'TIN';
    setTinFiltersLoading(true);
    axios
      .post(`${hostServer.dev}/fetchStrategicFilters`, {
        data: payload,
      })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          const formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: `${item.TIN}`,
              label: `${item.TIN}`,
              id: item.TIN,
            });
          });

          setTinFilterOptions(formatedList);

          if (distinctTinFilterOptions.length === 0) {
            setDistinctTinFilterOptions(formatedList);
          }
          setTinFiltersLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setTinFiltersLoading(false);
      });
  };

  const fetchMpinFilterOptions = (payload) => {
    payload.column = 'MPIN';
    setMpinFiltersLoading(true);
    axios
      .post(`${hostServer.dev}/fetchStrategicFilters`, {
        data: payload,
      })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          const formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: `${item.MPIN}`,
              label: `${item.MPIN}`,
              id: item.MPIN,
            });
          });

          setMpinFilterOptions(formatedList);

          if (distinctMpinFilterOptions.length === 0) {
            setDistinctMpinFilterOptions(formatedList);
          }
          setMpinFiltersLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setMpinFiltersLoading(false);
      });
  };

  const fetchProviderIdFilterOptions = (payload) => {
    payload.column = 'PROVIDER_ID';
    setProviderIdFiltersLoading(true);
    axios
      .post(
        // `${hostServer.dev}/fetchproviderIdFilterOptionsForNDBPLNDDPStatusTINMPINLevel`,
        `${hostServer.dev}/fetchStrategicFilters`,
        {
          data: payload,
        }
      )
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          const formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.PROVIDER_ID,
              label: item.PROVIDER_ID,
              id: item.PROVIDER_ID,
            });
          });

          setProviderIdFilterOptions(formatedList);

          if (distinctProviderIdFilterOptions.length === 0) {
            setDistinctProviderIdFilterOptions(formatedList);
          }

          setProviderIdFiltersLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setProviderIdFiltersLoading(false);
      });
  };

  const fetchNpiFilterOptions = (payload) => {
    payload.column = 'NPI';
    setNpiFiltersLoading(true);
    axios
      .post(`${hostServer.dev}/fetchStrategicFilters`, {
        data: payload,
      })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          const formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: item.NPI,
              label: item.NPI,
              id: item.NPI,
            });
          });
          setNpiFilterOptions(formatedList);

          if (distinctNpiFilterOptions.length === 0) {
            setDistinctNpiFilterOptions(formatedList);
          }
          setNpiFiltersLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setNpiFiltersLoading(false);
      });
  };

  const fetchOrgNameFilterOptions = (payload) => {
    payload.column = 'ORG_NAME';
    setOrgNameFiltersLoading(true);
    axios
      .post(
        // `${hostServer.dev}/fetchOrgTypeFilterOptionsForNDBPLNDDPStatusTINMPINLevel`,
        `${hostServer.dev}/fetchStrategicFilters`,
        {
          data: payload,
        }
      )
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          const formatedList = [];
          response.data.results.map((item) => {
            formatedList.push({
              value: `${item.ORG_NAME}`,
              label: `${item.ORG_NAME}`,
              id: item.ORG_NAME,
            });
          });
          setOrgNameFilterOptions(formatedList);

          if (distinctOrgNameFilterOptions.length === 0) {
            setDistinctOrgNameFilterOptions(formatedList);
          }
          setOrgNameFiltersLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
        setOrgNameFiltersLoading(false);
      });
  };

  const fetchStrategicDisruptionData = () => {
    const mpinData = [];
    const tinData = [];

    console.log('JSON_DATA', sheetJsonData);

    sheetJsonData?.forEach((item) => {
      if (item.MPIN) {
        mpinData.push(item.MPIN);
      }
      if (item.TIN) {
        tinData.push(item.TIN);
      }
      if (item['TAX ID']) {
        tinData.push(item['TAX ID']);
      }
      // eslint-disable-next-line
      // prettier-ignore
      // console.log('UNFORMATTED', item["Playbook Provider Name"]);
      // prettier-ignore
      // console.log('UNFORMATTED_MPIN', item.MPIN);

      // if (item['MPIN ']) {
      //   mpinData.push(item['MPIN ']);
      // }
    });
    uniqueSubtin = Array.from(new Set(tinData));
    uniqueSubmpin = Array.from(new Set(mpinData));

    console.log('Upload File Data ::::', mpinData, tinData);
    console.log(
      'Unique SUB TIN/MPIN (inside)::::',
      uniqueSubtin,
      uniqueSubmpin
    );

    const payload = {
      TIN: tinData,
      MPIN: mpinData,
    };

    const uniqueTin = [...new Set(tinData)].map((item) => {
      return {
        value: `${item}`,
        label: `${item}`,
        id: item,
      };
    });
    const uniqueMpin = [...new Set(mpinData)].map((item) => {
      return {
        value: `${item}`,
        label: `${item}`,
        id: item,
      };
    });

    console.log('UNIQUE TIN:::', uniqueTin);
    setFileTin(uniqueTin);
    setFileMpin(uniqueMpin);
    setTinFilterOptions(uniqueTin);
    setMpinFilterOptions(uniqueMpin);
    setDistinctMpinFilterOptions(uniqueMpin);
    setDistinctTinFilterOptions(uniqueTin);

    const filterPayload = {
      TIN: uniqueTin.length > 0 ? uniqueTin : [],
      MPIN: uniqueMpin.length > 0 ? uniqueMpin : [],
      PROVIDER_ID: [],
      ORG_NAME: [],
      NPI: [],
      SUB_TIN: uniqueTin,
      SUB_MPIN: uniqueMpin,
    };

    axios
      .post(`${hostServer.dev}/fetchStrategicDisruptionData`, {
        data: payload,
      })
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          // console.log('axios API===', responseData);
          setTableData(responseData); // Using it to set filter values
          dataTablePropsPagination.setData(responseData, true);
          setSubmitisLoading(false);

          if (tableDataAll.length === 0) {
            setTableDataAll(responseData);
          }
        }

        fetchProviderIdFilterOptions(filterPayload);
        fetchNpiFilterOptions(filterPayload);
        fetchOrgNameFilterOptions(filterPayload);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  // useEffect(() => {
  //   if (sheetJsonData && sheetJsonData.length && sheetJsonData.length > 0) {
  //     fetchStrategicDisruptionData();
  //   }
  // }, [sheetJsonData]);

  // console.log('Table Data::::', tableData);

  // const TINData = tableData.filter((item) => {
  //   //console.log('ITEM::', item.TIN);
  //   return item.TIN;
  // });

  const TinData = [];
  const MpinData = [];
  const PidData = [];
  const NpiData = [];
  const OrgData = [];
  tableData.forEach((item) => {
    TinData.push({ value: item.TIN, label: item.TIN });
    MpinData.push({ value: item.MPIN, label: item.MPIN });
    PidData.push({ value: item.PROVIDER_ID, label: item.PROVIDER_ID });
    NpiData.push({ value: item.NPI, label: item.NPI });
    OrgData.push({ value: item.ORG_NAME, label: item.ORG_NAME });
  });

  const SubTinData = [];
  const SubMpinData = [];
  tableData.forEach((item) => {
    SubTinData.push(item.TIN);
    SubMpinData.push(item.MPIN);
  });
  // Below Values Used for Settingup Subscription :
  const uniqueSubTinData = [...new Set(SubTinData)];
  const uniqueSubMpinData = [...new Set(SubMpinData)];
  // console.log('SubTin DATA::::', SubTinData);
  console.log('Unique SUB TIN/MPIN ::::', uniqueSubtin, uniqueSubmpin);

  // console.log('TIN DATA ::::', tinData);

  const uniqueTIN = [
    ...new Map(
      TinData.map((item) => {
        return [item.value, item];
      })
    ).values(),
  ];
  // console.log('uniqueTIN::::', uniqueTIN);
  // setTinFilterOptions(uniqueTIN);  too many re-renders

  const uniqueMPIN = [
    ...new Map(
      MpinData.map((item) => {
        return [item.value, item];
      })
    ).values(),
  ];
  // setMpinFilterOptions(uniqueMPIN);
  console.log('uniqueMPINCount:::', uniqueMPIN.length);

  const uniquePID = [
    ...new Map(
      PidData.map((item) => {
        return [item.value, item];
      })
    ).values(),
  ];

  const uniqueNPI = [
    ...new Map(
      NpiData.map((item) => {
        return [item.value, item];
      })
    ).values(),
  ];

  const uniqueOrg = [
    ...new Map(
      OrgData.map((item) => {
        return [item.value, item];
      })
    ).values(),
  ];

  const fetchFilterOptions = (columnFilterChanged, selectedValue) => {
    const payload = {};

    switch (columnFilterChanged) {
      case 'TIN':
        // payload = {
        //   TIN: selectedValue.length > 0 ? selectedValue : [],
        //   MPIN: mpinFilters.length > 0 ? mpinFilters : [],
        //   // PROVIDER_NAME:
        //   //   providerIdFilters.length > 0 ? providerIdFilters : [],
        // };
        // array = [Satyam]
        // eslint-disable-next-line no-case-declarations
        const dyanamicMPIN = [];
        tableData.forEach((item) => {
          if (
            selectedValue.includes(item.TIN) // &&
            //! dyanamicMPIN.includes(item.MPIN)
          ) {
            // DyanamicMPIN.push(item.MPIN);
            console.log('SELECTED TINS::::', selectedValue);
            dyanamicMPIN.push({ value: item.MPIN, label: item.MPIN });
            console.log('dyanamicMPIN(in loop)::::', dyanamicMPIN);
          }
        });

        const uniqueMPIN1 = [
          ...new Map(
            dyanamicMPIN.map((item) => {
              return [item.value, item];
            })
          ).values(),
        ];

        console.log('Dynamic_mpin', dyanamicMPIN);
        console.log('Dynamic_uniquempin', uniqueMPIN1);
        // uniqueMPIN = dyanamicMPIN;
        setMpinFilterOptions(uniqueMPIN1);
        console.log('uniqueMPIN.length::::', uniqueMPIN1.length);
        console.log('uniqueMPINDropdown::::', uniqueMPIN1);

        break;

      case 'MPIN':
        const dyanamicTIN = [];
        tableData.forEach((item) => {
          if (
            selectedValue.includes(item.MPIN) // &&
            //! dyanamicMPIN.includes(item.MPIN)
          ) {
            // DyanamicMPIN.push(item.MPIN);
            console.log('SELECED MPINS::::', selectedValue);
            dyanamicTIN.push({ value: item.TIN, label: item.TIN });
            console.log('dyanamicTIN(in loop)::::', dyanamicTIN);
          }
        });

        const uniqueTIN1 = [
          ...new Map(
            dyanamicTIN.map((item) => {
              return [item.value, item];
            })
          ).values(),
        ];

        console.log('Dynamic_mpin', dyanamicTIN);
        console.log('Dynamic_uniquempin', uniqueTIN1);
        // uniqueMPIN = dyanamicMPIN;
        setTinFilterOptions(uniqueTIN1);
        console.log('uniqueMPIN.length::::', uniqueTIN1.length);
        console.log('uniqueMPINDropdown::::', uniqueTIN1);

        break;

      default:
        break;
    }

    if (columnFilterChanged !== 'TIN'); // fetchTinFilterOptions(payload);
    if (columnFilterChanged !== 'MPIN'); // fetchMpinFilterOptions(payload);
    // if (columnFilterChanged !== 'PROVIDER_NAME')
    //   fetchproviderIdFilterOptions(payload);
  };

  // Adding  Column(s) Filters Programmatically
  // const filterTable = () => {
  //   dataTablePropsPagination.filter.setFilter('TIN', [
  //     {
  //       condition: 'equals',
  //       // filterValue: '131623978',
  //       filterValue: TinDropDown,
  //     },
  //   ]);
  //   dataTablePropsPagination.filter.setFilter('MPIN', [
  //     {
  //       condition: 'equals',
  //       filterValue: MpinDropDown,
  //     },
  //   ]);
  //   dataTablePropsPagination.filter.setFilter('PROVIDER_ORG_ID', [
  //     {
  //       condition: 'equals',
  //       filterValue: PidDropDown,
  //     },
  //   ]);
  //   dataTablePropsPagination.filter.setFilter('NPI', [
  //     {
  //       condition: 'equals',
  //       filterValue: NpiDropDown,
  //     },
  //   ]);
  //   dataTablePropsPagination.filter.setFilter('ORG_NAME', [
  //     {
  //       condition: 'equals',
  //       filterValue: ONameDropDown,
  //     },
  //   ]);
  // };

  //  Experimenting with DataTable Filter
  // const filterTable = () => {
  //   // console.log('inside filterTable:::', response.data.results);
  //   // dataTablePropsPagination.filter.setAllFilters(TinDropDown);

  //   console.log('TIN_DROPDOWN:', TinDropDown);
  //   console.log('MPIN_DROPDOWN:', MpinDropDown);
  //   dataTablePropsPagination.setData(
  //     [
  //       // {
  //       //   col1: 'Initial Col 1/Row 1',
  //       //   col2: 'Initial Col 2/Row 1',
  //       // },
  //     ],
  //     true
  //   );
  // };

  // const uniqueTIN = [...new Set(tinData)];

  const handleErrorClose = () => {
    setShowError(false);
  };

  const onFileChangeHandler = (e) => {
    if (e == 0 || e == []) {
      setShowError(true);
    }
    // console.log('filename', e[0]?.name);
    setfileName(e[0]?.name);

    const ext = e[0]?.name.split('.').pop();
    if (e) {
      if (ext !== 'csv' && ext !== 'xlsx' && ext !== 'xlx') {
        setShowError(true);
      } else {
        // setShowError(false);
        // setShowToster(true);
        setIsUploading(true);

        // dispatch(startLoader());
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;

          const workbook = read(data, { type: 'array' });
          // SetMainWorkBook(workbook);
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          // Get the range of the header row
          const headerRange = utils.decode_range(worksheet['!ref']);

          // Iterate over each cell in the header row
          for (
            let column = headerRange.s.c;
            column <= headerRange.e.c;
            column++
          ) {
            const cellAddress = utils.encode_cell({
              r: headerRange.s.r,
              c: column,
            });
            const cellValue = worksheet[cellAddress].v;
            const trimmedCellValue = cellValue.trim();
            const upperCaseValue = trimmedCellValue.toUpperCase();
            worksheet[cellAddress].v = upperCaseValue;
            worksheet[cellAddress].w = upperCaseValue;
          }

          const json = utils.sheet_to_json(worksheet, {
            raw: true,
            defval: null,
            blankrows: false,
          });
          setSheetJsonData(json);

          // setIsUploading(false);
          setTimeout(() => {
            setIsUploading(false);
          }, 3000);

          // if (json && json.length === 0) {
          //   setTimeout(() => {
          //     setShowToster(false);
          //     setEmptyFileError(true);
          //     dispatch(stopLoader());
          //     setLoading(false);
          //   }, 5000);
          //   return;
          // }

          // const allKeys = Object.keys(json[0]);
          const allKeys = Object.keys(json[0]).map((key) => {
            return key.trim();
          });

          console.log('allKeys', allKeys);
          const cols = allKeys.map((item) => {
            return { value: item };
          });

          cols.sort((a, b) => {
            if (a.value < b.value) {
              return -1;
            }
            if (a.value > b.value) {
              return 1;
            }
            return 0;
          });

          setSheetColumns(cols);
          // dispatch(stopLoader());
          // setLoading(false);
        };
        reader.readAsArrayBuffer(e[0]);
      }
    }
  };

  const sendDataOnSubmit = () => {
    if (sheetJsonData && sheetJsonData.length && sheetJsonData.length > 0) {
      fetchStrategicDisruptionData();
    }
  };

  const storeSubscriberDetails = () => {
    const payload = {
      userEmail: `${activeAccountEmail};${additionalRecepients}`,
      TIN: uniqueSubTinData || [],
      MPIN: uniqueSubMpinData,
      // PROVIDER_NAME: providerIdFilters,
      // ORG_TYPE: orgTypeFilters,
      // DDP_STATUS: ddpStatusFilters,
      // START_DATE: startDateFilters,
      subscriptionFrequency,
      subscriptionStartDate,
      subscriptionEndDate: subscriptionEndDate || '',
      subscriptionWeekDay: subscriptionWeekDay || '',
      subscriptionMonthDay: subscriptionMonthDay || '',
    };

    // alert(Object.values(payload));

    axios
      .post(`${hostServer.dev}/subscribeStrategicDisruption_REPORT`, {
        data: payload,
      })
      .then((response) => {
        setAfterSubscribeMessage('Data Saved Successfully.');
        setShowSubscribeMessage(true);
      })
      .catch((error) => {
        console.error(error);
        setShowSubscribeMessage(true);
        setAfterSubscribeMessage(
          'Unable to save the details. Please try after sometime.'
        );
      });
  };

  const fetchTableDataByFilters = () => {
    const payload = {
      TIN: tinFilters,
      MPIN: mpinFilters,
      PROVIDER_ID: providerIdFilters,
      NPI: npiFilters,
      ORG_NAME: orgNameFilters,
      SUB_TIN: fileTin,
      SUB_MPIN: fileMpin,
    };

    console.log(
      'payload:::',
      payload,
      TinDropDown.length,
      MpinDropDown.length,
      PidDropDown.length
    );
    if (
      tinFilters.length === 0 &&
      mpinFilters.length === 0 &&
      providerIdFilters.length === 0 &&
      npiFilters.length === 0 &&
      orgNameFilters.length === 0
    )
      // if (
      //   TinDropDown ||
      //   MpinDropDown ||
      //   PidDropDown ||
      //   NpiDropDown ||
      //   ONameDropDown
      // )
      alert('PLEASE SELECT FILTER(s) FIRST !');
    else {
      axios
        .post(`${hostServer.dev}/fetchStrategicDisruptionDataByFilter`, {
          data: payload,
        })
        .then((response) => {
          const responseData = response.data.results;

          console.log(
            'TESTING_FILTER_BUTTON',
            responseData,
            responseData.length,
            response.status
          );
          if (responseData && responseData?.length && responseData.length > 0) {
            // setTableData(responseData);

            dataTablePropsPagination.setData(responseData, true);
            setFilterisLoading(false);
          } else if (response.status === 200)
            alert('No data found for selected filters.');
          else {
            alert('We are facing some issues please try after sometime!');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  // useEffect(() => {
  //   if (tinFilters?.length > 0) {
  //     window.clearTimeout();
  //     window.setTimeout(() => {
  //       const payload = {
  //         TIN: tinFilters.length > 0 ? tinFilters : [],
  //         MPIN: mpinFilters.length > 0 ? mpinFilters : [],
  //         PROVIDER_ID: providerIdFilters.length > 0 ? providerIdFilters : [],
  //         ORG_NAME: orgNameFilters.length > 0 ? orgNameFilters : [],
  //         NPI: npiFilters.length > 0 ? npiFilters : [],
  //         SUB_TIN: fileTin,
  //         SUB_MPIN: fileMpin,
  //       };

  //       fetchMpinFilterOptions(payload);
  //       fetchProviderIdFilterOptions(payload);
  //       fetchOrgNameFilterOptions(payload);
  //       fetchNpiFilterOptions(payload);
  //     }, 2000);
  //   }
  // }, [tinFilters]);

  // useEffect(() => {
  //   if (mpinFilters?.length > 0) {
  //     window.clearTimeout();

  //     window.setTimeout(() => {
  //       const payload = {
  //         TIN: tinFilters.length > 0 ? tinFilters : [],
  //         MPIN: mpinFilters.length > 0 ? mpinFilters : [],
  //         PROVIDER_ID: providerIdFilters.length > 0 ? providerIdFilters : [],
  //         ORG_NAME: orgNameFilters.length > 0 ? orgNameFilters : [],
  //         NPI: npiFilters.length > 0 ? npiFilters : [],
  //         SUB_TIN: fileTin,
  //         SUB_MPIN: fileMpin,
  //       };

  //       fetchTinFilterOptions(payload);
  //       fetchProviderIdFilterOptions(payload);
  //       fetchOrgNameFilterOptions(payload);
  //       fetchNpiFilterOptions(payload);
  //     }, 2000);
  //   }
  // }, [mpinFilters]);

  // useEffect(() => {
  //   if (providerIdFilters?.length > 0) {
  //     window.clearTimeout();

  //     window.setTimeout(() => {
  //       const payload = {
  //         TIN: tinFilters.length > 0 ? tinFilters : [],
  //         MPIN: mpinFilters.length > 0 ? mpinFilters : [],
  //         PROVIDER_ID: providerIdFilters.length > 0 ? providerIdFilters : [],
  //         ORG_NAME: orgNameFilters.length > 0 ? orgNameFilters : [],
  //         NPI: npiFilters.length > 0 ? npiFilters : [],
  //         SUB_TIN: fileTin,
  //         SUB_MPIN: fileMpin,
  //       };

  //       fetchMpinFilterOptions(payload);
  //       fetchTinFilterOptions(payload);
  //       fetchOrgNameFilterOptions(payload);
  //       fetchNpiFilterOptions(payload);
  //     }, 2000);
  //   }
  // }, [providerIdFilters]);

  // useEffect(() => {
  //   if (orgNameFilters?.length > 0) {
  //     window.clearTimeout();

  //     window.setTimeout(() => {
  //       const payload = {
  //         TIN: tinFilters.length > 0 ? tinFilters : [],
  //         MPIN: mpinFilters.length > 0 ? mpinFilters : [],
  //         PROVIDER_ID: providerIdFilters.length > 0 ? providerIdFilters : [],
  //         ORG_NAME: orgNameFilters.length > 0 ? orgNameFilters : [],
  //         NPI: npiFilters.length > 0 ? npiFilters : [],
  //         SUB_TIN: fileTin,
  //         SUB_MPIN: fileMpin,
  //       };

  //       fetchMpinFilterOptions(payload);
  //       fetchProviderIdFilterOptions(payload);
  //       fetchTinFilterOptions(payload);
  //       fetchNpiFilterOptions(payload);
  //     }, 2000);
  //   }
  // }, [orgNameFilters]);

  // useEffect(() => {
  //   if (npiFilters?.length > 0) {
  //     window.clearTimeout();

  //     window.setTimeout(() => {
  //       const payload = {
  //         TIN: tinFilters.length > 0 ? tinFilters : [],
  //         MPIN: mpinFilters.length > 0 ? mpinFilters : [],
  //         PROVIDER_ID: providerIdFilters.length > 0 ? providerIdFilters : [],
  //         ORG_NAME: orgNameFilters.length > 0 ? orgNameFilters : [],
  //         NPI: npiFilters.length > 0 ? npiFilters : [],
  //         SUB_TIN: fileTin,
  //         SUB_MPIN: fileMpin,
  //       };

  //       fetchMpinFilterOptions(payload);
  //       fetchProviderIdFilterOptions(payload);
  //       fetchTinFilterOptions(payload);
  //       fetchOrgNameFilterOptions(payload);
  //     }, 2000);
  //   }
  // }, [npiFilters]);

  return (
    <React.Fragment>
      <title>Strategic Disruption Report</title>
      <div className="report-body-wrapper">
        <div className="filter-container-wrapper-sdrp">
          <div className="filter-container-sdrp">
            <section className="multi-select-container">
              <span className="multi-select-title">TIN:</span>
              <MultiSelect
                labelledBy="TIN"
                options={tinFilterOptions}
                // placeholder="Select TIN(s)"
                value={tinFilters}
                onChange={(selectedValues) => {
                  setTinFilters(selectedValues);

                  let removedItem = [];

                  if (!strategicDisruptionFilters.hasFilter('tin')) {
                    strategicDisruptionFilters.push('tin');
                  } else {
                    removedItem = strategicDisruptionFilters.pop('tin');
                  }

                  removedItem.reverse();

                  const payload = {
                    TIN:
                      selectedValues.length > 0 && !removedItem.includes('tin')
                        ? selectedValues
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_ID:
                      providerIdFilters.length > 0 &&
                      !removedItem.includes('providerId')
                        ? providerIdFilters
                        : [],
                    NPI:
                      npiFilters.length > 0 && !removedItem.includes('npi')
                        ? npiFilters
                        : [],
                    ORG_NAME:
                      orgNameFilters.length > 0 &&
                      !removedItem.includes('orgName')
                        ? orgNameFilters
                        : [],
                    SUB_TIN: fileTin,
                    SUB_MPIN: fileMpin,
                  };

                  let temporaryPayload = payload;

                  if (!strategicDisruptionFilters.hasFilter('mpin')) {
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('providerId')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('providerId'))
                      temporaryPayload.PROVIDER_ID = [];
                    fetchProviderIdFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('npi')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('npi')) temporaryPayload.NPI = [];
                    fetchNpiFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('orgName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgName'))
                      temporaryPayload.ORG_NAME = [];
                    fetchOrgNameFilterOptions(temporaryPayload);
                  }

                  if (removedItem.length > 0) {
                    strategicDisruptionFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={tinFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">MPIN:</span>
              <MultiSelect
                labelledBy="MPIN"
                options={mpinFilterOptions}
                // placeholder="Select MPIN(s)"
                value={mpinFilters}
                // onChange={setMpinFilters}
                onChange={(selectedValues) => {
                  setMpinFilters(selectedValues);

                  let removedItem = [];

                  if (!strategicDisruptionFilters.hasFilter('mpin')) {
                    strategicDisruptionFilters.push('mpin');
                  } else {
                    removedItem = strategicDisruptionFilters.pop('mpin');
                  }

                  removedItem.reverse();

                  const payload = {
                    TIN:
                      tinFilters.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      selectedValues.length > 0 && !removedItem.includes('mpin')
                        ? selectedValues
                        : [],
                    PROVIDER_ID:
                      providerIdFilters.length > 0 &&
                      !removedItem.includes('providerId')
                        ? providerIdFilters
                        : [],
                    NPI:
                      npiFilters.length > 0 && !removedItem.includes('npi')
                        ? npiFilters
                        : [],
                    ORG_NAME:
                      orgNameFilters.length > 0 &&
                      !removedItem.includes('orgName')
                        ? orgNameFilters
                        : [],
                    SUB_TIN: fileTin,
                    SUB_MPIN: fileMpin,
                  };

                  let temporaryPayload = payload;

                  if (!strategicDisruptionFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.MPIN = [];
                    fetchTinFilterOptions(temporaryPayload);
                  }

                  if (!strategicDisruptionFilters.hasFilter('providerId')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('providerId'))
                      temporaryPayload.PROVIDER_ID = [];
                    fetchProviderIdFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('npi')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('npi')) temporaryPayload.NPI = [];
                    fetchNpiFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('orgName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgName'))
                      temporaryPayload.ORG_NAME = [];
                    fetchOrgNameFilterOptions(temporaryPayload);
                  }

                  if (removedItem.length > 0) {
                    strategicDisruptionFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={mpinFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">PROVIDER_ID:</span>
              <MultiSelect
                labelledBy="PROVIDER_ID"
                options={providerIdFilterOptions}
                // placeholder="Select PROVIDER_NAME(s)"
                value={providerIdFilters}
                onChange={(selectedValues) => {
                  setProviderIdFilters(selectedValues);

                  let removedItem = [];

                  if (!strategicDisruptionFilters.hasFilter('providerId')) {
                    strategicDisruptionFilters.push('providerId');
                  } else {
                    removedItem = strategicDisruptionFilters.pop('providerId');
                  }

                  removedItem.reverse();

                  const payload = {
                    TIN:
                      tinFilters.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_ID:
                      selectedValues.length > 0 &&
                      !removedItem.includes('providerId')
                        ? selectedValues
                        : [],
                    NPI:
                      npiFilters.length > 0 && !removedItem.includes('npi')
                        ? npiFilters
                        : [],
                    ORG_NAME:
                      orgNameFilters.length > 0 &&
                      !removedItem.includes('orgName')
                        ? orgNameFilters
                        : [],
                    SUB_TIN: fileTin,
                    SUB_MPIN: fileMpin,
                  };

                  let temporaryPayload = payload;

                  if (!strategicDisruptionFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(temporaryPayload);
                  }

                  if (!strategicDisruptionFilters.hasFilter('mpin')) {
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('npi')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('npi')) temporaryPayload.NPI = [];
                    fetchNpiFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('orgName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgName'))
                      temporaryPayload.ORG_NAME = [];
                    fetchOrgNameFilterOptions(temporaryPayload);
                  }

                  if (removedItem.length > 0) {
                    strategicDisruptionFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={providerIdFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">NPI:</span>
              <MultiSelect
                labelledBy="NPI"
                options={npiFilterOptions}
                value={npiFilters}
                onChange={(selectedValues) => {
                  setNpiFilters(selectedValues);

                  let removedItem = [];

                  if (!strategicDisruptionFilters.hasFilter('npi')) {
                    strategicDisruptionFilters.push('npi');
                  } else {
                    removedItem = strategicDisruptionFilters.pop('npi');
                  }

                  removedItem.reverse();

                  const payload = {
                    TIN:
                      tinFilters.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_ID:
                      providerIdFilters.length > 0 &&
                      !removedItem.includes('providerId')
                        ? providerIdFilters
                        : [],
                    NPI:
                      selectedValues.length > 0 && !removedItem.includes('npi')
                        ? selectedValues
                        : [],
                    ORG_NAME:
                      orgNameFilters.length > 0 &&
                      !removedItem.includes('orgName')
                        ? orgNameFilters
                        : [],
                    SUB_TIN: fileTin,
                    SUB_MPIN: fileMpin,
                  };

                  let temporaryPayload = payload;

                  if (!strategicDisruptionFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(temporaryPayload);
                  }

                  if (!strategicDisruptionFilters.hasFilter('mpin')) {
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('providerId')) {
                    if (removedItem.includes('providerId'))
                      temporaryPayload.PROVIDER_ID = [];
                    fetchProviderIdFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('orgName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgName'))
                      temporaryPayload.ORG_NAME = [];
                    fetchOrgNameFilterOptions(temporaryPayload);
                  }

                  if (removedItem.length > 0) {
                    strategicDisruptionFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={npiFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">ORG_NAME:</span>
              <MultiSelect
                labelledBy="ORG_NAME"
                options={orgNameFilterOptions}
                value={orgNameFilters}
                onChange={(selectedValues) => {
                  setOrgNameFilters(selectedValues);

                  let removedItem = [];

                  if (!strategicDisruptionFilters.hasFilter('orgName')) {
                    strategicDisruptionFilters.push('orgName');
                  } else {
                    removedItem = strategicDisruptionFilters.pop('orgName');
                  }

                  removedItem.reverse();

                  const payload = {
                    TIN:
                      tinFilters.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_ID:
                      providerIdFilters.length > 0 &&
                      !removedItem.includes('providerId')
                        ? providerIdFilters
                        : [],
                    NPI:
                      npiFilters.length > 0 && !removedItem.includes('npi')
                        ? npiFilters
                        : [],
                    ORG_NAME:
                      selectedValues.length > 0 &&
                      !removedItem.includes('orgName')
                        ? selectedValues
                        : [],
                    SUB_TIN: fileTin,
                    SUB_MPIN: fileMpin,
                  };

                  let temporaryPayload = payload;

                  if (!strategicDisruptionFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(temporaryPayload);
                  }

                  if (!strategicDisruptionFilters.hasFilter('mpin')) {
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('providerId')) {
                    if (removedItem.includes('providerId'))
                      temporaryPayload.PROVIDER_ID = [];
                    fetchProviderIdFilterOptions(temporaryPayload);
                  }
                  if (!strategicDisruptionFilters.hasFilter('npi')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('npi')) temporaryPayload.NPI = [];
                    fetchNpiFilterOptions(temporaryPayload);
                  }

                  if (removedItem.length > 0) {
                    strategicDisruptionFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={orgNameFiltersLoading}
              />
            </section>
          </div>

          <Snackbar
            open={showError}
            autoHideDuration={6000}
            onClose={handleErrorClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <Alert severity="error">
              <strong>Only excel Files Accepted!</strong>
            </Alert>
          </Snackbar>
          <div className="button-wrapper">
            <FileUpload
              maxMessage={false}
              maxFiles={1}
              label="FileUpload Sandbox"
              uploadMessage="Drag Your Filter File Here"
              // noIcon
              dragDisabled={false}
              // isDisabled={!checkDisable || rosterName == ''}
              hideLabel
              isUploading={isUploading}
              css={{
                'abyss-file-upload-body-container': {
                  // width: '70% !important',
                  justifyContent: 'space-around',
                  // flexDirection: 'row',
                  height: '200px!important',
                  // padding: '5px',
                },
              }}
              // fileTypes={fileTypesAccepted || ''}
              onChange={(e) => {
                return onFileChangeHandler(e);
              }}
            />

            <Button
              size="sm"
              className="abyss-button"
              variant="outline"
              onClick={downloadExcel}
              // css={{
              //   'abyss-button-root': {
              //     width: '30% !important',
              //      height: '60px!important',
              //     // padding: '5px',
              //   },
              // }}
            >
              Download: Upload File Template
            </Button>

            <Button
              size="sm"
              className="abyss-button"
              isLoading={SubmitisLoading}
              ariaLoadingLabel="Loading Data"
              onClick={() => {
                setSubscribeButton(false);
                setSubmitisLoading(true);
                sendDataOnSubmit();

                // setTimeout(() => {
                //   setIsLoading(false);
                // }, 3000);
              }}
            >
              Submit
            </Button>

            {/* <Button
              size="sm"
              className="abyss-button"
              disabled
              // onClick={() => {
              //   sendDataOnSubmit();
              // }}
            >
              Search
            </Button> */}
          </div>
        </div>
      </div>

      {/* <div className="report-table-record-count">
        <h6
          css={{
            'abyss-heading-root': {
              textAlign: 'right',
            },
          }}
        >
          Showing Records:{tableData?.length} Out of Records :
          {tableData?.length}
        </h6>
      </div> */}
      {/* <div className="report-table">
        <div className="pagination-buttons-container" />
        <Table title="Table Scroll" columns={table?.columns} rows={tableData} />
      </div> */}

      <React.Fragment>
        <Modal
          title="Usage Instructions :"
          isOpen={isOpen}
          onClose={() => {
            return setIsOpen(false);
          }}
        >
          <Modal.Section>
            Please follow these steps in order to execute this report, subscribe
            to it, or filter/view the report on screen:
            <ol type="1">
              <li>
                1. Drag or upload the file of MPINs and TINs which you would
                like to use.
              </li>
              <li>2. Click Submit to run the report.</li>
              <li>
                3. Once submitted, you may subscribe to the report. Click
                Subscribe and fill in the details. Your subscription will be
                based upon the file you've uploaded. If you need the report to
                be run on a different list of MPINs/TINs, you will need to set
                up a new subscription - along with a new file. Only 1 list of
                MPINs/TINs may be used on a subscription at a time. You cannot
                subscribe to two different lists of MPINs/TINs.
              </li>
              <li>
                4. You may filter the results you're viewing on the screen.
                Simply choose from the filters at the top (TIN, MPIN,
                PROVIDER_ID, NPI, ORG_NAME). When you make selections in any of
                the filters, note the circular icon which indicates that the
                data is being processed - please wait for that to finish before
                you select other filters.
              </li>
            </ol>
          </Modal.Section>
        </Modal>
      </React.Fragment>
      <Tooltip content="Instruction Manual">
        <Button
          onClick={() => {
            return setIsOpen(true);
          }}
          aria-label="Instructions"
          rounded
        >
          <IconBrand icon="document" size={35} />
        </Button>
      </Tooltip>
      <Button
        size="sm"
        // onClick={fetchTableDataByFilters}
        isLoading={FilterisLoading}
        ariaLoadingLabel="Loading Data"
        onClick={() => {
          // setSubscribeButton(false);
          setFilterisLoading(true);
          fetchTableDataByFilters();

          // setTimeout(() => {
          //   setIsLoading(false);
          // }, 3000);
        }}
      >
        Filter
      </Button>
      <Button
        size="sm"
        className="abyss-button"
        variant="outline"
        onClick={() => {
          setTinFilters([]);
          setMpinFilters([]);
          setProviderIdFilters([]);
          setNpiFilters([]);
          setOrgNameFilters([]);
          setTinFilterOptions(distinctTinFilterOptions);
          setMpinFilterOptions(distinctMpinFilterOptions);
          setProviderIdFilterOptions(distinctProviderIdFilterOptions);
          setNpiFilterOptions(distinctNpiFilterOptions);
          setOrgNameFilterOptions(distinctOrgNameFilterOptions);

          strategicDisruptionFilters.clear();

          dataTablePropsPagination.setData(tableDataAll, true);
        }}
      >
        Clear
      </Button>
      <Button
        // isDisabled={SubscribeButton}
        aria-describedby="Work In Progress"
        size="sm"
        className="abyss-button"
        onClick={(e) => {
          e.preventDefault();
          if (openSubscribeModal == true) {
            setOpenSubscribeModal(false);
          } else {
            setOpenSubscribeModal(true);
          }
        }}
        css={{
          'abyss-button-root': {
            // width: '50% !important',
            // float: 'right',
          },
        }}
      >
        Subscribe
      </Button>
      <div>
        <SubscriptionModal
          openSubscribeModal={openSubscribeModal}
          tinFilters={uniqueSubTinData}
          mpinFilters={uniqueSubMpinData}
          fileName={fileName}
          // providerIdFilters={uni}
          // orgTypeFilters={orgTypeFilters}
          // ddpStatusFilters={ddpStatusFilters}
          // startDateFilters={startDateFilters}
          setOpenSubscribeModal={setOpenSubscribeModal}
          activeAccountEmail={activeAccountEmail}
        />
        <Modal
          title="Subscribe"
          isOpen={false}
          onClose={() => {
            setOpenSubscribeModal(false);
            setShowSubscribeMessage(false);
          }}
          size={400}
          css={{
            'abyss-modal-content-title': {
              fontSize: '26px!important',
            },
          }}
        >
          {!showSubscribeMessage ? (
            <React.Fragment>
              <Modal.Section
                css={{
                  'abyss-modal-section-root': {
                    paddingBottom: '4px',
                  },
                }}
              >
                <h6>User email: {activeAccountEmail}</h6>
                <TextInput
                  css={{
                    'abyss-text-input-root': {
                      marginTop: '5px',
                      width: '60%',
                    },
                  }}
                  label="Additional Recepient(s):"
                  value={additionalRecepients}
                  onChange={(e) => {
                    // setTimeout(() => {
                    //   setSubscriptionUserEmails(
                    //     subscriptionUserEmails + ';' + additionalRecepients
                    //   );
                    // }, 2000);

                    setAdditionRecepients(e.target.value);
                  }}
                />

                <div
                  style={{
                    color: '#424242',
                    margin: '4px',
                    padding: '2px',
                    fontSize: '14px',
                  }}
                >
                  Note: Only UHG email addresses may be allowed within the
                  subscription. Emails may not be sent to outside email
                  addresses (i.e., gmail.com, yahoo.com, etc.).
                </div>
                {(tinFilters.length > 0 || mpinFilters.length > 0) && (
                  <div
                    style={{
                      marginTop: '10px',
                    }}
                  >
                    <h6>Filters Selected</h6>
                    {tinFilters && tinFilters.length > 0 && (
                      <span
                        style={{
                          marginTop: '10px',
                          fontWeight: '1000',
                          color: '#002677',
                          fontSize: '16px',
                        }}
                      >
                        TIN: {tinFilters.join(',')}
                      </span>
                    )}
                    <br />
                    {mpinFilters && mpinFilters.length > 0 && (
                      <span
                        style={{
                          marginTop: '10px',
                          fontWeight: '1000',
                          color: '#002677',
                          fontSize: '16px',
                        }}
                      >
                        MPIN: {mpinFilters.join(',')}
                      </span>
                    )}
                    <br />
                    {/* {providerIdFilters && providerIdFilters.length > 0 && (
                      <span
                        style={{
                          marginTop: '10px',
                          fontWeight: '1000',
                          color: '#002677',
                          fontSize: '16px',
                        }}
                      >
                        PROVIDER_NAME: {providerIdFilters.join(',')}
                      </span>
                    )}
                    <br />
                    {orgTypeFilters && orgTypeFilters.length > 0 && (
                      <span
                        style={{
                          marginTop: '10px',
                          fontWeight: '1000',
                          color: '#002677',
                          fontSize: '16px',
                        }}
                      >
                        ORG_TYPE: {orgTypeFilters.join(',')}
                      </span>
                    )}
                    <br />
                    {ddpStatusFilters && ddpStatusFilters.length > 0 && (
                      <span
                        style={{
                          marginTop: '10px',
                          fontWeight: '1000',
                          color: '#002677',
                          fontSize: '16px',
                        }}
                      >
                        DDP_STATUS: {ddpStatusFilters.join(',')}
                      </span>
                    )}
                    <br />
                    {startDateFilters && startDateFilters.length > 0 && (
                      <span
                        style={{
                          marginTop: '10px',
                          fontWeight: '1000',
                          color: '#002677',
                          fontSize: '16px',
                        }}
                      >
                        START_DATE: {startDateFilters.join(',')}
                      </span>
                    )} */}
                  </div>
                )}

                <div
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <h5>Schedule Date</h5>
                  <div
                    style={{
                      display: 'flex',
                    }}
                    className=""
                  >
                    <DateInput
                      label="Start Date"
                      value={subscriptionStartDate}
                      onChange={setSubscriptionStartDate}
                      css={{
                        'abyss-date-picker-root': {
                          width: '50% !important',
                          margin: '5px',
                        },
                      }}
                      minimumDate={Date.now()}
                    />
                    <DateInput
                      label="End Date"
                      value={subscriptionEndDate}
                      onChange={setSubscriptionEndDate}
                      css={{
                        'abyss-date-picker-root': {
                          width: '50% !important',
                          margin: '5px',
                        },
                      }}
                      minimumDate={Date.now()}
                      required
                    />
                  </div>
                  <SelectInput
                    label="Repeat"
                    placeholder="Pick one"
                    value={subscriptionFrequency}
                    onChange={setSubscriptionFrequency}
                    onClear={() => {
                      setSubscriptionFrequency('');
                    }}
                    options={[
                      { value: 'Daily', label: 'Daily' },
                      { value: 'Weekly', label: 'Weekly' },
                      { value: 'Monthly', label: 'Monthly' },
                    ]}
                    isClearable
                    isSearchable
                    css={{
                      'abyss-select-input-root': {
                        width: '50%',
                      },
                    }}
                  />
                  {subscriptionFrequency === 'Weekly' && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '10px 2px',
                      }}
                    >
                      <div>
                        <h6>On</h6>
                      </div>

                      <div
                        style={{
                          width: '20px',
                          margin: '10px',
                          padding: '4px',
                          color: 'white',
                          backgroundColor: '#002677',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSubscriptionWeekDay('Sunday');
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        S
                      </div>

                      <div
                        style={{
                          width: '20px',
                          margin: '10px',
                          padding: '4px',
                          color: 'white',
                          backgroundColor: '#002677',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSubscriptionWeekDay('Monday');
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        M
                      </div>

                      <div
                        style={{
                          width: '20px',
                          margin: '10px',
                          padding: '4px',
                          color: 'white',
                          backgroundColor: '#002677',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSubscriptionWeekDay('Tuesday');
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        T
                      </div>

                      <div
                        style={{
                          width: '20px',
                          margin: '10px',
                          padding: '4px',
                          color: 'white',
                          backgroundColor: '#002677',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSubscriptionWeekDay('Wednesday');
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        W
                      </div>

                      <div
                        style={{
                          width: '20px',
                          margin: '10px',
                          padding: '4px',
                          color: 'white',
                          backgroundColor: '#002677',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSubscriptionWeekDay('Thursday');
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        T
                      </div>

                      <div
                        style={{
                          width: '20px',
                          margin: '10px',
                          padding: '4px',
                          color: 'white',
                          backgroundColor: '#002677',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSubscriptionWeekDay('Friday');
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        F
                      </div>

                      <div
                        style={{
                          width: '20px',
                          margin: '10px',
                          padding: '4px',
                          color: 'white',
                          backgroundColor: '#002677',
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSubscriptionWeekDay('Saturday');
                        }}
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        S
                      </div>
                    </div>
                  )}

                  {subscriptionFrequency === 'Monthly' && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: '10px 2px',
                      }}
                    >
                      <TextInput
                        css={{
                          'abyss-text-input-root': {
                            marginTop: '5px',
                            width: '60%',
                          },
                        }}
                        label="Every month on day(s):"
                        placeholder="Ex: 1,27-30"
                        value={subscriptionMonthDay}
                        onChange={(e) => {
                          setSubscriptionMonthDay(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    color: '#424242',
                    margin: '8px 4px 2px 4px',
                    padding: '2px',
                    fontSize: '16px',
                  }}
                >
                  Note: You will receive this report approximately 6AM-6:30AM CT
                  based on the repeating schedule you've selected.
                </div>
              </Modal.Section>
              <Modal.Section>
                <div className="modal-primary-buttons">
                  <Button
                    size="sm"
                    className="abyss-button"
                    onClick={() => {
                      storeSubscriberDetails();
                    }}
                  >
                    Subscribe
                  </Button>
                  <Button
                    size="sm"
                    variant="outline"
                    className="abyss-button"
                    onClick={() => {
                      setOpenSubscribeModal(false);
                    }}
                  >
                    Back
                  </Button>
                </div>
              </Modal.Section>
            </React.Fragment>
          ) : (
            <Modal.Section>
              <div
                style={{
                  width: '60%',
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                {afterSubscribeMessage.includes('Success') ? (
                  <IconMaterial icon="done" variant="filled" size={64} />
                ) : (
                  <IconMaterial
                    icon="clear"
                    variant="filled"
                    color="$error1"
                    size={64}
                  />
                )}
                <h5>{afterSubscribeMessage}</h5>
              </div>
            </Modal.Section>
          )}
        </Modal>
      </div>
      <DataTable
        // hideTitleHeader
        // title="Strategic Disruption Data"
        tableState={dataTablePropsPagination}
      />
    </React.Fragment>
  );
};
