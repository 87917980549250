/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import { styled } from '@abyss/web/tools/styled';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { EventType, PublicClientApplication } from '@azure/msal-browser';

import { ReportBody } from '@src/components/ReportBody';
import { hostServer } from '@src/utils/constants';
import { msalConfig } from '../authConfig';
import { InitiateLogin } from '../InitiateLogin/InitiateLogin';

const msalInstance = new PublicClientApplication(msalConfig);

if (
  msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for Sign In and set Active Account
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    msalInstance.setActiveAccount(account);
  }
});

const HomeContainer = styled('div', {
  textAlign: 'center',
  // mineight: '100%';
});

const MainContent = () => {
  const getAccoutDetails = useMsal();
  const dispatch = useDispatch();
  const currentAccountDetails = getAccoutDetails.instance.getAllAccounts()[0];
  const isFooter = useSelector((state: RootStateOrAny) => {
    return state?.isFileUploaded;
  });

  const activeUserGroups = useSelector((state) => {
    return state.activeUserGroups;
  });

  const [hours] = useState(new Date().getHours());
  const [greeting, setGreeting] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (hours < 12) {
      setGreeting('Good morning, ');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon, ');
    } else if (hours >= 16) {
      setGreeting('Good evening, ');
    }
  }, [greeting, hours]);

  const fetchUserGroups = async (loggedInUserEmail: string) => {
    const payload = {
      loggedInUserEmail,
    };
    axios
      .post(`${hostServer.dev}/auth/checkGroups`, {
        data: payload,
      })
      .then((response) => {
        const responseData = response.data.results;
        dispatch({
          type: 'SET_ACTIVE_USER_GROUP',
          value: responseData,
        });
        setIsLoading(false);
      })
      .catch((error: { error: Error }) => {
        console.log('Error when fetch user groups', error);
      });
  };

  useEffect(() => {
    setIsLoading(true);
    if (getAccoutDetails) {
      if (Object.keys(activeUserGroups).length === 0) {
        fetchUserGroups(currentAccountDetails?.username);
      }
      dispatch({
        type: 'SET_ACTIVE_ACCOUNT_EMAIL',
        value: currentAccountDetails?.username,
      });
      dispatch({
        type: 'SET_ACTIVE_ACCOUNT_ID',
        value: currentAccountDetails?.localAccountId,
      });
    }
  }, [getAccoutDetails, currentAccountDetails]);

  const ndb_pln_ddp_status_tin_mpin_level_report_config = {
    filters: {
      toggleFilters: [''],
      dropdownFilters: [
        'TIN',
        'MPIN',
        'PROVIDER_NAME',
        'ORG_TYPE',
        'DDP_STATUS',
        'START_DATE',
        'END_DATE',
      ],
    },
    table: {
      COLUMNS_CONFIG: {
        COLUMNS: [
          { key: 'TIN', name: 'TIN' },
          { key: 'MPIN', name: 'MPIN' },
          { key: 'PROVIDER_NAME', name: 'PROVIDER_NAME' },
          { key: 'ORG_TYPE', name: 'ORG_TYPE' },
          { key: 'DDP_STATUS', name: 'DDP_STATUS' },
          { key: 'START_DATE', name: 'START_DATE' },
          { key: 'END_DATE', name: 'END_DATE' },
        ],
        PHI_COLUMNS: [],
      },
    },
  };

  return (
    <React.Fragment>
      <MsalProvider instance={msalInstance}>
        <AuthenticatedTemplate>
          {activeUserGroups &&
          (activeUserGroups?.isUserNonPhiGroupMember ||
            activeUserGroups?.isUserPhiGroupMember ||
            activeUserGroups?.isUserPhiClaimGroupMember) ? (
            <React.Fragment>
              <title>
                NDB Pulling PLN/DDP Status Indicators at the TIN/MPIN Level
              </title>
              <HomeContainer>
                <PageHeader
                  logo={
                    <React.Fragment>
                      <div className="header-wrapper">
                        <Brandmark
                          brand="uhc"
                          affiliate="uhc"
                          variant="lockup"
                          color="blue"
                          className="brandmark-css"
                          css={{
                            'abyss-brandmark': {
                              marginTop: '0px',
                            },
                          }}
                        />
                        {/* <img
                      className="navbar-secondary-image"
                      src="./MicrosoftTeams-image.png"
                      alt="navbar-secondaryImage"
                    /> */}
                        <p className="report-title">
                          NDB Pulling PLN/DDP Status Indicators at the TIN/MPIN
                          Level
                        </p>
                      </div>
                    </React.Fragment>
                  }
                  model="pageheader-model"
                  css={{
                    'abyss-page-header-root': {
                      zIndex: 209,
                      borderColor: 'green',
                      borderStyle: 'dotted',
                      border: '1px solid #002677',
                      background: '#fcd299',
                    },
                  }}
                >
                  <PageHeader.Toolbar>
                    <div>
                      <h5 className="greeting">
                        {greeting}{' '}
                        {currentAccountDetails
                          ? `${currentAccountDetails.name}`
                          : 'Test'}
                      </h5>
                      <h5 className="greeting-secondary">
                        Welcome to USP Self-Service
                      </h5>
                    </div>
                  </PageHeader.Toolbar>
                </PageHeader>
                <ReportBody
                  {...ndb_pln_ddp_status_tin_mpin_level_report_config}
                />
              </HomeContainer>
            </React.Fragment>
          ) : (
            <LoadingOverlay
              loadingTitle="Please Wait..."
              loadingMessage="Retrieving Information...."
              ariaLoadingLabel="This is the ariaLoadingLabel prop."
              isLoading={isLoading}
              css={{
                'abyss-loading-overlay-root': {
                  marginTop: '200px',
                },
              }}
            />
          )}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <InitiateLogin />
        </UnauthenticatedTemplate>
      </MsalProvider>
    </React.Fragment>
  );
};

export const ReportHome = () => {
  return (
    <MsalProvider instance={msalInstance}>
      <MainContent />
    </MsalProvider>
  );
};

ReportHome.propTypes = {
  instance: PublicClientApplication.isRequired,
};
