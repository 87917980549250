import React from 'react';
import { createTheme } from '@abyss/web/tools/theme';
import { createRouter } from '@abyss/web/tools/createRouter';
import { AbyssProvider } from '@abyss/web/ui/AbyssProvider';
import { Provider } from 'react-redux';
import { Routes } from './routes/Routes';
import store from './store/store';

const theme = createTheme('uhc');
const router = createRouter(Routes);

export const browser = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
        <AbyssProvider theme={theme} router={router} />
      </Provider>
    </React.StrictMode>
  );
  {
    /* <AbyssProvider theme={theme} router={router} />; */
  }
};
