import React, { useEffect } from 'react';
import { PageBodyIntro } from '@abyss/web/ui/PageBodyIntro';
import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';

export const InitiateLogin = () => {
  const { instance } = useMsal();

  function handleLoginRedirect() {
    instance.loginRedirect(loginRequest).catch((error) => {
      return console.log(error);
    });
  }

  useEffect(() => {
    handleLoginRedirect();
  }, []);

  return (
    <React.Fragment>
      <UnauthenticatedTemplate>
        <PageBodyIntro>
          <PageBodyIntro.Row>
            <PageBodyIntro.Title>Initiating Login Process</PageBodyIntro.Title>
          </PageBodyIntro.Row>
        </PageBodyIntro>
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};
