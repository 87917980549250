/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect, useMemo } from 'react';
import { Button } from '@abyss/web/ui/Button';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { DateInput } from '@abyss/web/ui/DateInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { DataTable } from '@abyss/web/ui/DataTable';
import { useDataTable } from '@abyss/web/hooks/useDataTable';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { MultiSelect } from 'react-multi-select-component';
import { ndbPlnTableColumns } from '@src/definitions/ndbPlnTableColumns';
import {
  fetchDdpStatusFilterOptions,
  fetchMpinFilterOptions,
  fetchOrgTypeFilterOptions,
  fetchProviderNameFilterOptions,
  fetchStartDateFilterOptions,
  fetchTinFilterOptions,
} from '@src/utils/ndbPlnReportUtils';
import { Filters } from '@src/definitions/Filters';
import { hostServer } from '../../utils/constants';
import { SubscriptionModal } from '../SubscriptionModal/SubscriptionModal';

export const ReportBody = ({ table }) => {
  const ndbFilters = new Filters();
  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });

  const activeUserGroups = useSelector((state) => {
    return state.activeUserGroups;
  });

  const [tableData, setTableData] = useState([]);
  const [tableDataAll, setTableDataAll] = useState([]);

  const [tinFilterOptions, setTinFilterOptions] = useState([]);
  const [tinFilters, setTinFilters] = useState([]);
  const [distinctTinFilterOptions, setDistinctTinFilterOptions] = useState([]);
  const [tinFiltersLoading, setTinFiltersLoading] = useState(true);

  const [mpinFilterOptions, setMpinFilterOptions] = useState([]);
  const [mpinFilters, setMpinFilters] = useState([]);
  const [distinctMpinFilterOptions, setDistinctMpinFilterOptions] = useState(
    []
  );
  const [mpinFiltersLoading, setMpinFiltersLoading] = useState(true);

  const [providerNameFilterOptions, setProviderNameFilterOptions] = useState(
    []
  );
  const [providerNameFilters, setProviderNameFilters] = useState([]);
  const [
    distinctProviderNameFilterOptions,
    setDistinctProviderNameFilterOptions,
  ] = useState([]);
  const [providerNameFiltersLoading, setProviderNameFiltersLoading] =
    useState(true);

  const [orgTypeFilterOptions, setOrgTypeFilterOptions] = useState([]);
  const [orgTypeFilters, setOrgTypeFilters] = useState([]);
  const [distinctOrgTypeFilterOptions, setDistinctOrgTypeFilterOptions] =
    useState([]);
  const [orgTypeFiltersLoading, setOrgTypeFiltersLoading] = useState(true);

  const [ddpStatusFilterOptions, setDdpStatusFilterOptions] = useState([]);
  const [ddpStatusFilters, setDdpStatusFilters] = useState([]);
  const [distinctDdpStatusFilterOptions, setDistinctDdpStatusFilterOptions] =
    useState([]);
  const [ddpStatusFiltersLoading, setDdpStatusFiltersLoading] = useState(true);

  const [startDateFilterOptions, setStartDateFilterOptions] = useState([]);
  const [startDateFilters, setStartDateFilters] = useState([]);
  const [distinctStartDateFilterOptions, setDistinctStartDateFilterOptions] =
    useState([]);
  const [startDateFiltersLoading, setStartDateFiltersLoading] = useState(true);

  // show instruction variables
  const [openInstructionManual, setOpenInstructionManual] = useState(false);

  // Susbcription Variables
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);

  const [showSubscribeMessage, setShowSubscribeMessage] = useState(false);
  const [afterSubscribeMessage, setAfterSubscribeMessage] = useState('');

  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState('Daily');
  const [subscriptionWeekDay, setSubscriptionWeekDay] = useState('Monday');
  const [subscriptionMonthDay, setSubscriptionMonthDay] = useState('');

  const [additionalRecepients, setAdditionRecepients] = useState('');

  const dataTablePropsPagination = useDataTable({
    initialData: tableData,
    initialColumns: useMemo(() => {
      return ndbPlnTableColumns;
    }),
    showPagination: true,
    showTopPagination: true,
    showBottomPagination: true,
    showDownloadButton: true,
    paginationBottomCompact: true,
    pageSizeDefault: 50,
    downloadButtonConfig: {
      removeFiltered: true,
    },
    pageSizeOptions: [50, 100, 150],
  });

  const fetchTableData = () => {
    axios
      .get(`${hostServer.dev}/fetchNDBPLNDDPStatusTINMPINLevel`)
      .then((response) => {
        const responseData = response.data.results;
        if (responseData && responseData?.length && responseData.length > 0) {
          setTableData(response.data.results);
          setTableDataAll(response.data.results);
          dataTablePropsPagination.setData(response.data.results, true);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const storeSubscriberDetails = () => {
    const payload = {
      userEmail: `${activeAccountEmail};${additionalRecepients}`,
      TIN: tinFilters || [],
      MPIN: mpinFilters,
      PROVIDER_NAME: providerNameFilters,
      ORG_TYPE: orgTypeFilters,
      DDP_STATUS: ddpStatusFilters,
      START_DATE: startDateFilters,
      subscriptionFrequency,
      subscriptionStartDate,
      subscriptionEndDate: subscriptionEndDate || '',
      subscriptionWeekDay: subscriptionWeekDay || '',
      subscriptionMonthDay: subscriptionMonthDay || '',
    };

    // alert(Object.values(payload));

    axios
      .post(
        `${hostServer.dev}/subscription/subscribeNDB_PLN_DDP_STATUS_TIN_MPIN_LEVEL_REPORT`,
        { data: payload }
      )
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((_response) => {
        setAfterSubscribeMessage('Data Saved Successfully.');
        setShowSubscribeMessage(true);
      })
      .catch((error) => {
        setShowSubscribeMessage(true);
        setAfterSubscribeMessage(
          'Unable to save the details. Please try after sometime.'
        );
        console.error(error);
      });
  };

  const fetchTableDataByFilters = () => {
    const payload = {
      TIN: tinFilters,
      MPIN: mpinFilters,
      PROVIDER_NAME: providerNameFilters,
      ORG_TYPE: orgTypeFilters,
      DDP_STATUS: ddpStatusFilters,
      START_DATE: startDateFilters,
    };

    axios
      .post(`${hostServer.dev}/fetchTableDataByFilter`, {
        data: payload,
      })
      .then((response) => {
        const responseData = response.data.results;

        if (responseData && responseData?.length && responseData.length > 0) {
          setTableData(responseData);
          dataTablePropsPagination.setData(responseData, true);
        } else {
          // eslint-disable-next-line no-alert
          alert('We are facing some issues please try after sometime!');
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    if (
      activeUserGroups &&
      (activeUserGroups?.isUserPhiGroupMember ||
        activeUserGroups?.isUserPhiClaimGroupMember)
    ) {
      let FINAL_COLUMNS = Array.from(table?.COLUMNS_CONFIG?.COLUMNS);

      // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
      FINAL_COLUMNS = FINAL_COLUMNS.concat(
        Array.from(table?.COLUMNS_CONFIG?.PHI_COLUMNS)
      );

      // implement code to add column if phi user
    }
  }, [activeUserGroups]);

  useEffect(() => {
    const payload = {
      TIN: tinFilters.length > 0 ? tinFilters : [],
      MPIN: mpinFilters.length > 0 ? mpinFilters : [],
      PROVIDER_NAME: providerNameFilters.length > 0 ? providerNameFilters : [],
      ORG_TYPE: orgTypeFilters.length > 0 ? orgTypeFilters : [],
      DDP_STATUS: ddpStatusFilters.length > 0 ? ddpStatusFilters : [],
      START_DATE: startDateFilters.length > 0 ? startDateFilters : [],
    };
    fetchTableData();
    fetchTinFilterOptions(
      payload,
      setTinFiltersLoading,
      setTinFilterOptions,
      distinctTinFilterOptions,
      setDistinctTinFilterOptions
    );
    fetchMpinFilterOptions(
      payload,
      setMpinFiltersLoading,
      setMpinFilterOptions,
      setDistinctMpinFilterOptions,
      distinctMpinFilterOptions
    );
    fetchProviderNameFilterOptions(
      payload,
      setProviderNameFiltersLoading,
      setProviderNameFilterOptions,
      setDistinctProviderNameFilterOptions,
      distinctProviderNameFilterOptions
    );
    fetchOrgTypeFilterOptions(
      payload,
      setOrgTypeFiltersLoading,
      setOrgTypeFilterOptions,
      setDistinctOrgTypeFilterOptions,
      distinctOrgTypeFilterOptions
    );
    fetchDdpStatusFilterOptions(
      payload,
      setDdpStatusFiltersLoading,
      setDdpStatusFilterOptions,
      setDistinctDdpStatusFilterOptions,
      distinctDdpStatusFilterOptions
    );
    fetchStartDateFilterOptions(
      payload,
      setStartDateFiltersLoading,
      setStartDateFilterOptions,
      setDistinctStartDateFilterOptions,
      distinctStartDateFilterOptions
    );
  }, []);

  return (
    <React.Fragment>
      <div className="report-body-wrapper">
        <div className="filter-container-wrapper">
          <div className="filter-container">
            <section className="multi-select-container">
              <span className="multi-select-title">TIN:</span>
              <MultiSelect
                labelledBy="TIN"
                options={tinFilterOptions}
                value={tinFilters}
                onChange={(selectedValues) => {
                  setTinFilters(selectedValues);

                  let removedItem = [];

                  if (!ndbFilters.hasFilter('tin')) {
                    ndbFilters.push('tin');
                  } else {
                    removedItem = ndbFilters.pop('tin');
                  }

                  removedItem.reverse();

                  const payload = {
                    TIN:
                      selectedValues.length > 0 && !removedItem.includes('tin')
                        ? selectedValues
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_NAME:
                      providerNameFilters.length > 0 &&
                      !removedItem.includes('providerName')
                        ? providerNameFilters
                        : [],
                    ORG_TYPE:
                      orgTypeFilters.length > 0 &&
                      !removedItem.includes('orgType')
                        ? orgTypeFilters
                        : [],
                    DDP_STATUS:
                      ddpStatusFilters.length > 0 &&
                      !removedItem.includes('ddpStatus')
                        ? ddpStatusFilters
                        : [],
                    START_DATE:
                      startDateFilters.length > 0 &&
                      !removedItem.includes('startDate')
                        ? startDateFilters
                        : [],
                  };

                  let temporaryPayload = payload;

                  if (!ndbFilters.hasFilter('mpin')) {
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(
                      temporaryPayload,
                      setMpinFiltersLoading,
                      setMpinFilterOptions,
                      setDistinctMpinFilterOptions,
                      distinctMpinFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('providerName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('providerName'))
                      temporaryPayload.PROVIDER_NAME = [];
                    fetchProviderNameFilterOptions(
                      temporaryPayload,
                      setProviderNameFiltersLoading,
                      setProviderNameFilterOptions,
                      setDistinctProviderNameFilterOptions,
                      distinctProviderNameFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('orgType')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgType'))
                      temporaryPayload.ORG_TYPE = [];
                    fetchOrgTypeFilterOptions(
                      temporaryPayload,
                      setOrgTypeFiltersLoading,
                      setOrgTypeFilterOptions,
                      setDistinctOrgTypeFilterOptions,
                      distinctOrgTypeFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('ddpStatus')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('ddpStatus'))
                      temporaryPayload.DDP_STATUS = [];
                    fetchDdpStatusFilterOptions(
                      temporaryPayload,
                      setDdpStatusFiltersLoading,
                      setDdpStatusFilterOptions,
                      setDistinctDdpStatusFilterOptions,
                      distinctDdpStatusFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('startDate')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('startDate'))
                      temporaryPayload.START_DATE = [];
                    fetchStartDateFilterOptions(
                      temporaryPayload,
                      setStartDateFiltersLoading,
                      setStartDateFilterOptions,
                      setDistinctStartDateFilterOptions,
                      distinctStartDateFilterOptions
                    );
                  }

                  if (removedItem.length > 0) {
                    ndbFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={tinFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">MPIN:</span>
              <MultiSelect
                labelledBy="MPIN"
                options={mpinFilterOptions}
                value={mpinFilters}
                onChange={(selectedValues) => {
                  setMpinFilters(selectedValues);

                  let removedItem = [];

                  if (!ndbFilters.hasFilter('mpin')) {
                    ndbFilters.push('mpin');
                  } else {
                    removedItem = ndbFilters.pop('mpin');
                  }
                  removedItem.reverse();

                  const payload = {
                    TIN:
                      selectedValues.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? selectedValues
                        : [],
                    PROVIDER_NAME:
                      providerNameFilters.length > 0 &&
                      !removedItem.includes('providerName')
                        ? providerNameFilters
                        : [],
                    ORG_TYPE:
                      orgTypeFilters.length > 0 &&
                      !removedItem.includes('orgType')
                        ? orgTypeFilters
                        : [],
                    DDP_STATUS:
                      ddpStatusFilters.length > 0 &&
                      !removedItem.includes('ddpStatus')
                        ? ddpStatusFilters
                        : [],
                    START_DATE:
                      startDateFilters.length > 0 &&
                      !removedItem.includes('startDate')
                        ? startDateFilters
                        : [],
                  };

                  let temporaryPayload = payload;

                  if (!ndbFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(
                      temporaryPayload,
                      setTinFiltersLoading,
                      setTinFilterOptions,
                      setDistinctTinFilterOptions,
                      distinctTinFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('providerName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('providerName'))
                      temporaryPayload.PROVIDER_NAME = [];
                    fetchProviderNameFilterOptions(
                      temporaryPayload,
                      setProviderNameFiltersLoading,
                      setProviderNameFilterOptions,
                      setDistinctProviderNameFilterOptions,
                      distinctProviderNameFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('orgType')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgType'))
                      temporaryPayload.ORG_TYPE = [];
                    fetchOrgTypeFilterOptions(
                      temporaryPayload,
                      setOrgTypeFiltersLoading,
                      setOrgTypeFilterOptions,
                      setDistinctOrgTypeFilterOptions,
                      distinctOrgTypeFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('ddpStatus')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('ddpStatus'))
                      temporaryPayload.DDP_STATUS = [];
                    fetchDdpStatusFilterOptions(
                      temporaryPayload,
                      setDdpStatusFiltersLoading,
                      setDdpStatusFilterOptions,
                      setDistinctDdpStatusFilterOptions,
                      distinctDdpStatusFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('startDate')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('startDate'))
                      temporaryPayload.START_DATE = [];
                    fetchStartDateFilterOptions(
                      temporaryPayload,
                      setStartDateFiltersLoading,
                      setStartDateFilterOptions,
                      setDistinctStartDateFilterOptions,
                      distinctStartDateFilterOptions
                    );
                  }

                  if (removedItem.length > 0) {
                    ndbFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={mpinFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">PROVIDER_NAME:</span>
              <MultiSelect
                labelledBy="PROVIDER_NAME"
                options={providerNameFilterOptions}
                value={providerNameFilters}
                onChange={(selectedValues) => {
                  setProviderNameFilters(selectedValues);

                  let removedItem = [];

                  if (!ndbFilters.hasFilter('providerName')) {
                    ndbFilters.push('providerName');
                  } else {
                    removedItem = ndbFilters.pop('providerName');
                  }
                  removedItem.reverse();

                  const payload = {
                    TIN:
                      selectedValues.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_NAME:
                      providerNameFilters.length > 0 &&
                      !removedItem.includes('providerName')
                        ? selectedValues
                        : [],
                    ORG_TYPE:
                      orgTypeFilters.length > 0 &&
                      !removedItem.includes('orgType')
                        ? orgTypeFilters
                        : [],
                    DDP_STATUS:
                      ddpStatusFilters.length > 0 &&
                      !removedItem.includes('ddpStatus')
                        ? ddpStatusFilters
                        : [],
                    START_DATE:
                      startDateFilters.length > 0 &&
                      !removedItem.includes('startDate')
                        ? startDateFilters
                        : [],
                  };

                  let temporaryPayload = payload;

                  if (!ndbFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(
                      temporaryPayload,
                      setTinFiltersLoading,
                      setTinFilterOptions,
                      setDistinctTinFilterOptions,
                      distinctTinFilterOptions
                    );
                  }

                  if (!ndbFilters.hasFilter('mpin')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(
                      temporaryPayload,
                      setMpinFiltersLoading,
                      setMpinFilterOptions,
                      setDistinctMpinFilterOptions,
                      distinctMpinFilterOptions
                    );
                  }

                  if (!ndbFilters.hasFilter('orgType')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgType'))
                      temporaryPayload.ORG_TYPE = [];
                    fetchOrgTypeFilterOptions(
                      temporaryPayload,
                      setOrgTypeFiltersLoading,
                      setOrgTypeFilterOptions,
                      setDistinctOrgTypeFilterOptions,
                      distinctOrgTypeFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('ddpStatus')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('ddpStatus'))
                      temporaryPayload.DDP_STATUS = [];
                    fetchDdpStatusFilterOptions(
                      temporaryPayload,
                      setDdpStatusFiltersLoading,
                      setDdpStatusFilterOptions,
                      setDistinctDdpStatusFilterOptions,
                      distinctDdpStatusFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('startDate')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('startDate'))
                      temporaryPayload.START_DATE = [];
                    fetchStartDateFilterOptions(
                      temporaryPayload,
                      setStartDateFiltersLoading,
                      setStartDateFilterOptions,
                      setDistinctStartDateFilterOptions,
                      distinctStartDateFilterOptions
                    );
                  }
                  if (removedItem.length > 0) {
                    ndbFilters.concatenate(removedItem);
                  }
                }}
                // className="multi-select"
                hasSelectAll
                isLoading={providerNameFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">ORG_TYPE:</span>
              <MultiSelect
                labelledBy="ORG_TYPE"
                options={orgTypeFilterOptions}
                // placeholder="Select ORG_TYPE(s)"
                value={orgTypeFilters}
                onChange={(selectedValues) => {
                  setOrgTypeFilters(selectedValues);

                  let removedItem = [];

                  if (!ndbFilters.hasFilter('orgType')) {
                    ndbFilters.push('orgType');
                  } else {
                    removedItem = ndbFilters.pop('orgType');
                  }
                  removedItem.reverse();

                  const payload = {
                    TIN:
                      selectedValues.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_NAME:
                      providerNameFilters.length > 0 &&
                      !removedItem.includes('providerName')
                        ? providerNameFilters
                        : [],
                    ORG_TYPE:
                      orgTypeFilters.length > 0 &&
                      !removedItem.includes('orgType')
                        ? selectedValues
                        : [],
                    DDP_STATUS:
                      ddpStatusFilters.length > 0 &&
                      !removedItem.includes('ddpStatus')
                        ? ddpStatusFilters
                        : [],
                    START_DATE:
                      startDateFilters.length > 0 &&
                      !removedItem.includes('startDate')
                        ? startDateFilters
                        : [],
                  };

                  let temporaryPayload = payload;

                  if (!ndbFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(
                      temporaryPayload,
                      setTinFiltersLoading,
                      setTinFilterOptions,
                      setDistinctTinFilterOptions,
                      distinctTinFilterOptions
                    );
                  }

                  if (!ndbFilters.hasFilter('mpin')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(
                      temporaryPayload,
                      setMpinFiltersLoading,
                      setMpinFilterOptions,
                      setDistinctMpinFilterOptions,
                      distinctMpinFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('providerName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('providerName'))
                      temporaryPayload.PROVIDER_NAME = [];
                    fetchProviderNameFilterOptions(
                      temporaryPayload,
                      setProviderNameFiltersLoading,
                      setProviderNameFilterOptions,
                      setDistinctProviderNameFilterOptions,
                      distinctProviderNameFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('ddpStatus')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('ddpStatus'))
                      temporaryPayload.DDP_STATUS = [];
                    fetchDdpStatusFilterOptions(
                      temporaryPayload,
                      setDdpStatusFiltersLoading,
                      setDdpStatusFilterOptions,
                      setDistinctDdpStatusFilterOptions,
                      distinctDdpStatusFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('startDate')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('startDate'))
                      temporaryPayload.START_DATE = [];
                    fetchStartDateFilterOptions(
                      temporaryPayload,
                      setStartDateFiltersLoading,
                      setStartDateFilterOptions,
                      setDistinctStartDateFilterOptions,
                      distinctStartDateFilterOptions
                    );
                  }
                  if (removedItem.length > 0) {
                    ndbFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={orgTypeFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">DDP_STATUS:</span>
              <MultiSelect
                labelledBy="DDP_STATUS"
                options={ddpStatusFilterOptions}
                value={ddpStatusFilters}
                onChange={(selectedValues) => {
                  setDdpStatusFilters(selectedValues);

                  let removedItem = [];

                  if (!ndbFilters.hasFilter('ddpStatus')) {
                    ndbFilters.push('ddpStatus');
                  } else {
                    removedItem = ndbFilters.pop('ddpStatus');
                  }
                  removedItem.reverse();

                  const payload = {
                    TIN:
                      selectedValues.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_NAME:
                      providerNameFilters.length > 0 &&
                      !removedItem.includes('providerName')
                        ? providerNameFilters
                        : [],
                    ORG_TYPE:
                      orgTypeFilters.length > 0 &&
                      !removedItem.includes('orgType')
                        ? orgTypeFilters
                        : [],
                    DDP_STATUS:
                      ddpStatusFilters.length > 0 &&
                      !removedItem.includes('ddpStatus')
                        ? selectedValues
                        : [],
                    START_DATE:
                      startDateFilters.length > 0 &&
                      !removedItem.includes('startDate')
                        ? startDateFilters
                        : [],
                  };

                  let temporaryPayload = payload;

                  if (!ndbFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(
                      temporaryPayload,
                      setTinFiltersLoading,
                      setTinFilterOptions,
                      setDistinctTinFilterOptions,
                      distinctTinFilterOptions
                    );
                  }

                  if (!ndbFilters.hasFilter('mpin')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(
                      temporaryPayload,
                      setMpinFiltersLoading,
                      setMpinFilterOptions,
                      setDistinctMpinFilterOptions,
                      distinctMpinFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('providerName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('providerName'))
                      temporaryPayload.PROVIDER_NAME = [];
                    fetchProviderNameFilterOptions(
                      temporaryPayload,
                      setProviderNameFiltersLoading,
                      setProviderNameFilterOptions,
                      setDistinctProviderNameFilterOptions,
                      distinctProviderNameFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('orgType')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgType'))
                      temporaryPayload.ORG_TYPE = [];
                    fetchOrgTypeFilterOptions(
                      temporaryPayload,
                      setOrgTypeFiltersLoading,
                      setOrgTypeFilterOptions,
                      setDistinctOrgTypeFilterOptions,
                      distinctOrgTypeFilterOptions
                    );
                  }

                  if (!ndbFilters.hasFilter('startDate')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('startDate'))
                      temporaryPayload.START_DATE = [];
                    fetchStartDateFilterOptions(
                      temporaryPayload,
                      setStartDateFiltersLoading,
                      setStartDateFilterOptions,
                      setDistinctStartDateFilterOptions,
                      distinctStartDateFilterOptions
                    );
                  }

                  if (removedItem.length > 0) {
                    ndbFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={ddpStatusFiltersLoading}
              />
            </section>
            <section className="multi-select-container">
              <span className="multi-select-title">START_DATE:</span>
              <MultiSelect
                labelledBy="START_DATE"
                options={startDateFilterOptions}
                value={startDateFilters}
                onChange={(selectedValues) => {
                  setStartDateFilters(selectedValues);

                  let removedItem = [];

                  if (!ndbFilters.hasFilter('startDate')) {
                    ndbFilters.push('startDate');
                  } else {
                    removedItem = ndbFilters.pop('startDate');
                  }
                  removedItem.reverse();

                  const payload = {
                    TIN:
                      selectedValues.length > 0 && !removedItem.includes('tin')
                        ? tinFilters
                        : [],
                    MPIN:
                      mpinFilters.length > 0 && !removedItem.includes('mpin')
                        ? mpinFilters
                        : [],
                    PROVIDER_NAME:
                      providerNameFilters.length > 0 &&
                      !removedItem.includes('providerName')
                        ? providerNameFilters
                        : [],
                    ORG_TYPE:
                      orgTypeFilters.length > 0 &&
                      !removedItem.includes('orgType')
                        ? orgTypeFilters
                        : [],
                    DDP_STATUS:
                      ddpStatusFilters.length > 0 &&
                      !removedItem.includes('ddpStatus')
                        ? ddpStatusFilters
                        : [],
                    START_DATE:
                      startDateFilters.length > 0 &&
                      !removedItem.includes('startDate')
                        ? selectedValues
                        : [],
                  };

                  let temporaryPayload = payload;

                  if (!ndbFilters.hasFilter('tin')) {
                    if (removedItem.includes('tin')) temporaryPayload.TIN = [];
                    fetchTinFilterOptions(
                      temporaryPayload,
                      setTinFiltersLoading,
                      setTinFilterOptions,
                      setDistinctTinFilterOptions,
                      distinctTinFilterOptions
                    );
                  }

                  if (!ndbFilters.hasFilter('mpin')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('mpin'))
                      temporaryPayload.MPIN = [];
                    fetchMpinFilterOptions(
                      temporaryPayload,
                      setMpinFiltersLoading,
                      setMpinFilterOptions,
                      setDistinctMpinFilterOptions,
                      distinctMpinFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('providerName')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('providerName'))
                      temporaryPayload.PROVIDER_NAME = [];
                    fetchProviderNameFilterOptions(
                      temporaryPayload,
                      setProviderNameFiltersLoading,
                      setProviderNameFilterOptions,
                      setDistinctProviderNameFilterOptions,
                      distinctProviderNameFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('orgType')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('orgType'))
                      temporaryPayload.ORG_TYPE = [];
                    fetchOrgTypeFilterOptions(
                      temporaryPayload,
                      setOrgTypeFiltersLoading,
                      setOrgTypeFilterOptions,
                      setDistinctOrgTypeFilterOptions,
                      distinctOrgTypeFilterOptions
                    );
                  }
                  if (!ndbFilters.hasFilter('ddpStatus')) {
                    temporaryPayload = payload;
                    if (removedItem.includes('ddpStatus')) {
                      temporaryPayload.DDP_STATUS = [];
                    }
                    fetchDdpStatusFilterOptions(
                      temporaryPayload,
                      setDdpStatusFiltersLoading,
                      setDdpStatusFilterOptions,
                      setDistinctDdpStatusFilterOptions,
                      distinctDdpStatusFilterOptions
                    );
                  }

                  if (removedItem.length > 0) {
                    ndbFilters.concatenate(removedItem);
                  }
                }}
                className="multi-select"
                hasSelectAll
                isLoading={startDateFiltersLoading}
              />
            </section>
            <div className="filter-button-wrapper">
              <Button
                size="sm"
                className="abyss-button"
                onClick={() => {
                  fetchTableDataByFilters();
                }}
              >
                Apply
              </Button>
              <Button
                size="sm"
                className="abyss-button"
                variant="outline"
                onClick={() => {
                  setTinFilterOptions(distinctTinFilterOptions);
                  setTinFilters([]);

                  setMpinFilterOptions(distinctMpinFilterOptions);
                  setMpinFilters([]);

                  setProviderNameFilterOptions(
                    distinctProviderNameFilterOptions
                  );
                  setProviderNameFilters([]);

                  setOrgTypeFilterOptions(distinctOrgTypeFilterOptions);
                  setOrgTypeFilters([]);

                  setDdpStatusFilterOptions(distinctDdpStatusFilterOptions);
                  setDdpStatusFilters([]);

                  setStartDateFilterOptions(distinctStartDateFilterOptions);
                  setStartDateFilters([]);

                  setTableData(tableDataAll);
                  dataTablePropsPagination.setData(tableDataAll, true);

                  ndbFilters.clear();
                }}
              >
                Clear
              </Button>
            </div>
          </div>

          <div className="report-options">
            {/* <Button size="sm" className="abyss-button">
              Export
            </Button> */}
            <Button
              size="sm"
              className="abyss-button"
              onClick={(e) => {
                e.preventDefault();
                if (openSubscribeModal === true) {
                  setOpenSubscribeModal(false);
                } else {
                  setOpenSubscribeModal(true);
                }
              }}
            >
              Subscribe
            </Button>
          </div>
        </div>

        <React.Fragment>
          <Modal
            title="Usage Instructions :"
            isOpen={openInstructionManual}
            onClose={() => {
              return setOpenInstructionManual(false);
            }}
          >
            <Modal.Section>
              Please follow these steps in order to execute this report,
              subscribe to it, or filter/view the report on screen:
              <ol type="1">
                <li>
                  1. When you open the report, it is displaying the data/results
                  automatically refreshed from our database at approximately
                  8:00AM CT daily.
                </li>
                <li>
                  2. The filters may be used to focus in on a smaller portion of
                  the data. When you make selections in any of the filters, note
                  the circular icon which indicates that the data is being
                  processed - please wait for that to finish before you select
                  other filters. Once you've selected the filters you want,
                  click Apply to see the results. Click Clear if you want to
                  start over on your filters.
                </li>
                <li>
                  3. You may subscribe to the report. Click Subscribe and fill
                  in the details. Your subscription will be based upon the
                  filters you've chosen. Once the subscription begins arriving
                  in your email, you will see a table in the email which
                  contains all of the data in a summary (in other words, the
                  unfiltered/full results). When you open the attachment to that
                  email, the attached file will be based on the filters which
                  you've applied in your subscription.
                </li>
              </ol>
            </Modal.Section>
          </Modal>
        </React.Fragment>
        <Tooltip content="Instruction Manual">
          <Button
            onClick={() => {
              return setOpenInstructionManual(true);
            }}
            aria-label="Instructions"
            rounded
          >
            <IconBrand icon="document" size={35} />
          </Button>
        </Tooltip>

        <div className="report-table-wrapper">
          <div className="report-table">
            <div className="pagination-buttons-container" />
            <DataTable
              tableState={dataTablePropsPagination}
              css={{
                'abyss-data-table-header': {
                  display: 'none',
                },
                'abyss-data-table-subheader-top-container': {
                  display: 'none',
                },
              }}
            />
          </div>

          <div>
            <SubscriptionModal
              openSubscribeModal={openSubscribeModal}
              tinFilters={tinFilters}
              mpinFilters={mpinFilters}
              providerNameFilters={providerNameFilters}
              orgTypeFilters={orgTypeFilters}
              ddpStatusFilters={ddpStatusFilters}
              startDateFilters={startDateFilters}
              setOpenSubscribeModal={setOpenSubscribeModal}
            />
            <Modal
              title="Subscribe"
              isOpen={false}
              onClose={() => {
                setOpenSubscribeModal(false);
                setShowSubscribeMessage(false);
              }}
              size={400}
              css={{
                'abyss-modal-content-title': {
                  fontSize: '26px!important',
                },
              }}
            >
              {!showSubscribeMessage ? (
                <React.Fragment>
                  <Modal.Section
                    css={{
                      'abyss-modal-section-root': {
                        paddingBottom: '4px',
                      },
                    }}
                  >
                    <h6>User email: {activeAccountEmail}</h6>
                    <TextInput
                      css={{
                        'abyss-text-input-root': {
                          marginTop: '5px',
                          width: '60%',
                        },
                      }}
                      label="Additional Recepient(s):"
                      value={additionalRecepients}
                      onChange={(e) => {
                        setAdditionRecepients(e.target.value);
                      }}
                    />

                    <div
                      style={{
                        color: '#424242',
                        margin: '4px',
                        padding: '2px',
                        fontSize: '14px',
                      }}
                    >
                      Note: Only UHG email addresses may be allowed within the
                      subscription. Emails may not be sent to outside email
                      addresses (i.e., gmail.com, yahoo.com, etc.).
                    </div>
                    {(tinFilters.length > 0 ||
                      mpinFilters.length > 0 ||
                      providerNameFilters.length > 0 ||
                      orgTypeFilters.length > 0 ||
                      ddpStatusFilters.length > 0 ||
                      startDateFilters.length > 0) && (
                      <div
                        style={{
                          marginTop: '10px',
                        }}
                      >
                        <h6>Filters Selected</h6>
                        {tinFilters && tinFilters.length > 0 && (
                          <span
                            style={{
                              marginTop: '10px',
                              fontWeight: '1000',
                              color: '#002677',
                              fontSize: '16px',
                            }}
                          >
                            TIN: {tinFilters.join(',')}
                          </span>
                        )}
                        <br />
                        {mpinFilters && mpinFilters.length > 0 && (
                          <span
                            style={{
                              marginTop: '10px',
                              fontWeight: '1000',
                              color: '#002677',
                              fontSize: '16px',
                            }}
                          >
                            MPIN: {mpinFilters.join(',')}
                          </span>
                        )}
                        <br />
                        {providerNameFilters && providerNameFilters.length > 0 && (
                          <span
                            style={{
                              marginTop: '10px',
                              fontWeight: '1000',
                              color: '#002677',
                              fontSize: '16px',
                            }}
                          >
                            PROVIDER_NAME: {providerNameFilters.join(',')}
                          </span>
                        )}
                        <br />
                        {orgTypeFilters && orgTypeFilters.length > 0 && (
                          <span
                            style={{
                              marginTop: '10px',
                              fontWeight: '1000',
                              color: '#002677',
                              fontSize: '16px',
                            }}
                          >
                            ORG_TYPE: {orgTypeFilters.join(',')}
                          </span>
                        )}
                        <br />
                        {ddpStatusFilters && ddpStatusFilters.length > 0 && (
                          <span
                            style={{
                              marginTop: '10px',
                              fontWeight: '1000',
                              color: '#002677',
                              fontSize: '16px',
                            }}
                          >
                            DDP_STATUS: {ddpStatusFilters.join(',')}
                          </span>
                        )}
                        <br />
                        {startDateFilters && startDateFilters.length > 0 && (
                          <span
                            style={{
                              marginTop: '10px',
                              fontWeight: '1000',
                              color: '#002677',
                              fontSize: '16px',
                            }}
                          >
                            START_DATE: {startDateFilters.join(',')}
                          </span>
                        )}
                      </div>
                    )}

                    <div
                      style={{
                        marginTop: '10px',
                      }}
                    >
                      <h5>Schedule Date</h5>
                      <div
                        style={{
                          display: 'flex',
                        }}
                        className=""
                      >
                        <DateInput
                          label="Start Date"
                          value={subscriptionStartDate}
                          onChange={setSubscriptionStartDate}
                          css={{
                            'abyss-date-picker-root': {
                              width: '50% !important',
                              margin: '5px',
                            },
                          }}
                          minimumDate={Date.now()}
                        />
                        <DateInput
                          label="End Date"
                          value={subscriptionEndDate}
                          onChange={setSubscriptionEndDate}
                          css={{
                            'abyss-date-picker-root': {
                              width: '50% !important',
                              margin: '5px',
                            },
                          }}
                          minimumDate={Date.now()}
                          required
                        />
                      </div>
                      <SelectInput
                        label="Repeat"
                        placeholder="Pick one"
                        value={subscriptionFrequency}
                        onChange={setSubscriptionFrequency}
                        onClear={() => {
                          setSubscriptionFrequency('');
                        }}
                        options={[
                          { value: 'Daily', label: 'Daily' },
                          { value: 'Weekly', label: 'Weekly' },
                          { value: 'Monthly', label: 'Monthly' },
                        ]}
                        isClearable
                        isSearchable
                        css={{
                          'abyss-select-input-root': {
                            width: '50%',
                          },
                        }}
                      />
                      {subscriptionFrequency === 'Weekly' && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '10px 2px',
                          }}
                        >
                          <div>
                            <h6>On</h6>
                          </div>

                          <div
                            style={{
                              width: '20px',
                              margin: '10px',
                              padding: '4px',
                              color: 'white',
                              backgroundColor: '#002677',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSubscriptionWeekDay('Sunday');
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            S
                          </div>

                          <div
                            style={{
                              width: '20px',
                              margin: '10px',
                              padding: '4px',
                              color: 'white',
                              backgroundColor: '#002677',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSubscriptionWeekDay('Monday');
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            M
                          </div>

                          <div
                            style={{
                              width: '20px',
                              margin: '10px',
                              padding: '4px',
                              color: 'white',
                              backgroundColor: '#002677',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSubscriptionWeekDay('Tuesday');
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            T
                          </div>

                          <div
                            style={{
                              width: '20px',
                              margin: '10px',
                              padding: '4px',
                              color: 'white',
                              backgroundColor: '#002677',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSubscriptionWeekDay('Wednesday');
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            W
                          </div>

                          <div
                            style={{
                              width: '20px',
                              margin: '10px',
                              padding: '4px',
                              color: 'white',
                              backgroundColor: '#002677',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSubscriptionWeekDay('Thursday');
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            T
                          </div>

                          <div
                            style={{
                              width: '20px',
                              margin: '10px',
                              padding: '4px',
                              color: 'white',
                              backgroundColor: '#002677',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSubscriptionWeekDay('Friday');
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            F
                          </div>

                          <div
                            style={{
                              width: '20px',
                              margin: '10px',
                              padding: '4px',
                              color: 'white',
                              backgroundColor: '#002677',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setSubscriptionWeekDay('Saturday');
                            }}
                            onKeyDown={() => {}}
                            role="button"
                            tabIndex={0}
                          >
                            S
                          </div>
                        </div>
                      )}

                      {subscriptionFrequency === 'Monthly' && (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            margin: '10px 2px',
                          }}
                        >
                          <TextInput
                            css={{
                              'abyss-text-input-root': {
                                marginTop: '5px',
                                width: '60%',
                              },
                            }}
                            label="Every month on day(s):"
                            placeholder="Ex: 1,27-30"
                            value={subscriptionMonthDay}
                            onChange={(e) => {
                              setSubscriptionMonthDay(e.target.value);
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        color: '#424242',
                        margin: '8px 4px 2px 4px',
                        padding: '2px',
                        fontSize: '16px',
                      }}
                    >
                      Note: You will receive this report approximately 8AM CT
                      based on the repeating schedule you've selected.
                    </div>
                  </Modal.Section>
                  <Modal.Section>
                    <div className="modal-primary-buttons">
                      <Button
                        size="sm"
                        className="abyss-button"
                        onClick={() => {
                          storeSubscriberDetails();
                        }}
                      >
                        Subscribe
                      </Button>
                      <Button
                        size="sm"
                        variant="outline"
                        className="abyss-button"
                        onClick={() => {
                          setOpenSubscribeModal(false);
                        }}
                      >
                        Back
                      </Button>
                    </div>
                  </Modal.Section>
                </React.Fragment>
              ) : (
                <Modal.Section>
                  <div
                    style={{
                      width: '60%',
                      display: 'flex',
                      justifyContent: 'space-around',
                      alignItems: 'center',
                    }}
                  >
                    {afterSubscribeMessage.includes('Success') ? (
                      <IconMaterial icon="done" variant="filled" size={64} />
                    ) : (
                      <IconMaterial
                        icon="clear"
                        variant="filled"
                        color="$error1"
                        size={64}
                      />
                    )}
                    <h5>{afterSubscribeMessage}</h5>
                  </div>
                </Modal.Section>
              )}
            </Modal>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
