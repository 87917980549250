import React, { useState, useRef, useEffect } from 'react';
import { Modal } from '@abyss/web/ui/Modal';
import { TextInput } from '@abyss/web/ui/TextInput';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { DateInput } from '@abyss/web/ui/DateInput';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Link } from '@abyss/web/ui/Link';
import { useForm } from '@abyss/web/hooks/useForm';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Card } from '@abyss/web/ui/Card';
import { Tabs } from '@abyss/web/ui/Tabs';
import axios from 'axios';
import { Button } from '@abyss/web/ui/Button';

import { useSelector } from 'react-redux';
import { hostServer } from '../../utils/constants';
import { NewSubscriptionModal } from './NewSubscriptionModal/NewSubscriptionModal';

const NoSubscriptionUI = ({
  setIsNewSubscription,
  setIsUserHasNoSubscription,
}) => {
  return (
    <React.Fragment>
      <div className="no-subscription-ui">
        <div
          style={{
            textAlign: 'center',
            fontWeight: '600',
            fontSize: '20px',
          }}
        >
          You have no subscription yet.{' '}
        </div>
        <Button
          type="button"
          onClick={() => {
            setIsNewSubscription(true);
            setIsUserHasNoSubscription(false);
          }}
        >
          Create New Subscription
        </Button>
      </div>
    </React.Fragment>
  );
};

const getWeekDayName = (subscriptionWeekDay) => {
  const weekDays = Array.from(subscriptionWeekDay.split(',')).sort();

  let weekDayName = '';

  weekDays.forEach((weekDay) => {
    if (weekDayName.length > 0) {
      weekDayName += ', ';
    }
    switch (weekDay) {
      case '0':
        weekDayName += 'Sunday';
        break;
      case '1':
        weekDayName += 'Monday';
        break;
      case '2':
        weekDayName += 'Tuesday';
        break;
      case '3':
        weekDayName += 'Wednesday';
        break;
      case '4':
        weekDayName += 'Thursday';
        break;
      case '5':
        weekDayName += 'Friday';
        break;
      case '6':
        weekDayName += 'Saturday';
        break;
      default:
        break;
    }
  });

  return weekDayName;
};

function processFilterObject(filters) {
  const FILTER_SELECTED = [];

  filters.forEach((filter) => {
    FILTER_SELECTED.push(filter?.value);
  });

  return FILTER_SELECTED;
}

export const SubscriptionModal = ({
  tinFilters,
  mpinFilters,
  fileName,
  // providerNameFilters,
  // orgTypeFilters,
  // ddpStatusFilters,
  // startDateFilters,
  openSubscribeModal,
  setOpenSubscribeModal,
}) => {
  const activeAccountEmail = useSelector((state) => {
    return state.activeAccountEmail;
  });

  const [isUserHasNoSubscription, setIsUserHasNoSubscription] = useState(true);
  const [isNewSubscription, setIsNewSubscription] = useState(false);

  const [userSubscriptions, setUserSubscriptions] = useState([]);

  const [showSubscribeMessage, setShowSubscribeMessage] = useState(false);
  const [afterSubscribeMessage, setAfterSubscribeMessage] = useState('');

  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null);
  const [subscriptionEndDate, setSubscriptionEndDate] = useState(null);
  const [subscriptionFrequency, setSubscriptionFrequency] = useState('Weekly');
  const [subscriptionWeekDay, setSubscriptionWeekDay] = useState('');
  const [subscriptionMonthDay, setSubscriptionMonthDay] = useState('');

  const [additionalRecepients, setAdditionRecepients] = useState('');

  const [isEditSubscription, setEditSubscription] = useState(false);
  const [currentSubscription, setCurrentSubscription] = useState({});

  const [isInputsValid, setIsValidInput] = useState(false);

  const [repeatMessage, setRepeatMessage] = useState('daily');

  const [weekDays, setWeekDays] = useState([]);

  function checkEmailDomain(email) {
    const allowedDomains = ['uhc', 'uhg', 'optum'];
    const domain = email.split('@')[1].split('.')[0];

    return allowedDomains.includes(domain);
  }

  function checkValidEmailAddress(email) {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return email.match(emailRegex);
  }

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const currentMonth = currentDate.getMonth();
  //   const currentYear = currentDate.getFullYear();
  //   const targetDate = new Date(currentYear, currentMonth, 15);
  //   setSubscriptionStartDate(targetDate);
  // }, []);

  function validateEnteredAdditionaRecipients(inputValue) {
    if (inputValue.length > 0) {
      const additionalRecepientsArray = inputValue.split(',');

      const emailDiv = document.getElementById('email-error');

      for (let i = 0; i < additionalRecepientsArray.length; i++) {
        // validateEmailInAzureAD(additionalRecepientsArray[i]);
        if (!checkValidEmailAddress(additionalRecepientsArray[i])) {
          emailDiv.style.display = 'block';
          emailDiv.innerText = 'Please enter a valid email address!!';
          setIsValidInput(false);
          return;
        }
        if (!checkEmailDomain(additionalRecepientsArray[i])) {
          emailDiv.style.display = 'block';
          emailDiv.innerText = 'External email addresses are not allowed!!';
          setIsValidInput(false);
          return;
        }
      }
    }
    if (subscriptionStartDate !== null) {
      setIsValidInput(true);
    }
  }

  const storeSubscriberDetails = () => {
    const payload = {
      userEmail: activeAccountEmail,
      additionalRecipients: additionalRecepients,
      TIN: tinFilters || [],
      MPIN: mpinFilters,
      // PROVIDER_NAME: providerNameFilters,
      // ORG_TYPE: orgTypeFilters,
      // DDP_STATUS: ddpStatusFilters,
      // START_DATE: startDateFilters,
      subscriptionFrequency,
      subscriptionStartDate,
      subscriptionEndDate: subscriptionEndDate || '',
      subscriptionWeekDay: subscriptionWeekDay || '',
      subscriptionMonthDay: subscriptionMonthDay || '',
    };

    axios
      .post(
        `${hostServer.dev}/subscription/subscribeStrategicDisruption_REPORT`,
        { data: payload }
      )
      .then((response) => {
        setAfterSubscribeMessage('Data Saved Successfully.');
        setShowSubscribeMessage(true);
        fetchSubscriptions();

        setSubscriptionFrequency('Weekly');
        setSubscriptionWeekDay('');
        setSubscriptionMonthDay('');
        setSubscriptionStartDate(null);
        setSubscriptionEndDate(null);
        setAdditionRecepients('');

        setTimeout(() => {
          setShowSubscribeMessage(false);
          setIsNewSubscription(false);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        setShowSubscribeMessage(true);
        setAfterSubscribeMessage(
          'Unable to save the details. Please try after sometime.'
        );
        setTimeout(() => {
          setShowSubscribeMessage(false);
          setIsNewSubscription(false);
        }, 2000);
      });
  };

  const fetchSubscriptions = () => {
    axios
      .post(`${hostServer.dev}/subscription/fetchSubscriptions`, {
        data: {
          userEmail: activeAccountEmail,
          reportid: 'SDRPT',
        },
      })
      .then((response) => {
        if (response?.data?.results.length === 0) {
          setIsUserHasNoSubscription(true);
        } else {
          setUserSubscriptions(response.data.results);
          setIsUserHasNoSubscription(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteSubscription = (subscriptionId) => {
    axios
      .post(`${hostServer.dev}/subscription/deleteSubscriptions`, {
        data: {
          userEmail: activeAccountEmail,
          subscriptionId,
          reportid: 'SDRPT',
        },
      })
      .then((response) => {
        alert('Subscription deleted successfully!!');
        fetchSubscriptions();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  useEffect(() => {
    const temp = weekDays;
    setRepeatMessage(`on each ${weekDays} of the week `);
  }, [subscriptionWeekDay]);

  return (
    <React.Fragment>
      <div>
        <Modal
          title="Manage Subscriptions"
          isOpen={openSubscribeModal}
          hideLabel
          onClose={() => {
            setOpenSubscribeModal(false);
            setShowSubscribeMessage(false);
            setIsNewSubscription(false);
          }}
          css={{
            'abyss-modal-root': {
              width: '1000px',
            },
            'abyss-modal-content-title': {
              fontSize: '26px!important',
            },
          }}
        >
          {isEditSubscription ? (
            <NewSubscriptionModal
              subscription={currentSubscription}
              setEditSubscription={setEditSubscription}
              fetchSubscriptions={fetchSubscriptions}
            />
          ) : (
            <React.Fragment>
              {isUserHasNoSubscription ? (
                <NoSubscriptionUI
                  setIsNewSubscription={setIsNewSubscription}
                  setIsUserHasNoSubscription={setIsUserHasNoSubscription}
                />
              ) : (
                <React.Fragment>
                  {!isNewSubscription ? (
                    <React.Fragment>
                      <div>
                        <Card
                          header={
                            <React.Fragment>
                              <div
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <h6>Your Subscriptions</h6>

                                <Button
                                  type="button"
                                  onClick={() => {
                                    setIsNewSubscription(true);
                                  }}
                                >
                                  Add New
                                </Button>
                              </div>
                            </React.Fragment>
                          }
                        >
                          <Card.Section>
                            {userSubscriptions?.map((subscription) => {
                              return (
                                <Card
                                  css={{
                                    'abyss-card-title': {
                                      minHeight: 60,
                                      // color: '$gray9',
                                      // fontSize: '$sm',
                                    },
                                  }}
                                  // collapse
                                  // collapseOnClick={(e, state) => {}}
                                  defaultIsOpen={false}
                                >
                                  <Card.Section>
                                    <div
                                      style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: '80%',
                                        }}
                                      >
                                        <strong>
                                          Recipient(s):{` `}
                                          {`${subscription?.SUBSCRIPTION_OWNER}
                                          ${
                                            subscription?.RECIPIENT_EMAILADDRESS
                                              .length > 0
                                              ? `, ${subscription?.RECIPIENT_EMAILADDRESS.replace(
                                                  ';',
                                                  ','
                                                )}`
                                              : ''
                                          }`}
                                        </strong>
                                      </div>
                                      <div
                                        style={{
                                          width: '20%',
                                          float: 'right',
                                        }}
                                      >
                                        <Link
                                          css={{
                                            'abyss-link-root': {
                                              marginRight: '10px',
                                            },
                                          }}
                                          href="#"
                                          onClick={() => {
                                            setCurrentSubscription(
                                              subscription
                                            );
                                            setEditSubscription(true);
                                          }}
                                        >
                                          Edit
                                        </Link>
                                        <Link
                                          css={{
                                            'abyss-link-root': {
                                              marginRight: '10px',
                                            },
                                          }}
                                          href="#"
                                          onClick={() => {
                                            if (
                                              confirm(
                                                'You are going to delete a subscription. Do you want to proceed?'
                                              )
                                            ) {
                                              deleteSubscription(
                                                subscription?.SUBSCRIPTION_ID
                                              );
                                            }
                                          }}
                                        >
                                          Delete
                                        </Link>
                                      </div>
                                    </div>
                                    <p
                                      style={{
                                        display: 'flex',
                                        margin: '5px',
                                      }}
                                    >
                                      <span className="card-content-element">
                                        Start Date:{' '}
                                        {subscription?.SUBSCRIPTION_START_DATE}
                                      </span>
                                      <span className="card-content-element">
                                        End Date:{' '}
                                        {subscription?.SUBSCRIPTION_END_DATE}
                                      </span>
                                    </p>
                                    <div
                                      style={{
                                        display: 'flex',
                                        margin: '5px',
                                      }}
                                    >
                                      <span className="card-content-element">
                                        Schedule:{' '}
                                        {subscription?.SUBSCRIPTION_FREQUENCY}
                                      </span>
                                      {subscription?.SUBSCRIPTION_FREQUENCY ===
                                        'Weekly' && (
                                        <span className="card-content-element">
                                          Week Day(s):{` `}
                                          <strong>
                                            {getWeekDayName(
                                              subscription?.SUBSCRIPTION_WEEK_DAY
                                            )}
                                          </strong>
                                        </span>
                                      )}{' '}
                                      {subscription?.SUBSCRIPTION_FREQUENCY ===
                                        'Monthly' && (
                                        <span className="card-content-element">
                                          Day(s):{` `}
                                          <strong>
                                            {
                                              subscription?.SUBSCRIPTION_MONTH_DAY
                                            }
                                          </strong>
                                        </span>
                                      )}
                                    </div>
                                    <Card
                                      header={
                                        <span className="card-content-element-title">
                                          Filter(s)
                                        </span>
                                      }
                                      collapse
                                      collapseOnClick={() => {}}
                                      defaultIsOpen={false}
                                    >
                                      <Card.Section>
                                        <p
                                          style={{
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: '#002677',
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            TIN(s):
                                          </span>{' '}
                                          <span
                                            style={{
                                              fontSize: '15px',
                                            }}
                                          >
                                            {subscription
                                              ?.SUBSCRIPTION_TIN_FILTERS
                                              .length > 0
                                              ? subscription?.SUBSCRIPTION_TIN_FILTERS
                                              : 'N/A'}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: '#002677',
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            MPIN(s):
                                          </span>{' '}
                                          <span
                                            style={{
                                              fontSize: '15px',
                                            }}
                                          >
                                            {subscription
                                              ?.SUBSCRIPTION_MPIN_FILTERS
                                              .length > 0
                                              ? subscription?.SUBSCRIPTION_MPIN_FILTERS
                                              : 'N/A'}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                          }}
                                        >
                                          {/* <span
                                            style={{
                                              color: '#002677',
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            PROVIDER NAME(s):
                                          </span>{' '} */}
                                          <span
                                            style={{
                                              fontSize: '15px',
                                            }}
                                          >
                                            {/* {subscription
                                              ?.SUBSCRIPTION_PROVIDER_NAME_FILTERS
                                              .length > 0
                                              ? subscription?.SUBSCRIPTION_PROVIDER_NAME_FILTERS
                                              : 'N/A'}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: '#002677',
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            ORG TYPE(s):
                                          </span>{' '}
                                          <span
                                            style={{
                                              fontSize: '15px',
                                            }}
                                          >
                                            {subscription
                                              ?.SUBSCRIPTION_ORG_TYPE_FILTERS
                                              .length > 0
                                              ? subscription?.SUBSCRIPTION_ORG_TYPE_FILTERS
                                              : 'N/A'}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: '#002677',
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                            }}
                                          >
                                            DDP_STATUS(s):
                                          </span>{' '}
                                          <span
                                            style={{
                                              fontSize: '15px',
                                            }}
                                          >
                                            {subscription
                                              ?.SUBSCRIPTION_DDP_STATUS_FILTERS
                                              .length > 0
                                              ? subscription?.SUBSCRIPTION_DDP_STATUS_FILTERS
                                              : 'N/A'}
                                          </span>
                                        </p>
                                        <p
                                          style={{
                                            marginLeft: '10px',
                                            marginTop: '5px',
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: '#002677',
                                              fontSize: '16px',
                                              fontWeight: 'bold',
                                            }}
                                          > */}
                                            {/* START_DATE(s):
                                          </span>{' '}
                                          <span
                                            style={{
                                              fontSize: '15px',
                                            }}
                                          > */}
                                            {/* {subscription
                                              ?.SUBSCRIPTION_START_DATE_FILTERS
                                              .length > 0
                                              ? subscription?.SUBSCRIPTION_START_DATE_FILTERS
                                              : 'N/A'} */}
                                          </span>
                                        </p>
                                      </Card.Section>
                                    </Card>
                                  </Card.Section>
                                </Card>
                              );
                            })}
                          </Card.Section>
                        </Card>
                      </div>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      {!showSubscribeMessage ? (
                        <React.Fragment>
                          <Modal.Section
                            css={{
                              'abyss-modal-section-root': {
                                paddingBottom: '4px',
                              },
                            }}
                          >
                            <h6>User email: {activeAccountEmail}</h6>
                            <TextInput
                              css={{
                                'abyss-text-input-root': {
                                  marginTop: '5px',
                                  width: '60%',
                                },
                              }}
                              label="Additional Recepient(s):"
                              value={additionalRecepients}
                              onChange={(e) => {
                                setAdditionRecepients(e.target.value);

                                setTimeout(() => {
                                  document.getElementById(
                                    'email-error'
                                  ).innerText = '';
                                  validateEnteredAdditionaRecipients(
                                    e.target.value
                                  );
                                }, 1000);
                              }}
                            />

                            <div
                              style={{
                                display: 'none',
                                fontSize: '16px',
                                color: '#C40000',
                                margin: '5px',
                              }}
                              id="email-error"
                            />

                            <div
                              style={{
                                color: '#424242',
                                margin: '4px',
                                padding: '2px',
                                fontSize: '14px',
                              }}
                            >
                              Note: Only UHG email addresses may be allowed
                              within the subscription. Emails may not be sent to
                              outside email addresses (i.e., gmail.com,
                              yahoo.com, etc.).
                            </div>
                            {(tinFilters.length > 0 ||
                              mpinFilters.length > 0) && (
                              <div
                                style={{
                                  marginTop: '10px',
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: '18px',
                                    marginBottom: '4px',
                                  }}
                                >
                                  TINs/MPINs from {fileName}
                                </div>

                                <hr />
                                <div
                                  style={{
                                    padding: '8px',
                                  }}
                                >
                                  {tinFilters && tinFilters.length > 0 && (
                                    <span
                                      style={{
                                        marginTop: '10px',
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: '#002677',
                                          fontSize: '18px',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Count of Unique TINS:
                                      </span>{' '}
                                      <span style={{ fontSize: '17px' }}>
                                        {/* {tinFilters.join(',')} */}
                                        {tinFilters.length}
                                      </span>
                                    </span>
                                  )}

                                  <br />
                                  {mpinFilters && mpinFilters.length > 0 && (
                                    <span
                                      style={{
                                        marginTop: '10px',
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: '#002677',
                                          fontSize: '18px',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        Count of Unique MPINs:
                                      </span>{' '}
                                      <span style={{ fontSize: '17px' }}>
                                        {/* {processFilterObject(mpinFilters).join(
                                          ','
                                        )} */}
                                        {mpinFilters.length}
                                      </span>
                                    </span>
                                  )}
                                  <br />
                                </div>
                                <div
                                  style={{
                                    color: '#424242',
                                    margin: '4px',
                                    padding: '2px',
                                    fontSize: '14px',
                                  }}
                                >
                                  The TINs and MPINs in this file are used to
                                  run the report. If you decide you want to run
                                  the report using a different list, you'll need
                                  to upload a new file and set up a new
                                  subscription.
                                </div>
                              </div>
                            )}

                            <div
                              style={{
                                marginTop: '10px',
                              }}
                            >
                              <h5>Schedule Date</h5>
                              <div
                                style={{
                                  display: 'flex',
                                }}
                                className=""
                              >
                                <DateInput
                                  label="Start Date"
                                  value={subscriptionStartDate}
                                  onChange={(e) => {
                                    setSubscriptionStartDate(e);
                                    console.log(typeof e);
                                    if (typeof e === 'object') {
                                      setIsValidInput(false);
                                      const dateErrorDiv =
                                        document.getElementById('date-error');
                                      dateErrorDiv.innerHTML =
                                        'Please select date!!';
                                      dateErrorDiv.style.display = 'block';
                                    } else if (
                                      additionalRecepients.length === 0
                                    ) {
                                      setIsValidInput(true);
                                      const dateErrorDiv =
                                        document.getElementById('date-error');
                                      dateErrorDiv.style.display = 'none';
                                    } else if (
                                      additionalRecepients.length !== 0
                                    ) {
                                      const emailErrorDiv =
                                        document.getElementById('email-error');
                                      if (
                                        emailErrorDiv.style.display === 'none'
                                      ) {
                                        setIsValidInput(true);
                                      }
                                      const dateErrorDiv =
                                        document.getElementById('date-error');
                                      dateErrorDiv.style.display = 'none';
                                    }
                                  }}
                                  css={{
                                    'abyss-date-picker-root': {
                                      width: '50% !important',
                                      margin: '5px',
                                    },
                                  }}
                                  // minimumDate={Date.now()}
                                />
                                <DateInput
                                  label="End Date"
                                  value={subscriptionEndDate}
                                  onChange={setSubscriptionEndDate}
                                  css={{
                                    'abyss-date-picker-root': {
                                      width: '50% !important',
                                      margin: '5px',
                                    },
                                  }}
                                  // minimumDate={Date.now()}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'none',
                                  fontSize: '16px',
                                  color: '#C40000',
                                  margin: '5px',
                                }}
                                id="date-error"
                              />
                              <SelectInput
                                label="Repeat"
                                placeholder="Pick one"
                                value={subscriptionFrequency}
                                onChange={setSubscriptionFrequency}
                                onClear={() => {
                                  setSubscriptionFrequency('');
                                }}
                                options={[
                                  { value: 'Daily', label: 'Daily' },
                                  { value: 'Weekly', label: 'Weekly' },
                                  { value: 'Monthly', label: 'Monthly' },
                                ]}
                                isClearable
                                isSearchable
                                // isDisabled
                                css={{
                                  'abyss-select-input-root': {
                                    width: '50%',
                                  },
                                }}
                              />
                              {subscriptionFrequency === 'Weekly' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '10px 2px',
                                  }}
                                >
                                  <div>
                                    <h6>On</h6>
                                  </div>

                                  <div
                                    className="week-day"
                                    onClick={(e) => {
                                      console.log(e.target.classList);
                                      if (subscriptionWeekDay.length > 0) {
                                        e.target.classList.remove(
                                          'selected-week-day'
                                        );

                                        if (subscriptionWeekDay.includes('0')) {
                                          if (
                                            subscriptionWeekDay.includes('0,')
                                          ) {
                                            subscriptionWeekDay.replace(
                                              '0,',
                                              ''
                                            );
                                          } else {
                                            subscriptionWeekDay.replace(
                                              '0',
                                              ''
                                            );
                                          }

                                          // setWeekDays(
                                          //   Array.from(weekDays).pop('Sunday')
                                          // );
                                        } else {
                                          setSubscriptionWeekDay(
                                            `${subscriptionWeekDay},0`
                                          );
                                          // setWeekDays(
                                          //   Array.from(weekDays).push('Sunday')
                                          // );
                                          e.target.classList.add(
                                            'selected-week-day'
                                          );
                                        }
                                      } else {
                                        setSubscriptionWeekDay(`0`);
                                        setWeekDays(
                                          Array.from(weekDays).push('Sunday')
                                        );
                                        e.target.classList.add(
                                          'selected-week-day'
                                        );
                                      }
                                    }}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    S
                                  </div>

                                  <div
                                    className="week-day"
                                    onClick={(e) => {
                                      if (subscriptionWeekDay.length > 0) {
                                        if (subscriptionWeekDay.includes('1')) {
                                          e.target.classList.remove(
                                            'selected-week-day'
                                          );
                                          if (
                                            subscriptionWeekDay.includes('1,')
                                          ) {
                                            subscriptionWeekDay.replace(
                                              '1,',
                                              ''
                                            );
                                          } else {
                                            subscriptionWeekDay.replace(
                                              '1',
                                              ''
                                            );
                                          }
                                          // setWeekDays(
                                          //   Array.from(weekDays).pop('Monday')
                                          // );
                                        } else {
                                          setSubscriptionWeekDay(
                                            `${subscriptionWeekDay},1`
                                          );
                                          // setWeekDays(
                                          //   Array.from(weekDays).push('Monday')
                                          // );
                                          e.target.classList.add(
                                            'selected-week-day'
                                          );
                                        }
                                      } else {
                                        setSubscriptionWeekDay(`1`);
                                        // setWeekDays(
                                        //   Array.from(weekDays).push('Monday')
                                        // );
                                        e.target.classList.add(
                                          'selected-week-day'
                                        );
                                      }
                                    }}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    M
                                  </div>

                                  <div
                                    className="week-day"
                                    onClick={(e) => {
                                      if (subscriptionWeekDay.length > 0) {
                                        if (subscriptionWeekDay.includes('2')) {
                                          e.target.classList.remove(
                                            'selected-week-day'
                                          );
                                          if (
                                            subscriptionWeekDay.includes('2,')
                                          ) {
                                            subscriptionWeekDay.replace(
                                              '2,',
                                              ''
                                            );
                                          } else {
                                            subscriptionWeekDay.replace(
                                              '2',
                                              ''
                                            );
                                          }
                                          // setWeekDays(
                                          //   Array.from(weekDays).pop('Tuesday')
                                          // );
                                        } else {
                                          setSubscriptionWeekDay(
                                            `${subscriptionWeekDay},2`
                                          );
                                          // setWeekDays(
                                          //   Array.from(weekDays).push('Tuesday')
                                          // );
                                          e.target.classList.add(
                                            'selected-week-day'
                                          );
                                        }
                                      } else {
                                        setSubscriptionWeekDay(`2`);
                                        // setWeekDays(
                                        //   Array.from(weekDays).push('Tuesday')
                                        // );
                                        e.target.classList.add(
                                          'selected-week-day'
                                        );
                                      }
                                    }}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    T
                                  </div>

                                  <div
                                    className="week-day"
                                    onClick={(e) => {
                                      if (subscriptionWeekDay.length > 0) {
                                        if (subscriptionWeekDay.includes('3')) {
                                          e.target.classList.remove(
                                            'selected-week-day'
                                          );
                                          if (
                                            subscriptionWeekDay.includes('3,')
                                          ) {
                                            subscriptionWeekDay.replace(
                                              '3,',
                                              ''
                                            );
                                          } else {
                                            subscriptionWeekDay.replace(
                                              '3',
                                              ''
                                            );
                                          }
                                          // setWeekDays(
                                          //   Array.from(weekDays).pop(
                                          //     'Wednesday'
                                          //   )
                                          // );
                                        } else {
                                          setSubscriptionWeekDay(
                                            `${subscriptionWeekDay},3`
                                          );
                                          // setWeekDays(
                                          //   Array.from(weekDays).push(
                                          //     'Wednesday'
                                          //   )
                                          // );
                                          e.target.classList.add(
                                            'selected-week-day'
                                          );
                                        }
                                      } else {
                                        setSubscriptionWeekDay(`3`);
                                        // setWeekDays(
                                        //   Array.from(weekDays).push('Wednesday')
                                        // );
                                        e.target.classList.add(
                                          'selected-week-day'
                                        );
                                      }
                                    }}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    W
                                  </div>

                                  <div
                                    className="week-day"
                                    onClick={(e) => {
                                      if (subscriptionWeekDay.length > 0) {
                                        if (subscriptionWeekDay.includes('4')) {
                                          e.target.classList.remove(
                                            'selected-week-day'
                                          );
                                          if (
                                            subscriptionWeekDay.includes('4,')
                                          ) {
                                            subscriptionWeekDay.replace(
                                              '4,',
                                              ''
                                            );
                                          } else {
                                            subscriptionWeekDay.replace(
                                              '4',
                                              ''
                                            );
                                          }
                                          // setWeekDays(
                                          //   Array.from(weekDays).pop('Thursday')
                                          // );
                                        } else {
                                          setSubscriptionWeekDay(
                                            `${subscriptionWeekDay},4`
                                          );
                                          // setWeekDays(
                                          //   Array.from(weekDays).push(
                                          //     'Thursday'
                                          //   )
                                          // );
                                          e.target.classList.add(
                                            'selected-week-day'
                                          );
                                        }
                                      } else {
                                        setSubscriptionWeekDay(`4`);
                                        // setWeekDays(
                                        //   Array.from(weekDays).push('Thursday')
                                        // );
                                        e.target.classList.add(
                                          'selected-week-day'
                                        );
                                      }
                                    }}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    T
                                  </div>

                                  <div
                                    className="week-day"
                                    onClick={(e) => {
                                      if (subscriptionWeekDay.length > 0) {
                                        if (subscriptionWeekDay.includes('5')) {
                                          e.target.classList.remove(
                                            'selected-week-day'
                                          );
                                          if (
                                            subscriptionWeekDay.includes('5,')
                                          ) {
                                            subscriptionWeekDay.replace(
                                              '5,',
                                              ''
                                            );
                                          } else {
                                            subscriptionWeekDay.replace(
                                              '5',
                                              ''
                                            );
                                          }
                                          // setWeekDays(
                                          //   Array.from(weekDays).pop('Friday')
                                          // );
                                        } else {
                                          setSubscriptionWeekDay(
                                            `${subscriptionWeekDay},5`
                                          );
                                          // setWeekDays(
                                          //   Array.from(weekDays).push('Friday')
                                          // );
                                          e.target.classList.add(
                                            'selected-week-day'
                                          );
                                        }
                                      } else {
                                        setSubscriptionWeekDay(`5`);
                                        // setWeekDays(
                                        //   Array.from(weekDays).push('Friday')
                                        // );
                                        e.target.classList.add(
                                          'selected-week-day'
                                        );
                                      }
                                    }}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    F
                                  </div>

                                  <div
                                    className="week-day"
                                    onClick={(e) => {
                                      if (subscriptionWeekDay.length > 0) {
                                        e.target.classList.remove(
                                          'selected-week-day'
                                        );
                                        if (subscriptionWeekDay.includes('6')) {
                                          e.target.classList.remove(
                                            'selected-week-day'
                                          );
                                          if (
                                            subscriptionWeekDay.includes('6,')
                                          ) {
                                            subscriptionWeekDay.replace(
                                              '6,',
                                              ''
                                            );
                                          } else {
                                            subscriptionWeekDay.replace(
                                              '6',
                                              ''
                                            );
                                          }
                                          // setWeekDays(
                                          //   Array.from(weekDays).pop('Saturday')
                                          // );
                                        } else {
                                          setSubscriptionWeekDay(
                                            `${subscriptionWeekDay},6`
                                          );
                                          // setWeekDays(
                                          //   Array.from(weekDays).push(
                                          //     'Saturday'
                                          //   )
                                          // );
                                          e.target.classList.add(
                                            'selected-week-day'
                                          );
                                        }
                                      } else {
                                        setSubscriptionWeekDay(`6`);
                                        // setWeekDays(
                                        //   Array.from(weekDays).push('Saturday')
                                        // );
                                        e.target.classList.add(
                                          'selected-week-day'
                                        );
                                      }
                                    }}
                                    onKeyDown={() => {}}
                                    role="button"
                                    tabIndex={0}
                                  >
                                    S
                                  </div>
                                </div>
                              )}

                              {subscriptionFrequency === 'Monthly' && (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '10px 2px',
                                  }}
                                >
                                  <TextInput
                                    css={{
                                      'abyss-text-input-root': {
                                        marginTop: '5px',
                                        width: '60%',
                                      },
                                    }}
                                    label="Every month on day(s):"
                                    placeholder="Ex: 1,27-30"
                                    value={subscriptionMonthDay}
                                    onChange={(e) => {
                                      setSubscriptionMonthDay(e.target.value);
                                      setRepeatMessage(
                                        `on ${e.target.value} day(s) of a month`
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div
                              style={{
                                color: '#424242',
                                margin: '8px 4px 2px 4px',
                                padding: '2px',
                                fontSize: '16px',
                              }}
                            >
                              {/* {`Note: You will receive this report ${repeatMessage} approximately at
                              8AM CT.`} */}
                              Note: You will receive this report approximately
                              6AM-630AM CT based on the repeating schedule
                              you've selected.
                            </div>
                          </Modal.Section>
                          <Modal.Section>
                            <div className="modal-primary-buttons">
                              <Button
                                size="sm"
                                className="abyss-button"
                                onClick={() => {
                                  storeSubscriberDetails();
                                }}
                                isDisabled={
                                  !isInputsValid &&
                                  tinFilters?.length > 0 &&
                                  mpinFilters?.length > 0
                                }
                              >
                                Subscribe
                              </Button>
                              <Button
                                type="button"
                                size="sm"
                                variant="outline"
                                className="abyss-button"
                                onClick={() => {
                                  setIsNewSubscription(false);
                                  setSubscriptionFrequency('Weekly');
                                  setSubscriptionWeekDay('');
                                  setSubscriptionMonthDay('');
                                  setSubscriptionStartDate(null);
                                  setSubscriptionEndDate(null);
                                  setAdditionRecepients('');
                                }}
                              >
                                Back
                              </Button>
                            </div>
                          </Modal.Section>
                        </React.Fragment>
                      ) : (
                        <Modal.Section>
                          <div
                            style={{
                              width: '60%',
                              display: 'flex',
                              justifyContent: 'space-around',
                              alignItems: 'center',
                            }}
                          >
                            {afterSubscribeMessage.includes('Success') ? (
                              <IconMaterial
                                icon="done"
                                variant="filled"
                                color="$success1"
                                size={64}
                              />
                            ) : (
                              <IconMaterial
                                icon="clear"
                                variant="filled"
                                color="$error1"
                                size={64}
                              />
                            )}
                            <h5>{afterSubscribeMessage}</h5>
                          </div>
                        </Modal.Section>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </Modal>
      </div>
    </React.Fragment>
  );
};
