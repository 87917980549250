/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { PageHeader } from '@abyss/web/ui/PageHeader';
import { Brandmark } from '@abyss/web/ui/Brandmark';
import { LoadingOverlay } from '@abyss/web/ui/LoadingOverlay';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from '@azure/msal-react';
import { useDispatch } from 'react-redux';
import { loginRequest } from '../authConfig';

export const Navbar = () => {
  const [hours] = useState(new Date().getHours());
  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    if (hours < 12) {
      setGreeting('Good morning, ');
    } else if (hours >= 12 && hours < 16) {
      setGreeting('Good afternoon, ');
    } else if (hours >= 16) {
      setGreeting('Good evening, ');
    }
  }, [greeting, hours]);
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const getAccoutDetails = useMsal();
  const currentAccountDetails = getAccoutDetails.instance.getAllAccounts()[0];

  const handleLoginRedirect = () => {
    instance.loginRedirect(loginRequest).catch((error) => {
      return error;
    });
  };

  useEffect(() => {
    handleLoginRedirect();
  }, []);

  return (
    <React.Fragment>
      <AuthenticatedTemplate>
        <PageHeader
          logo={
            <Brandmark
              brand="uhc"
              size="60px"
              affiliate="uhc"
              variant="monogram"
              color="blue"
            />
          }
          model="pageheader-model"
          logoTitle="Provider Roster Comparison"
          css={{
            'abyss-page-header-root': { zIndex: 209 },
            'abyss-page-header-logo-title': { marginLeft: '370px' },
          }}
        >
          {/* bnmvvv */}
          <PageHeader.Toolbar>
            <div>
              <h5 className="greeting">
                {greeting}{' '}
                {currentAccountDetails
                  ? `${currentAccountDetails.name}`
                  : 'Test'}
              </h5>
              <h5 className="greeting-secondary">
                Welcome to Provider Roster Comparison
              </h5>
            </div>
          </PageHeader.Toolbar>
        </PageHeader>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoadingOverlay />
      </UnauthenticatedTemplate>
    </React.Fragment>
  );
};
