export const ndbPlnTableColumns = [
  {
    Header: 'TIN',
    accessor: 'TIN',
  },
  {
    Header: 'MPIN',
    accessor: 'MPIN',
  },
  {
    Header: 'PROVIDER_NAME',
    accessor: 'PROVIDER_NAME',
  },
  {
    Header: 'ORG_TYPE',
    accessor: 'ORG_TYPE',
  },
  {
    Header: 'DDP_STATUS',
    accessor: 'DDP_STATUS',
  },
  {
    Header: 'START_DATE',
    accessor: 'START_DATE',
  },
  {
    Header: 'END_DATE',
    accessor: 'END_DATE',
  },
];
